import React, { useContext, useEffect, useState } from "react";
import {
	Alert,
	Button,
	Col,
	Container,
	Form,
	Row,
	Table,
} from "react-bootstrap";
import Loading from "../../Helpers/Loading";
import { userContext } from "../../Stores/Store";
import * as _api from "../../Apis/Configuration_apis";
import { useKeycloak } from "@react-keycloak/web";
import AlertMessage from "../../Helpers/AlertMessage";
import { BsBoxArrowInUpRight } from "react-icons/bs";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import moment from "moment";
import { set } from "draft-js/lib/DefaultDraftBlockRenderMap";

const Competitions = () => {
	const user = useContext(userContext);
	const [loading, setLoading] = useState(false);
	const [competition, setCompetition] = useState("");
	const [competitions, setCompetitions] = useState();
	const [showDelete, setShowDelete] = useState(false);
	const { keycloak, initialized } = useKeycloak();
	const [confirmDelete, setConfirmDelete] = useState();
	const [competitionAdd, setCompetitionAdd] = useState({ id: "", name: "" });
	const [errorForm, setErrorForm] = useState();
	const [showCompetitionNational, setShowCompetitionNational] = useState(false);

	// On réinitialise les erreurs quand on ferme l'alerte
	const initError = () => {
		setErrorForm();
	};

	useEffect(() => {
		setLoading(true);
		_api
			.getCompetitions(keycloak.token)
			.then((res) => {
				setCompetitions(res);
			})
			.then(() => setLoading(false));
	}, []);

	const addCompetition = () => {
		if (competitionAdd.name && competitionAdd.id) {
			let dateStart = competitionAdd.date_start
				? moment(competitionAdd.date_start).format("YYYY-MM-DD")
				: null;
			_api
				.putCompetition(keycloak.token, {
					...competitionAdd,
					date_start: dateStart,
				})
				.then((res) => {
					if (res) {
						setCompetitions([...competitions, res]);
						eraseForm();
					} else {
						setErrorForm("Code Erreur " + res);
					}
				});
		}
	};

	const updateCompetition = () => {
		if (competition.name) {
			competition.date_start = moment(competition.date_start).format(
				"YYYY-MM-DD",
			);
			_api
				.postCompetition(keycloak.token, competition.id, competition)
				.then((res) => {
					if (res) {
						let arrayTemp = [];
						let competitionIndex = competitions.findIndex(
							(obj) => obj.id == res.id,
						);
						arrayTemp = [...competitions];
						arrayTemp[competitionIndex] = res;
						setCompetitions(arrayTemp);
						eraseForm();
					} else {
						setErrorForm("Code Erreur " + res);
					}
				});
		}
	};

	const deleteCompetition = () => {
		if (competition.id) {
			if (confirmDelete == competition.name) {
				_api
					.deleteCompetition(keycloak.token, competition.id)
					.then((res) => {
						setCompetitions(
							competitions.filter((item) => item.id !== competition.id),
						);
					})
					.then(() => setCompetition(""))
					.then(() => setShowDelete(false));
			}
		}
	};

	const eraseForm = () => {
		setCompetition("");
	};

	const listCompetitions = () => {
		return competitions
			.sort((a, b) => (a.name > b.name ? 1 : -1))
			.map((competition) => {
				return (
					<>
						<tr>
							<td>{competition.id}</td>
							<td>{competition.name}</td>
							<td>{competition.date_start}</td>
							<td>
								{competition.url_website && (
									<a href={competition.url_website} target="_blank">
										<BsBoxArrowInUpRight />
									</a>
								)}
							</td>
							<td>
								{competition.url_forum && (
									<a href={competition.url_forum} target="_blank">
										<BsBoxArrowInUpRight />
									</a>
								)}
							</td>
							<td>
								{competition.url_trombinoscope && (
									<a href={competition.url_trombinoscope} target="_blank">
										<BsBoxArrowInUpRight />
									</a>
								)}
							</td>
							<td>
								{competition.url_aide && (
									<a href={competition.url_aide} target="_blank">
										<BsBoxArrowInUpRight />
									</a>
								)}
							</td>
							<td>{competition.national ? "Oui" : "Non"}</td>
							<td>
								<span
									style={{ cursor: "pointer" }}
									onClick={() => {
										setCompetition(competition);
										setShowCompetitionNational(competition.national);
										setShowDelete(false);
									}}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="currentColor"
										class="bi bi-pencil"
										viewBox="0 0 16 16"
										color="blue"
									>
										<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
									</svg>
								</span>
								&nbsp;
								<span
									style={{ cursor: "pointer" }}
									onClick={() => {
										setCompetition(competition);
										setShowDelete(true);
									}}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="currentColor"
										class="bi bi-trash"
										viewBox="0 0 16 16"
										color="red"
									>
										<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
										<path
											fill-rule="evenodd"
											d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
										/>
									</svg>
								</span>
							</td>
						</tr>
					</>
				);
			});
	};

	const handleChangeNational = (e) => {
		if (e.target.checked) {
			setShowCompetitionNational(true);
		}
		if (!e.target.checked) {
			setShowCompetitionNational(false);
		}
		if (!competition.id) {
			setCompetitionAdd({
				...competitionAdd,
				[e.target.name]: e.target.checked,
			});
		} else {
			setCompetition({
				...competition,
				[e.target.name]: e.target.checked,
			});
		}
	};

	//Contrôle du chargement des données
	if (loading) return <Loading />;
	return (
		<>
			<Container fluid>
				{/* Affichage des erreurs */}
				{errorForm && <AlertMessage message={errorForm} errorMaj={initError} />}
				<Row>
					<Col sm={9}>
						<Row>
							<Table hover responsive striped>
								<thead>
									<tr>
										<th>ID</th>
										<th>Compétition</th>
										<th>Date de début</th>
										<th>Site Web</th>
										<th>Forum</th>
										<th>Trombinoscope</th>
										<th>Aide</th>
										<th>Nationale</th>
										<th></th>
									</tr>
								</thead>
								<tbody>{competitions && listCompetitions()}</tbody>
							</Table>
						</Row>
					</Col>
					{!showDelete && (
						<Col>
							<h3>
								{!competition.id ? "Ajouter une " : "Modifier la"} compétition
							</h3>
							<Form onSubmit={(e) => e.preventDefault()}>
								{!competition.id && (
									<>
										<Row>
											<Form.Group className="mb-3">
												<Form.Control
													placeholder="ID de la Compétition"
													name="competition"
													value={competitionAdd.id}
													onChange={(e) =>
														setCompetitionAdd({
															...competitionAdd,
															id: e.target.value,
														})
													}
												/>
											</Form.Group>
										</Row>
										<Row>
											<Form.Group className="mb-3">
												<Form.Control
													placeholder="Nom de la Compétition"
													name="competition"
													value={competitionAdd.name}
													onChange={(e) =>
														setCompetitionAdd({
															...competitionAdd,
															name: e.target.value,
														})
													}
												/>
											</Form.Group>
										</Row>
										<Row>
											<Form.Group className="mb-3">
												<DateTimePicker
													locale="fr-FR"
													name="start_date"
													value={competitionAdd.date_start}
													onChange={(value) =>
														setCompetitionAdd({
															...competitionAdd,
															date_start: value,
														})
													}
												/>
											</Form.Group>
										</Row>
										<Row>
											<Form.Group className="mb-3">
												<Form.Control
													placeholder="url du site web"
													name="url_website"
													value={competitionAdd.url_website}
													onChange={(e) =>
														setCompetitionAdd({
															...competitionAdd,
															url_website: e.target.value,
														})
													}
												/>
											</Form.Group>
										</Row>
										<Row>
											<Form.Group className="mb-3">
												<Form.Control
													placeholder="url du forum"
													name="url_forum"
													value={competitionAdd.url_forum}
													onChange={(e) =>
														setCompetitionAdd({
															...competitionAdd,
															url_forum: e.target.value,
														})
													}
												/>
											</Form.Group>
										</Row>
										<Row>
											<Form.Group className="mb-3">
												<Form.Control
													placeholder="url du trombinoscope"
													name="url_trombinoscope"
													value={competitionAdd.url_trombinoscope}
													onChange={(e) =>
														setCompetitionAdd({
															...competitionAdd,
															url_trombinoscope: e.target.value,
														})
													}
												/>
											</Form.Group>
										</Row>
										<Row>
											<Form.Group className="mb-3">
												<Form.Control
													placeholder="url de l'aide"
													name="url_aide"
													value={competitionAdd.url_aide}
													onChange={(e) =>
														setCompetitionAdd({
															...competitionAdd,
															url_aide: e.target.value,
														})
													}
												/>
											</Form.Group>
										</Row>
										<Row>
											<Form.Group className="mb-3">
												<Form.Check
													label="Compétition Nationale"
													name="national"
													type="switch"
													onChange={handleChangeNational}
													checked={competitionAdd.national}
												/>
											</Form.Group>
										</Row>
										{showCompetitionNational && (
											<>
												<Row>
													<Form.Group className="mb-3">
														<Form.Control
															placeholder="url Skill Management"
															name="url_skill_management"
															value={competitionAdd.url_skill_management}
															onChange={(e) =>
																setCompetitionAdd({
																	...competitionAdd,
																	url_skill_management: e.target.value,
																})
															}
														/>
													</Form.Group>
												</Row>
												<Row>
													<Form.Group className="mb-3">
														<Form.Control
															placeholder="url liste de l'infrastructure"
															name="url_infrastructure_list"
															value={competitionAdd.url_infrastructure_list}
															onChange={(e) =>
																setCompetitionAdd({
																	...competitionAdd,
																	url_infrastructure_list: e.target.value,
																})
															}
														/>
													</Form.Group>
												</Row>
												<Row>
													<Form.Group className="mb-3">
														<Form.Control
															placeholder="url de la plateforme d'inscription"
															name="url_inscription_plateform"
															value={competitionAdd.url_inscription_plateform}
															onChange={(e) =>
																setCompetitionAdd({
																	...competitionAdd,
																	url_inscription_plateform: e.target.value,
																})
															}
														/>
													</Form.Group>
												</Row>
											</>
										)}
									</>
								)}
								{competition.id && (
									<>
										<Row>
											<Form.Group className="mb-3">
												<Form.Control
													placeholder="Nom de la Compétition"
													name="competition"
													value={competition && competition.name}
													onChange={(e) =>
														setCompetition({
															...competition,
															name: e.target.value,
														})
													}
												/>
											</Form.Group>
										</Row>
										<Row>
											<Form.Group className="mb-3">
												<DateTimePicker
													locale="fr-FR"
													name="start_date"
													value={new Date(competition.date_start)}
													onChange={(value) =>
														setCompetition({
															...competition,
															date_start: value,
														})
													}
												/>
											</Form.Group>
										</Row>
										<Row>
											<Form.Group className="mb-3">
												<Form.Control
													placeholder="url du site web"
													name="url_website"
													value={competition && competition.url_website}
													onChange={(e) =>
														setCompetition({
															...competition,
															url_website: e.target.value,
														})
													}
												/>
											</Form.Group>
										</Row>
										<Row>
											<Form.Group className="mb-3">
												<Form.Control
													placeholder="url du forum"
													name="url_forum"
													value={competition && competition.url_forum}
													onChange={(e) =>
														setCompetition({
															...competition,
															url_forum: e.target.value,
														})
													}
												/>
											</Form.Group>
										</Row>
										<Row>
											<Form.Group className="mb-3">
												<Form.Control
													placeholder="url du trombinoscope"
													name="url_trombinoscope"
													value={competition && competition.url_trombinoscope}
													onChange={(e) =>
														setCompetition({
															...competition,
															url_trombinoscope: e.target.value,
														})
													}
												/>
											</Form.Group>
										</Row>
										<Row>
											<Form.Group className="mb-3">
												<Form.Control
													placeholder="url de l'aide"
													name="url_aide"
													value={competition && competition.url_aide}
													onChange={(e) =>
														setCompetition({
															...competition,
															url_aide: e.target.value,
														})
													}
												/>
											</Form.Group>
										</Row>
										<Row>
											<Form.Group className="mb-3">
												<Form.Check
													label="Compétition Nationale"
													name="national"
													type="switch"
													onChange={handleChangeNational}
													checked={competition.national}
												/>
											</Form.Group>
										</Row>
										{showCompetitionNational && (
											<>
												<Row>
													<Form.Group className="mb-3">
														<Form.Control
															placeholder="url Skill Management"
															name="url_skill_management"
															value={competition.url_skill_management}
															onChange={(e) =>
																setCompetition({
																	...competition,
																	url_skill_management: e.target.value,
																})
															}
														/>
													</Form.Group>
												</Row>
												<Row>
													<Form.Group className="mb-3">
														<Form.Control
															placeholder="url liste de l'infrastructure"
															name="url_infrastructure_list"
															value={competition.url_infrastructure_list}
															onChange={(e) =>
																setCompetition({
																	...competition,
																	url_infrastructure_list: e.target.value,
																})
															}
														/>
													</Form.Group>
												</Row>
												<Row>
													<Form.Group className="mb-3">
														<Form.Control
															placeholder="url de la plateforme d'inscription"
															name="url_inscription_plateform"
															value={competition.url_inscription_plateform}
															onChange={(e) =>
																setCompetition({
																	...competition,
																	url_inscription_plateform: e.target.value,
																})
															}
														/>
													</Form.Group>
												</Row>
											</>
										)}
									</>
								)}
								<Row>
									<Col>
										{!competition.id && (
											<Button
												onClick={() => addCompetition()}
												style={{ marginTop: "10px" }}
											>
												Ajouter
											</Button>
										)}
										{competition.id && (
											<>
												<Button
													onClick={() => updateCompetition()}
													style={{ marginTop: "10px" }}
												>
													Modifier
												</Button>
												&nbsp;
												<Button
													variant="outline-secondary"
													onClick={() => eraseForm()}
													style={{ marginTop: "10px" }}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="20"
														height="20"
														fill="currentColor"
														class="bi bi-x-circle"
														viewBox="0 0 16 16"
													>
														<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
														<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
													</svg>
												</Button>
											</>
										)}
									</Col>
								</Row>
							</Form>
						</Col>
					)}
					{showDelete && (
						<Col>
							<h3>Supprimer {competition.name}</h3>
							<Form onSubmit={(e) => e.preventDefault()}>
								<Row>
									<Alert variant="warning">
										Cette action sera irrévocable. Elle entrainera la
										suppression de cette compétition et des éléments associés.
										<br />
										Pour confirmer la suppression veuillez saisir le nom de la
										compétition et cliquer sur "Supprimer"
									</Alert>
								</Row>
								<Row>
									<Col>
										<Form.Group className="mb-3">
											<Form.Control
												placeholder="Nom de la compétition à supprimer"
												required
												value={confirmDelete}
												onChange={(e) => setConfirmDelete(e.target.value)}
											/>
										</Form.Group>
									</Col>
									<Col>
										<Button
											variant="outline-danger"
											onClick={() => deleteCompetition()}
										>
											Supprimer
										</Button>
										&nbsp;
										<Button
											variant="outline-secondary"
											onClick={() => {
												setCompetition("");
												setShowDelete(false);
											}}
										>
											Annuler
										</Button>
									</Col>
								</Row>
							</Form>
						</Col>
					)}
				</Row>
			</Container>
		</>
	);
};

export default Competitions;
