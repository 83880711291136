import React, { useCallback } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import keycloak from '../Keycloak';

const Login = () => {

    return (
        <div>
            <Container fluid>
                <Row>
                <Col></Col>
                <Col>
                <Card style={{ width: '50rem',margin: '30px'}}>
                    <Card.Img style={{padding: '20px'}} variant="top" src={window.location.origin+"/WSFR_Logo.svg"} />
                    <Card.Body>
                        <Card.Title>Bienvenue sur la plateforme Victoire</Card.Title>
                        <Card.Text>
                        Authentification requise pour accèder à la plateforme.
                        </Card.Text>
                        <Button variant="primary" onClick={() => keycloak.login()}>Connexion à la plateforme</Button>
                    </Card.Body>
                </Card>
                </Col>
                <Col></Col>
                </Row>
            </Container>
        </div>
    );
};

export default Login;