import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Modal,
  Button,
  Row,
  Form,
  ListGroup,
  ListGroupItem,
} from "react-bootstrap";
import { BsBoxArrowInRight } from "react-icons/bs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./documents.scss";
import * as _api from "../../Apis/Documents_apis";
import { userContext } from "../../Stores/Store";
import { useKeycloak } from "@react-keycloak/web";
import Loading from "../../Helpers/Loading";

const DocumentMove = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const handleClose = () => props.show(false);
  const user = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const [folders, setFolders] = useState();
  const [errorForm, setErrorForm] = useState();

  useEffect(() => {
    if (user.competitionid !== 0 && user.skillid !== 0) {
      setLoading(true);
      _api
        .getDocumentsFolders(keycloak.token, user.competitionid, user.skillid)
        .then((res) => setFolders(res))
        .then(() => setLoading(false));
    }
  }, []);

  const moveDocument = (id) => {
    _api.moveDocument(keycloak.token, props.data.id, id).then((results) => {
      if (results) {
        props.maj(props.data, "move");
        props.show(false);
      } else {
        setErrorForm("Une erreur technique s'est produite");
      }
    });
  };

  const listFolders = () => {
    return folders
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((folder) => { if(folder.create)
        return (
          <>
            {(props.folderid != folder.id) && (
              <ListGroupItem className="list_move">
                <span>{folder.name} </span>
                <a
                  style={{ cursor: "pointer", float: "right" }}
                  onClick={() => moveDocument(folder.id)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-folder-symlink"
                    viewBox="0 0 16 16"
                  >
                    <path d="m11.798 8.271-3.182 1.97c-.27.166-.616-.036-.616-.372V9.1s-2.571-.3-4 2.4c.571-4.8 3.143-4.8 4-4.8v-.769c0-.336.346-.538.616-.371l3.182 1.969c.27.166.27.576 0 .742z" />
                    <path d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14h10.348a2 2 0 0 0 1.991-1.819l.637-7A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm.694 2.09A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09l-.636 7a1 1 0 0 1-.996.91H2.826a1 1 0 0 1-.995-.91l-.637-7zM6.172 2a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z" />
                  </svg>
                </a>
              </ListGroupItem>
            )}
          </>
        );
      });
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;
  return (
    <>
      <Modal
        className="modal_app"
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        centered="true"
        size="m"
      >
        <Modal.Header closeButton>
          <Modal.Title>Déplacer {props.data.name} vers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup className="list-group-flush">
            {folders && listFolders()}
          </ListGroup>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DocumentMove;
