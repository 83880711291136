import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { userContext } from "../../Stores/Store";
import * as _api from "../../Apis/Documents_apis";
import { useKeycloak } from "@react-keycloak/web";
import Loading from "../../Helpers/Loading";

const DocumentsFolderEdit = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const handleClose = () => props.show(false);
  const user = useContext(userContext);
  const [folder, setFolder] = useState(props.data);
  const [loading, setLoading] = useState(false);
  const [errorForm, setErrorForm] = useState();

  const handleChange = (e) => {
    setFolder({ ...folder, [e.target.name]: e.target.value });
  };

  const updateFolder = () => {
      _api
        .postFolder(
          keycloak.token,
          folder.id,
          folder
        )
        .then((results) => {
          if (results) {
            props.maj(folder, "updateFolder");
            props.show(false);
          } else {
            setErrorForm("Une erreur technique s'est produite");
          }
        });
  };

  const deleteFolder = () => {
    _api.deleteFolder(keycloak.token, folder.id).then((results) => {
      if (results) {
        props.maj(folder, "deleteFolder");
        props.show(false);
      } else {
        setErrorForm("Une erreur technique s'est produite");
      }
    });
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <Modal
      className="modal_app"
      show={props.show}
      onHide={handleClose}
      backdrop="static"
      centered="true"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Modifier un dossier</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control
                  placeholder="Nom du dossier"
                  value={folder.name}
                  name="name"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>

      <Modal.Footer>
      {(folder.folderUpdate) &&
        <Button onClick={() => updateFolder()}>Modifier</Button>
      }
      {(folder.folderDelete) && 
        <Button variant="danger" onClick={() => deleteFolder()}>
          Supprimer
        </Button>
        }
        <Button variant="secondary" onClick={handleClose}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DocumentsFolderEdit;
