import React, { useContext } from "react";
import "./entrainements.scss";

import {
	Accordion,
	Button,
	Card,
	Col,
	OverlayTrigger,
	Row,
	Tooltip,
} from "react-bootstrap";
import { Navigate, NavLink, useParams, useNavigate } from "react-router-dom";
import { userContext } from "../../Stores/Store";
import EntrainementEdit from "./EntrainementEdit";
import EntrainementView from "./EntrainementView";
import { BsFillFolderFill } from "react-icons/bs";

const Entrainement = () => {
	const { entrainementId } = useParams();
	const user = useContext(userContext);
	const navigate = useNavigate();

	return (
		<>
			<Row className="header">
				<Col sm={4}>
					<span className="title">Sujet d'entrainement</span>
				</Col>
				<Col sm={7}>
					<Button variant="primary" onClick={() => navigate("/entrainements")}>
						<BsFillFolderFill /> Retour aux sujets d'entrainements
					</Button>
				</Col>
			</Row>
			{user.userRights && user.userRights.entrainement.update ? (
				<EntrainementEdit />
			) : (
				<EntrainementView />
			)}
		</>
	);
};

export default Entrainement;
