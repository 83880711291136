import React from 'react';

const Evaluations = () => {
    return (
        <div>
        <i>Fonctionnalité en cours de développement, Exemple de données</i><br />
        <a href="#1"><img src="./images/screenshots/btn_evaluer.png" /></a>
        <a href="#2"><img src="./images/screenshots/btn_notation.png" /></a><br />
        <br/><br/><br/><br/><br/><br/><br/><br/><br/>
        <img id="1" src="./images/screenshots/evaluer.png" /><br />
        <br/><br/><br/><br/><br/><br/><br/><br/><br/>
        <img id="2" src="./images/screenshots/notation1.png" /><br />
        <img src="./images/screenshots/notation2.png" /><br />
        </div>
    );
};

export default Evaluations;