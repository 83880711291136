import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';

const AlertMessage = (props) => {
    const [showAlert, setShowAlert] = useState(true);

    const listMessages = () => {
        return props.messages.map((item) => {
           return <li>{item}</li>
        })
    }

    if (showAlert) {
      return (
        <Alert variant="warning" onClose={() => {setShowAlert(false);props.errorMaj();}} dismissible>
          <Alert.Heading>Formulaire non valide car :</Alert.Heading>
          <p>
            {props.message && props.message}
            {Array.isArray(props.messages) && listMessages()}
            {!Array.isArray(props.messages) && props.messages}
          </p>
        </Alert>
      );
    }
    else {
        return '';
    }
  
};

export default AlertMessage;