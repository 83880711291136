import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { userContext } from "../../Stores/Store";
import DateTimePicker from "react-datetime-picker";
import * as tasks_api from "../../Apis/Tasks_apis";
import { controlForm } from "../../Helpers/FormValidate";
import AlertMessage from "../../Helpers/AlertMessage";
import Commentaires from "../Commentaires/Commentaires";
import Loading from "../../Helpers/Loading";

const TaskWidgetView = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const user = useContext(userContext);
  const [task, setTask] = useState({});
  const [errorForm, setErrorForm] = useState();
  const [loading, setLoading] = useState(false);

  const handleClose = () => props.show(false);

  // On réinitialise les erreurs quand on ferme l'alerte
  const initError = () => {
    setErrorForm();
  };

  useEffect(() => {
    if (props.id) {
      setLoading(true);
      tasks_api
        .getTaskIdAsync(keycloak.token, props.id)
        .then((res) => {
          setTask(res);
        })
        .then(() => setLoading(false));
    } else {
      setTask({
        color: "blue",
        name: "",
        due_date: new Date(),
        category: "",
        description: "",
        individual: false,
        assigned_to: [],
        solved_by: [],
        creation_date: new Date(),
        author: keycloak.tokenParsed.preferred_username,
      });
    }
  }, [props.id]);

  const handleChangeDate = (date, field) => {
    setTask({ ...task, [field]: date });
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case "individual":
        if (e.target.checked) {
          setTask({ ...task, individual: true });
        } else {
          setTask({ ...task, individual: false });
        }
        break;
      case "assigned_to":
        if (e.target.checked) {
          setTask({
            ...task,
            assigned_to: [...task.assigned_to, e.target.value],
          });
        } else {
          let temptable = [...task.assigned_to];
          let index = temptable.indexOf(e.target.value);
          if (index !== -1) {
            temptable.splice(index, 1);
            setTask({ ...task, assigned_to: temptable });
          }
        }
        break;
      default:
        setTask({ ...task, [e.target.name]: e.target.value });
        break;
    }
  };

  const deleteTask = () => {
    let checkErrors = controlForm("task", task); // Contrôle du formulaire
    if (checkErrors.length === 0) {
      tasks_api.deleteTask(keycloak.token, task.id).then((results) => {
        if (results) {
          props.maj(task, "delete");
          props.show(false);
        } else {
          setErrorForm("Une erreur technique s'est produite");
        }
      });
    } else {
      setErrorForm(checkErrors);
    }
  };

  const addTask = () => {
    let checkErrors = controlForm("task", task); // Contrôle du formulaire
    task.status = 0;
    if (checkErrors.length === 0) {
      tasks_api
        .putTask(keycloak.token, user.competitionid, user.skillid, task)
        .then((results) => {
          if (results) {
            props.maj(results, "add"); //on renvoie le résultat vers le state à modifier du parent
            props.show(false);
          } else {
            setErrorForm("Une erreur technique s'est produite");
          }
        });
    } else {
      setErrorForm(checkErrors);
    }
  };

  const updateTask = () => {
    let checkErrors = controlForm("task", task); // Contrôle du formulaire
    if (checkErrors.length === 0) {
      tasks_api.postTask(keycloak.token, task.id, task).then((results) => {
        if (results) {
          props.maj(task, "update");
          props.show(false);
        } else {
          setErrorForm("Une erreur technique s'est produite");
        }
      });
    } else {
      setErrorForm(checkErrors);
    }
  };

  const listContacts = () =>
    user.contactsSkill.map((contact) => {
      return (
        <Form.Check className={(task.solved_by && task.solved_by.indexOf(contact.username) !== -1) && "task_done"}
          inline
          label={contact.last_name + " " + contact.first_name}
          name="assigned_to"
          type="checkbox"
          value={contact.username}
          id={`inline-radio-${contact.username}`}
          onChange={handleChange}
          checked={
            task.assigned_to &&
            task.assigned_to.indexOf(contact.username) !== -1
              ? true
              : false
          }
        />
      );
    });

    //Contrôle du chargement des données
    if (loading) return <Loading />;

  return (
    <>
      <Modal
        className="modal_app"
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        centered="true"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id ? "Modifier" : "Ajouter"} une tâche
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col>
                <Form.Label>Informations</Form.Label>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Check
                    className="event_blue"
                    inline
                    name="color"
                    type="radio"
                    value="blue"
                    checked={task.color === "blue" ? true : false}
                    onChange={handleChange}
                  />
                  <Form.Check
                    className="event_green"
                    inline
                    name="color"
                    type="radio"
                    value="green"
                    checked={task.color === "green" ? true : false}
                    onChange={handleChange}
                  />
                  <Form.Check
                    className="event_orange"
                    inline
                    name="color"
                    type="radio"
                    value="orange"
                    checked={task.color === "orange" ? true : false}
                    onChange={handleChange}
                  />
                  <Form.Check
                    className="event_red"
                    inline
                    name="color"
                    type="radio"
                    value="red"
                    checked={task.color === "red" ? true : false}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    placeholder="Nom de la tâche"
                    name="name"
                    value={task.name}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    placeholder="Note"
                    name="description"
                    rows={3}
                    value={task.description}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    placeholder="Catégorie"
                    name="category"
                    value={task.category}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <DateTimePicker
                    locale="fr-FR"
                    name="due_date"
                    value={
                      typeof task.due_date === "string"
                        ? new Date(task.due_date)
                        : task.due_date
                    }
                    onChange={(value) => handleChangeDate(value, "due_date")}
                    required="true"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    name="individual"
                    label="Tâche à réaliser par l'ensemble des assignées"
                    onChange={handleChange}
                    checked={task.individual}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Form.Group className="mb-3">
                {user.contactsSkill && listContacts()}
              </Form.Group>
            </Row>

            {/* Affichage des erreurs */}
            {errorForm && (
              <AlertMessage messages={errorForm} errorMaj={initError} />
            )}
          </Form>
          {props.id &&
          <Commentaires module="todolists" id={task.id}/>
          }
        </Modal.Body>

        <Modal.Footer>
          {user.userRights && user.userRights.todo.update && props.id && (
            <Button onClick={() => updateTask()}>Modifier</Button>
          )}
          {user.userRights && user.userRights.todo.create && !props.id && (
            <Button onClick={() => addTask()}>Ajouter</Button>
          )}
          {user.userRights && user.userRights.todo.delete && props.id && (
            <Button variant="danger" onClick={() => deleteTask()}>
              Supprimer
            </Button>
          )}
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TaskWidgetView;
