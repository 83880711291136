import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import AlertMessage from "../../Helpers/AlertMessage";
import { userContext } from "../../Stores/Store";
import * as events_api from "../../Apis/Events_apis";
import { controlForm } from "../../Helpers/FormValidate";
import moment from "moment";
import DateTimePicker from "react-datetime-picker";
import Commentaires from "../Commentaires/Commentaires";
import Loading from "../../Helpers/Loading";

export const PlanningView = (props) => {
	const { keycloak, initialized } = useKeycloak();
	const user = useContext(userContext);
	const [event, setEvent] = useState({
		color: "blue",
		title: "",
		training_number: 1,
		start: new Date(),
		end: new Date(),
		localisation: "training_center",
		note: "",
		all_day: false,
		users: [],
	});
	const [errorForm, setErrorForm] = useState();
	const [showTime, setShowTime] = useState(true);
	const [loading, setLoading] = useState(false);
	const [showConvocationDate, setShowConvocationDate] = useState(false);
	const [initialValueConfirmed, setInitialValueConfirmed] = useState();
	const [typeEvent, setTypeEvent] = useState("others"); // others = Autres, international_training = Stage à l'international, technical_training = Stage technique

	const handleClose = () => props.show(false);

	// On réinitialise les erreurs quand on ferme l'alerte
	const initError = () => {
		setErrorForm();
	};

	const deleteEvent = () => {
		let checkErrors = controlForm("planning", event); // Contrôle du formulaire
		if (checkErrors.length === 0) {
			events_api.deleteEvent(keycloak.token, event.id).then((results) => {
				if (results) {
					props.maj(event, "delete");
					props.show(false);
				} else {
					setErrorForm("Une erreur technique s'est produite");
				}
			});
		} else {
			setErrorForm(checkErrors);
		}
	};

	const updateEvent = () => {
		// On force l'heure si All_Day
		if (!showTime) {
			let startDate = moment(event.start).format("YYYY-MM-DDT08:00:00");
			let endDate = moment(event.end).format("YYYY-MM-DDT23:00:00");
			event.start = new Date(startDate);
			event.end = new Date(endDate);
		}
		let checkErrors = controlForm("planning", event); // Contrôle du formulaire
		if (checkErrors.length === 0) {
			events_api.postEvent(keycloak.token, event.id, event).then((results) => {
				if (results) {
					props.maj(event, "update");
					props.show(false);
				} else {
					setErrorForm("Une erreur technique s'est produite");
				}
			});
		} else {
			setErrorForm(checkErrors);
		}
	};

	const addEvent = () => {
		// On force l'heure si All_Day
		if (!showTime) {
			let startDate = moment(event.start).format("YYYY-MM-DDT08:00:00");
			let endDate = moment(event.end).format("YYYY-MM-DDT23:00:00");
			event.start = new Date(startDate);
			event.end = new Date(endDate);
		}
		let checkErrors = controlForm("planning", event); // Contrôle du formulaire
		if (checkErrors.length === 0) {
			events_api
				.putEvent(keycloak.token, user.competitionid, user.skillid, event)
				.then((results) => {
					if (results) {
						props.maj(results, "add"); //on renvoie le résultat vers le state à modifier du parent
						props.show(false);
					} else {
						setErrorForm("Une erreur technique s'est produite");
					}
				});
		} else {
			setErrorForm(checkErrors);
		}
	};

	useEffect(() => {
		if (props.id) {
			setLoading(true);
			events_api
				.getEventIdAsync(keycloak.token, props.id)
				.then((res) => {
					setEvent(res);
					setTypeEvent(res.event_type);
					setShowConvocationDate(res.confirmed);
					res.all_day && setShowTime(false);
					setInitialValueConfirmed(res.confirmed);
				})
				.then(() => setLoading(false));
		} else {
			setEvent({
				color: "blue",
				title: "",
				start: new Date(),
				end: new Date(),
				localisation: "",
				note: "",
				all_day: false,
				users: [],
			});
		}
	}, [props.id]);

	const handleChangeDate = (date, field) => {
		if (field === "start" && date > event.end) event.end = date;
		setEvent({ ...event, [field]: date });
	};

	const handleChangeAllDay = (e) => {
		if (e.target.checked) {
			setShowTime(false);
			setEvent({ ...event, [e.target.name]: e.target.checked });
		}
		if (!e.target.checked) {
			setShowTime(true);
			setEvent({ ...event, [e.target.name]: e.target.checked });
		}
	};

	const handleChangeConfirmed = (e) => {
		if (e.target.checked) {
			setShowConvocationDate(true);
			setEvent({ ...event, [e.target.name]: e.target.checked });
		}
		if (!e.target.checked) {
			setShowConvocationDate(false);
			setEvent({ ...event, [e.target.name]: e.target.checked });
		}
	};

	const handleChange = (e) => {
		switch (e.target.name) {
			case "users":
				if (e.target.checked) {
					setEvent({ ...event, users: [...event.users, e.target.value] });
				} else {
					let temptable = [...event.users];
					let index = temptable.indexOf(e.target.value);
					if (index !== -1) {
						temptable.splice(index, 1);
						setEvent({ ...event, users: temptable });
					}
				}
				break;
			default:
				setEvent({ ...event, [e.target.name]: e.target.value });
				break;
		}
	};

	const listContacts = () =>
		user.contactsSkill.map((contact) => {
			return (
				<Form.Check
					disabled={
						(props.id && !event.editable && true) ||
						(event.event_type === "technical_training" &&
							event.confirmed &&
							user.userRoles[0] !== "admin" &&
							user.userRoles[0] !== "staff")
					}
					inline
					label={contact.last_name + " " + contact.first_name}
					name="users"
					type="checkbox"
					value={contact.username}
					id={`inline-radio-${contact.username}`}
					onChange={handleChange}
					checked={
						event.users && event.users.indexOf(contact.username) !== -1
							? true
							: false
					}
				/>
			);
		});

	useEffect(() => {
		if (typeEvent === "technical_training") {
			event.training_number = event.training_number || 1;
			setEvent({
				...event,
				title: "Stage Technique " + event.training_number,
				all_day: true,
				event_type: "technical_training",
			});
			setShowTime(false);
		} else {
			setEvent({
				...event,
				event_type: typeEvent,
			});
			setShowTime(true);
		}
	}, [typeEvent, event.training_number]);

	//Contrôle du chargement des données
	if (loading) return <Loading />;

	return (
		<Modal
			className="modal_app"
			show={props.show}
			onHide={handleClose}
			backdrop="static"
			centered="true"
			size="xl"
		>
			<Modal.Header closeButton>
				<Modal.Title>
					{props.id ? "Modifier" : "Ajouter"} un événement
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={(e) => e.preventDefault()}>
					<Row>
						<Col>
							<Form.Label>Type d'événement</Form.Label>
						</Col>
						<Col>
							<Form.Select
								name="event_type"
								value={typeEvent}
								onChange={(e) => setTypeEvent(e.target.value)}
								disabled={
									event.event_type === "technical_training" &&
									event.confirmed &&
									user.userRoles[0] !== "admin" &&
									user.userRoles[0] !== "staff"
								}
							>
								<option
									selected={event.event_type === "others"}
									value={"others"}
								>
									Autres
								</option>
								<option
									selected={event.event_type === "international_training"}
									value={"international_training"}
								>
									Stage à l'international
								</option>
								<option
									selected={event.event_type === "technical_training"}
									value={"technical_training"}
								>
									Stage technique
								</option>
							</Form.Select>
						</Col>
					</Row>
					{typeEvent === "international_training" && (
						<Row>
							<Col className="text-center mt-3">
								⚠ Pour ce type d'événement vous devez impérativement prendre
								contact avec Guadalupe (Lu) Garcia pour l'organisation de ce
								déplacement. ⚠
							</Col>
						</Row>
					)}
					<hr />
					<Row>
						<Col>
							<Form.Label>Informations</Form.Label>
						</Col>
						<Col>
							<Form.Group className="mb-3">
								<Form.Check
									disabled={
										(props.id && !event.editable && true) ||
										(event.event_type === "technical_training" &&
											event.confirmed &&
											user.userRoles[0] !== "admin" &&
											user.userRoles[0] !== "staff")
									}
									className="event_blue"
									inline
									name="color"
									type="radio"
									value="blue"
									onChange={handleChange}
									checked={event.color === "blue" ? true : false}
								/>
								<Form.Check
									disabled={
										(props.id && !event.editable && true) ||
										(event.event_type === "technical_training" &&
											event.confirmed &&
											user.userRoles[0] !== "admin" &&
											user.userRoles[0] !== "staff")
									}
									className="event_green"
									inline
									name="color"
									type="radio"
									value="green"
									onChange={handleChange}
									checked={event.color === "green" ? true : false}
								/>
								<Form.Check
									disabled={
										(props.id && !event.editable && true) ||
										(event.event_type === "technical_training" &&
											event.confirmed &&
											user.userRoles[0] !== "admin" &&
											user.userRoles[0] !== "staff")
									}
									className="event_orange"
									inline
									name="color"
									type="radio"
									value="orange"
									onChange={handleChange}
									checked={event.color === "orange" ? true : false}
								/>
								<Form.Check
									disabled={
										(props.id && !event.editable && true) ||
										(event.event_type === "technical_training" &&
											event.confirmed &&
											user.userRoles[0] !== "admin" &&
											user.userRoles[0] !== "staff")
									}
									className="event_red"
									inline
									name="color"
									type="radio"
									value="red"
									onChange={handleChange}
									checked={event.color === "red" ? true : false}
								/>
							</Form.Group>
						</Col>
					</Row>
					{typeEvent === "technical_training" && (
						<Row>
							<Form.Label>Stage Numéro</Form.Label>
							<Form.Group className="mb-3">
								<Form.Control
									disabled={
										(props.id && !event.editable && true) ||
										(event.event_type === "technical_training" &&
											event.confirmed &&
											user.userRoles[0] !== "admin" &&
											user.userRoles[0] !== "staff")
									}
									placeholder="Numéro du stage"
									name="training_number"
									value={event.training_number}
									onChange={handleChange}
								/>
							</Form.Group>
						</Row>
					)}
					<Row>
						<Form.Group className="mb-3">
							<Form.Control
								disabled={
									typeEvent === "technical_training" ||
									(props.id && !event.editable && true) ||
									(event.event_type === "technical_training" &&
										event.confirmed &&
										user.userRoles[0] !== "admin" &&
										user.userRoles[0] !== "staff")
								}
								placeholder="Nom de l'événement"
								name="title"
								value={event.title}
								onChange={handleChange}
							/>
						</Form.Group>
					</Row>
					<Row>
						<Col sm={2}>
							<Form.Label htmlFor="start">Date de début</Form.Label>
						</Col>
						<Col>
							<Form.Group className="mb-3">
								<DateTimePicker
									disabled={
										(props.id && !event.editable && true) ||
										(event.event_type === "technical_training" &&
											event.confirmed &&
											user.userRoles[0] !== "admin" &&
											user.userRoles[0] !== "staff")
									}
									locale="fr-FR"
									value={
										typeof event.start === "string"
											? new Date(event.start)
											: event.start
									}
									name="start"
									onChange={(value) => handleChangeDate(value, "start")}
									format={showTime ? "" : "d/MM/yyyy"}
									required="true"
								/>
							</Form.Group>
						</Col>
						<Col sm={2}>
							<Form.Label htmlFor="end">Date de fin</Form.Label>
						</Col>
						<Col>
							<Form.Group className="mb-3">
								<DateTimePicker
									disabled={
										(props.id && !event.editable && true) ||
										(event.event_type === "technical_training" &&
											event.confirmed &&
											user.userRoles[0] !== "admin" &&
											user.userRoles[0] !== "staff")
									}
									locale="fr-FR"
									name="end"
									value={
										typeof event.end === "string"
											? new Date(event.end)
											: event.end
									}
									onChange={(value) => handleChangeDate(value, "end")}
									format={showTime ? "" : "d/MM/yyyy"}
									required="true"
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Group className="mb-3">
								<Form.Check
									inline
									disabled={
										typeEvent === "technical_training" ||
										(props.id && !event.editable && true) ||
										(event.event_type === "technical_training" &&
											event.confirmed &&
											user.userRoles[0] !== "admin" &&
											user.userRoles[0] !== "staff")
									}
									label="Toute la journée"
									name="all_day"
									type="switch"
									value="true"
									onChange={handleChangeAllDay}
									checked={event.all_day}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Group className="mb-3">
							{typeEvent !== "technical_training" ? (
								<Form.Control
									disabled={
										(props.id && !event.editable && true) ||
										(event.event_type === "technical_training" &&
											event.confirmed &&
											user.userRoles[0] !== "admin" &&
											user.userRoles[0] !== "staff")
									}
									placeholder="Lieu de l'événement"
									name="localisation"
									value={event.localisation}
									onChange={handleChange}
								/>
							) : (
								<>
									<Col>
										<Form.Label>Lieu de l'événement</Form.Label>
									</Col>
									<Col>
										<Form.Select
											name="localisation"
											value={event.localisation}
											onChange={handleChange}
											disabled={
												(props.id && !event.editable && true) ||
												(event.event_type === "technical_training" &&
													event.confirmed &&
													user.userRoles[0] !== "admin" &&
													user.userRoles[0] !== "staff")
											}
										>
											<option value={"training_center"}>
												Centre d'Excellence
											</option>
											<option value={"distanciel"}>Distanciel</option>
											<option value={"others"}>Autres</option>
										</Form.Select>
									</Col>
								</>
							)}
						</Form.Group>
					</Row>
					{typeEvent === "technical_training" &&
						event.localisation === "others" && (
							<>
								<Row>
									<Col>
										<Form.Group className="mb-3">
											<Form.Control
												disabled={
													(props.id && !event.editable && true) ||
													(event.event_type === "technical_training" &&
														event.confirmed &&
														user.userRoles[0] !== "admin" &&
														user.userRoles[0] !== "staff")
												}
												placeholder="Nom (ou statut) du lieu de formation"
												name="training_center_name"
												value={event.training_center_name}
												onChange={handleChange}
											/>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group className="mb-3">
											<Form.Control
												disabled={
													(props.id && !event.editable && true) ||
													(event.event_type === "technical_training" &&
														event.confirmed &&
														user.userRoles[0] !== "admin" &&
														user.userRoles[0] !== "staff")
												}
												placeholder="Adresse du lieu de formation"
												name="training_center_address"
												value={event.training_center_address}
												onChange={handleChange}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col>
										<Form.Group className="mb-3">
											<Form.Control
												disabled={
													(props.id && !event.editable && true) ||
													(event.event_type === "technical_training" &&
														event.confirmed &&
														user.userRoles[0] !== "admin" &&
														user.userRoles[0] !== "staff")
												}
												placeholder="Code postal du lieu de formation"
												name="training_center_postal_code"
												value={event.training_center_postal_code}
												onChange={handleChange}
											/>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group className="mb-3">
											<Form.Control
												disabled={
													(props.id && !event.editable && true) ||
													(event.event_type === "technical_training" &&
														event.confirmed &&
														user.userRoles[0] !== "admin" &&
														user.userRoles[0] !== "staff")
												}
												placeholder="Ville du lieu de formation"
												name="training_center_city"
												value={event.training_center_city}
												onChange={handleChange}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col>
										<Form.Group className="mb-3">
											<Form.Control
												disabled={
													(props.id && !event.editable && true) ||
													(event.event_type === "technical_training" &&
														event.confirmed &&
														user.userRoles[0] !== "admin" &&
														user.userRoles[0] !== "staff")
												}
												placeholder="Nom du contact du lieu de formation"
												name="training_center_contact_name"
												value={event.training_center_contact_name}
												onChange={handleChange}
											/>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group className="mb-3">
											<Form.Control
												disabled={
													(props.id && !event.editable && true) ||
													(event.event_type === "technical_training" &&
														event.confirmed &&
														user.userRoles[0] !== "admin" &&
														user.userRoles[0] !== "staff")
												}
												placeholder="Fonction du contact du lieu de formation"
												name="training_center_contact_position"
												value={event.training_center_contact_position}
												onChange={handleChange}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col>
										<Form.Group className="mb-3">
											<Form.Control
												disabled={
													(props.id && !event.editable && true) ||
													(event.event_type === "technical_training" &&
														event.confirmed &&
														user.userRoles[0] !== "admin" &&
														user.userRoles[0] !== "staff")
												}
												placeholder="Email du contact du lieu de formation"
												name="training_center_contact_email"
												value={event.training_center_contact_email}
												onChange={handleChange}
											/>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group className="mb-3">
											<Form.Control
												disabled={
													(props.id && !event.editable && true) ||
													(event.event_type === "technical_training" &&
														event.confirmed &&
														user.userRoles[0] !== "admin" &&
														user.userRoles[0] !== "staff")
												}
												placeholder="Téléphone du contact du lieu de formation"
												name="training_center_contact_phone"
												value={event.training_center_contact_phone}
												onChange={handleChange}
											/>
										</Form.Group>
									</Col>
								</Row>
							</>
						)}
					{typeEvent !== "technical_training" && (
						<Row>
							<Form.Group className="mb-3">
								<Form.Control
									disabled={
										(props.id && !event.editable && true) ||
										(event.event_type === "technical_training" &&
											event.confirmed &&
											user.userRoles[0] !== "admin" &&
											user.userRoles[0] !== "staff")
									}
									as="textarea"
									placeholder="Note"
									name="note"
									value={event.note}
									onChange={handleChange}
									rows={3}
								/>
							</Form.Group>
						</Row>
					)}
					<Row>
						<Form.Label>
							{typeEvent === "technical_training" &&
								"Liste des personnes à convoquer au stage"}
						</Form.Label>
						<Form.Group className="mb-3">
							{user.contactsSkill && listContacts()}
						</Form.Group>
					</Row>
					{typeEvent === "technical_training" && (
						<>
							<Row>
								<Col>
									<Form.Group className="mb-3">
										<Form.Control
											disabled={
												(props.id && !event.editable && true) ||
												(event.event_type === "technical_training" &&
													event.confirmed &&
													user.userRoles[0] !== "admin" &&
													user.userRoles[0] !== "staff")
											}
											placeholder="Horaire de formation du 1er jour (ex 10h00 à 12h00 et 14h00 à 17h00)"
											name="first_day_hours"
											value={event.first_day_hours}
											onChange={handleChange}
										/>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group className="mb-3">
										<Form.Control
											disabled={
												(props.id && !event.editable && true) ||
												(event.event_type === "technical_training" &&
													event.confirmed &&
													user.userRoles[0] !== "admin" &&
													user.userRoles[0] !== "staff")
											}
											placeholder="Horaire de formation du dernier jour  (ex 8h00 à 12h00 et 14h00 à 15h00)"
											name="last_day_hours"
											value={event.last_day_hours}
											onChange={handleChange}
										/>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group className="mb-3">
										<Form.Control
											disabled={
												(props.id && !event.editable && true) ||
												(event.event_type === "technical_training" &&
													event.confirmed &&
													user.userRoles[0] !== "admin" &&
													user.userRoles[0] !== "staff")
											}
											placeholder="Horaire de formation des autres jours de formation (ex 8h00 à 12h00 et 14h00 à 17h00)"
											name="others_day_hours"
											value={event.others_day_hours}
											onChange={handleChange}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Group>
										<Form.Check
											inline
											disabled={
												(props.id && !event.editable && true) ||
												(props.id &&
													event.event_type === "technical_training" &&
													initialValueConfirmed &&
													user.userRoles[0] !== "admin" &&
													user.userRoles[0] !== "staff")
											}
											label="Dates confirmées"
											name="confirmed"
											type="switch"
											value="true"
											onChange={handleChangeConfirmed}
											checked={event.confirmed}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col>
									<span className="text-secondary">
										⚠️ La confirmation des dates verrouillera l'ensemble des
										champs
									</span>
								</Col>
							</Row>
							{showConvocationDate &&  (
									<Row>
										<Col>
											<Form.Label htmlFor="start">
												Date d'envoi des convocations
											</Form.Label>
										</Col>
										<Col>
											<Form.Group>
												<DateTimePicker
													disabled={
														(props.id && !event.editable && true) ||
														(event.event_type === "technical_training" &&
															event.confirmed &&
															user.userRoles[0] !== "admin" &&
															user.userRoles[0] !== "staff")
													}
													locale="fr-FR"
													value={
														typeof event.convocation_date === "string"
															? new Date(event.convocation_date)
															: event.convocation_date
													}
													name="convocation_date"
													onChange={(value) =>
														handleChangeDate(
															moment(value).format("YYYY-MM-DD"),
															"convocation_date",
														)
													}
													format={"d/MM/yyyy"}
													required="true"
												/>
											</Form.Group>
										</Col>
									</Row>
								)}
							<Row>
								<Form.Group className="mb-3">
									<Form.Control
										disabled={
											(props.id && !event.editable && true) ||
											(event.event_type === "technical_training" &&
												event.confirmed &&
												user.userRoles[0] !== "admin" &&
												user.userRoles[0] !== "staff")
										}
										as="textarea"
										placeholder="Note supplémentaire de l'expert"
										name="note"
										value={event.note}
										onChange={handleChange}
										rows={5}
									/>
								</Form.Group>
							</Row>
						</>
					)}

					{/* Affichage des erreurs */}
					{errorForm && (
						<AlertMessage messages={errorForm} errorMaj={initError} />
					)}
				</Form>

				{props.id && <Commentaires module="events" id={event.id} />}
			</Modal.Body>

			<Modal.Footer>
				{user.userRights && event.editable && (
					<Button onClick={() => updateEvent()}>Modifier</Button>
				)}
				{!props.id && <Button onClick={() => addEvent()}>Ajouter</Button>}

				{props.id && event.editable && (
					<Button variant="danger" onClick={() => deleteEvent()}>
						Supprimer
					</Button>
				)}
				<Button variant="secondary" onClick={handleClose}>
					Fermer
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default PlanningView;
