import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import Loading from "../../Helpers/Loading";
import { userContext } from "../../Stores/Store";
import * as _api from "../../Apis/Configuration_apis";
import * as me_api from "../../Apis/Me_apis";
import { useKeycloak } from "@react-keycloak/web";
import AlertMessage from "../../Helpers/AlertMessage";
import { roles } from "../../Helpers/Data";
import moment from "moment";
import { BsBoxArrowInUpRight, BsCardList, BsPersonBadge } from "react-icons/bs";

const Questions = () => {
  const user = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState("");
  const [competitions, setCompetitions] = useState();
  const [competitionId, setCompetitionId] = useState(null);
  const [skills, setSkills] = useState();
  const [skillId, setSkillId] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const { keycloak, initialized } = useKeycloak();
  const [showModalFolRights, setShowModalFolRights] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState();
  const handleModalFolRights = (status) => setShowModalFolRights(status); //fonction à passer au child pour MAJ status modal
  const [competitionSearch, setCompetitionSearch] = useState();
  const [skillSearch, setSkillSearch] = useState();
  const [errorForm, setErrorForm] = useState();
  const [purge, setPurge] = useState(false);
  const [logs, setLogs] = useState();
  const [showLogs, setShowLogs] = useState(false);

  // On réinitialise les erreurs quand on ferme l'alerte
  const initError = () => {
    setErrorForm();
  };

  useEffect(() => {
    if (competitionSearch != null && !skillSearch != null) {
      setLoading(true);
      _api
        .getQuestions(keycloak.token)
        .then((res) => setQuestions(res))
        .then(() => setLoading(false));
    } else {
      setLoading(true);
      _api
        .getQuestions(keycloak.token, user.competitionid, user.skillid)
        .then((res) => setQuestions(res))
        .then(() => setLoading(false));
    }
  }, [user.skillid, user.competitionid]);

  useEffect(() => {
    if (competitionId) {
      setLoading(true);
      me_api
        .getSkillsAsync(keycloak.token, competitionId)
        .then((res) => setSkills(res))
        .then(() => setLoading(false));
    } else {
      setSkills();
      setSkillId(null);
    }
  }, [competitionId]);

  useEffect(() => {
    setLoading(true);
    if (!competitionSearch) setCompetitionSearch(user.competitionid);
    if (!skillSearch) setSkillSearch(user.skillid);
    me_api
      .getCompetitionsAsync(keycloak.token)
      .then((res) => {
        setCompetitions(res);
      })
      .then(() => setLoading(false));
  }, []);

  const addQuestion = () => {
    if (question.name) {
      if (question.competition == "") {
        question.skill = null;
        question.competition = null;
      }
      if (question.skill == "") question.skill = null;
      if (question.role == "") question.role = null;
      _api.putQuestion(keycloak.token, question).then((res) => {
        if (res.status) {
          setErrorForm("Code Erreur " + res.status + ": " + res.msg);
        } else {
          setQuestions([res, ...questions]);
          eraseForm();
        }
      });
    }
  };

  const updateQuestion = () => {
    if (question.id) {
      if (question.competition == "") {
        question.skill = null;
        question.competition = null;
      }
      if (question.skill == "") question.skill = null;
      if (question.role == "") question.role = null;
      _api
        .postQuestion(keycloak.token, question.id, purge, question)
        .then((res) => {
          if (res.status) {
            setErrorForm("Code Erreur " + res.status + ": " + res.msg);
          } else {
            let arrayTemp = [];
            let folderIndex = questions.findIndex((obj) => obj.id == res.id);
            arrayTemp = [...questions];
            arrayTemp[folderIndex] = res;
            setQuestions(arrayTemp);
            eraseForm();
          }
        });
    }
  };

  const deleteFolder = () => {
    if (question.id) {
      if (confirmDelete == question.name) {
        _api
          .deleteQuestion(keycloak.token, question.id)
          .then((res) => {
            setQuestions(questions.filter((item) => item.id !== question.id));
          })
          .then(() => setQuestion(""))
          .then(() => setShowDelete(false));
      }
    }
  };

  const eraseForm = () => {
    setQuestion("");
  };

  const search = () => {
    _api
      .getQuestions(keycloak.token, competitionSearch, skillSearch)
      .then((res) => setQuestions(res));
  };

  const listQuestions = () => {
    return questions.map((question) => {
      return (
        <>
          <tr>
            <td>{question.id}</td>
            <td>{question.name}</td>
            <td>{question.competition}</td>
            <td>{question.skill}</td>
            <td>{question.role}</td>
            <td>
              {question.link && (
                <a href={question.link} target="_blank">
                  <BsBoxArrowInUpRight />
                </a>
              )}
            </td>
            <td>
              {question.update_date &&
                moment(question.update_date).format("DD/MM/YYYY")}
            </td>
            <td>{question.comment}</td>
            <td>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setQuestion(question);
                  setCompetitionId(question.competition);
                  setSkillId(question.skill);
                  setShowDelete(false);
                  setShowLogs(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-pencil"
                  viewBox="0 0 16 16"
                  color="blue"
                >
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>
              </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setQuestion(question);
                  setShowDelete(true);
                  setShowLogs(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-trash"
                  viewBox="0 0 16 16"
                  color="red"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fill-rule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  accessLogs(question.id);
                  setQuestion(question);
                }}
              >
                <BsCardList />
              </span>
            </td>
          </tr>
        </>
      );
    });
  };

  const listCompetitions = () => {
    return competitions
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((competition) => {
        return (
          <option
            selected={competition.id == competitionId}
            value={competition.id}
          >
            {competition.id} - {competition.name}
          </option>
        );
      });
  };

  const listLogs = () => {
    return logs.map((log) => {
      return (
        <Row style={{ margin: "10px" }}>
          <Col sm={1}>
            <BsPersonBadge />{" "}
          </Col>
          <Col>
            {log.username} ({moment(log.date).format("DD-MM-YYYY à HH:mm")})
          </Col>
        </Row>
      );
    });
  };

  const listSkills = () => {
    if (skills) {
      return skills
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((skill) => {
          return (
            <option value={skill.number} selected={skill.number == skillId}>
              {skill.number + " - " + skill.name}
            </option>
          );
        });
    }
  };

  const listRoles = () => {
    if (roles) {
      return roles
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((role) => {
          return <option value={role.name_db}>{role.name}</option>;
        });
    }
  };

  const accessLogs = (id) => {
    if (id) {
      _api
        .getQuestionLogs(keycloak.token, id)
        .then((res) => {
          setLogs(res);
        })
        .then(() => setShowLogs(true))
        .then(() => setShowDelete(false));
    }
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <>
      <Container fluid>
        {/* Affichage des erreurs */}
        {errorForm && <AlertMessage message={errorForm} errorMaj={initError} />}
        <Row>
          <Col sm={8}>
            <Row>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="ID Compétition"
                        defaultValue={user.competitionid}
                        value={competitionSearch}
                        onChange={(e) => setCompetitionSearch(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="ID Métier"
                        defaultValue={user.skillid}
                        value={skillSearch}
                        onChange={(e) => setSkillSearch(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Button variant="outline-success" onClick={() => search()}>
                      Chercher
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Row>
            <Row>
              <Table hover responsive striped>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nom</th>
                    <th>Compétition</th>
                    <th>Métier</th>
                    <th>Rôle</th>
                    <th>Lien</th>
                    <th>Date MAJ</th>
                    <th>Commentaire</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{questions && listQuestions()}</tbody>
              </Table>
            </Row>
          </Col>
          {!showDelete && !showLogs && (
            <Col>
              <h3>
                {!question.id ? "Ajouter un " : "Modifier le"} questionnaire
              </h3>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="Nom du questionnaire "
                        required
                        value={question && question.name}
                        onChange={(e) =>
                          setQuestion({ ...question, name: e.target.value })
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="url du questionnaire"
                        name="link"
                        value={question && question.link}
                        onChange={(e) =>
                          setQuestion({ ...question, link: e.target.value })
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Select
                      name="competition"
                      onChange={(e) => {
                        setQuestion({
                          ...question,
                          competition: e.target.value,
                        });
                        setCompetitionId(e.target.value);
                      }}
                      size="sm"
                      value={question && question.competition}
                    >
                      <option value="">0 - Toutes les compétitions</option>
                      {competitions && listCompetitions()}
                    </Form.Select>
                  </Col>
                  <Col>
                    <Form.Select
                      size="sm"
                      name="skill"
                      onChange={(e) => {
                        setQuestion({ ...question, skill: e.target.value });
                        setSkillId(e.target.value);
                      }}
                      value={question && question.skill}
                    >
                      <option value="">0 - Tous les métiers</option>
                      {skills && listSkills()}
                    </Form.Select>
                  </Col>
                  <Col>
                    <Form.Select
                      size="sm"
                      name="role_select"
                      onChange={(e) =>
                        setQuestion({ ...question, role: e.target.value })
                      }
                      value={question && question.role}
                    >
                      <option value="">0 - Tous les rôles</option>
                      {roles && listRoles()}
                    </Form.Select>
                  </Col>
                </Row>
                {question.id && (
                  <>
                    <hr />
                    <span>Mise à jour du questionnaire</span>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Control
                            placeholder="Votre commentaire"
                            required
                            onChange={(e) =>
                              setQuestion({
                                ...question,
                                comment: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Check
                            inline
                            label="Souhaitez vous purger les logs ?"
                            name="enabled"
                            type="switch"
                            value="false"
                            onChange={(e) => {
                              if (e.target.checked) setPurge(true);
                              if (!e.target.checked) setPurge(false);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col>
                    {!question.id && (
                      <Button
                        onClick={() => addQuestion()}
                        style={{ marginTop: "10px" }}
                      >
                        Ajouter
                      </Button>
                    )}
                    {question.id && (
                      <>
                        <Button
                          onClick={() => updateQuestion()}
                          style={{ marginTop: "10px" }}
                        >
                          Modifier
                        </Button>
                        &nbsp;
                        <Button
                          variant="outline-secondary"
                          onClick={() => eraseForm()}
                          style={{ marginTop: "10px" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-x-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                          </svg>
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </Form>
            </Col>
          )}
          {showLogs && logs && (
            <Col>
              <h3>Logs pour "{question.name}"</h3>
              <Form onSubmit={(e) => e.preventDefault()}>
                {logs && listLogs()}
                <Row>
                  <Col>
                    <Button
                      variant="outline-secondary"
                      onClick={() => {
                        setQuestion("");
                        setShowLogs(false);
                      }}
                    >
                      Annuler
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          )}
          {showDelete && (
            <Col>
              <h3>Supprimer {question.name}</h3>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Alert variant="warning">
                    Cette action sera irrévocable. Elle entrainera la
                    suppression de ce questionnaire et de ses logs.
                    <br />
                    Pour confirmer la suppression veuillez saisir le nom du
                    questionnaire complet et cliquer sur "Supprimer"
                  </Alert>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="Nom du questionnaire à supprimer"
                        required
                        value={confirmDelete}
                        onChange={(e) => setConfirmDelete(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Button
                      variant="outline-danger"
                      onClick={() => deleteFolder()}
                    >
                      Supprimer
                    </Button>
                    &nbsp;
                    <Button
                      variant="outline-secondary"
                      onClick={() => {
                        setQuestion("");
                        setShowDelete(false);
                      }}
                    >
                      Annuler
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Questions;
