import React, { useContext, useEffect, useState } from "react";
import { Col, Modal, Button, Row, Form, Alert } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./entrainements.scss";
import * as _api from "../../Apis/Entrainements_apis";
import { userContext } from "../../Stores/Store";
import { useKeycloak } from "@react-keycloak/web";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const CriteriaCreate = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const handleClose = () => props.show(false);
  const user = useContext(userContext);
  const [criteria, setCriteria] = useState({name : "", points : 0});
  const [errorForm, setErrorForm] = useState();

  const handleChange = (e) => {
    setCriteria({...criteria, [e.target.name] : e.target.value});
  };

  const addCrit = () => {
    _api
      .putEntrainementCrit(keycloak.token, props.entrainementId, criteria)
      .then((result) => {
        if (result) {
          props.result(result);
          props.show(false);
        } else {
          setErrorForm("Une erreur technique s'est produite");
        }
      });
  };

  return (
    <>
      <Modal
        className="modal_app"
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        centered="true"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un critère</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Form onSubmit={(e) => e.preventDefault()}>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <label>Nom du critère</label>
                    <Form.Control
                      value={criteria.name}
                      name="name"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className="mb-3">
                    <label>Nombre de points</label>
                    <Form.Control
                      value={criteria.points}
                      name="points"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => addCrit()}>
            Ajouter
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CriteriaCreate;
