import { useKeycloak } from "@react-keycloak/web";
import React, { useContext } from "react";
import { useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import * as _api from "../../Apis/Comments_apis";
import { useEffect } from "react";
import Loading from "../../Helpers/Loading";
import CommentaireView from "./CommentaireView";
import "./commentaires.scss";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { convertToRaw } from "draft-js";
import { ContentState } from "draft-js";
import { convertFromHTML } from "draft-js";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { userContext } from "../../Stores/Store";
import { Component } from "react";

const Commentaires = (props) => {
  const [commentaire, setCommentaire] = useState({});
  const { keycloak, initialized } = useKeycloak();
  const user = useContext(userContext);
  const [commentaires, setCommentaires] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reply, setReply] = useState(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(""))
    )
  );

  useEffect(() => {
    if (props.id && props.module) {
      setLoading(true);
      _api
        .getCommentsAsync(keycloak.token, props.module, props.id)
        .then((res) => setCommentaires(res))
        .then(() => setLoading(false));
    }
  }, [props.id, props.module]);

  const addComment = () => {
    commentaire.content = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    if (commentaire.content.replace(/<[^>]+>/g, "") != "") {
      if (reply) commentaire.parent_id = reply.id;
      commentaire.notions = [];
      _api
        .putComment(keycloak.token, props.module, props.id, commentaire)
        .then((results) => {
          if (results) {
            setLoading(true);
            if (reply) results.parent_content = reply.content;
            setCommentaires([results, ...commentaires]);

            setEditorState(
              EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  convertFromHTML('')
                )
              )
            );
            setLoading(false);
          } else {
            // setErrorForm("Une erreur technique s'est produite");
          }
        });
    }
  };

  const refact = (datas) => {
    let arrayTemp = [];
    datas.map((data) => {
      if (data.nb_references > 0) {
        arrayTemp[data.id] = data.content;
      }
    });
    let results = [];
    datas.map((data, index) => {
      results.push(data);
      if (arrayTemp[data.parent_id]) {
        results[index] = { ...data, parent_content: arrayTemp[data.parent_id] };
      }
    });
    return results;
  };

  const listCommentaires = () => {
    let commentairesMaj = refact(commentaires);
    return commentairesMaj.map((commentaire) => {
      return (
        <CommentaireView
          module={props.module}
          data={commentaire}
          reply={setReply}
        />
      );
    });
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <>
      <hr />
      <Container>
        <Row>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Col>
              <Editor
                toolbarOnFocus
                wrapperClassName="comment-wrapper"
                editorClassName="comment-editor"
                editorState={editorState}
                onEditorStateChange={setEditorState}
                placeholder={"Ecrire un commentaire"}
                toolbar={{
                  options: ["inline", "list", "link", "history", "emoji"],
                }}
                mention={{
                  separator: " ",
                  trigger: "@",
                  suggestions: [],
                }}
              />
            </Col>
            <Col className="buttons_comment">
              <Button variant="outline-secondary" onClick={() => addComment()}>
                {reply ? (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-reply-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.921 11.9 1.353 8.62a.719.719 0 0 1 0-1.238L5.921 4.1A.716.716 0 0 1 7 4.719V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z" />
                    </svg>
                    Répondre
                  </>
                ) : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-send-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                    </svg>{" "}
                    Envoyer
                  </>
                )}
              </Button>
              {reply && (
                <Button variant="outline-danger" onClick={() => setReply(null)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-x-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </Button>
              )}
            </Col>
            <Row>
              {reply && (
                <Alert variant="info">
                  En réponse au commentaire de {reply.author} : <br /> "
                  {reply.content}"
                </Alert>
              )}
            </Row>
          </Form>
        </Row>
        <br />
        <Row>
          <ListGroup>
            {!loading && commentaires && listCommentaires()}
          </ListGroup>
        </Row>
      </Container>
    </>
  );
};

export default Commentaires;
