/**
 * GET Events Async
 * @returns promise Events
 */
export const getEventsAsync = async (token, competitionid, skillid, start, end) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/events/?competition=${competitionid}&skill=${skillid}&start_date=${start}&end_date=${end}`,
      requestOptions
    );
    if(promise.status === 200){
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * GET Event by ID Async
 * @returns promise Events
 */
 export const getEventIdAsync = async (token, id) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/events/${id}`,
      requestOptions
    );
    if(promise.status === 200){
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * PUT Event Async
 * @returns HTTP Code
 */
export const putEvent = async (
  token,
  competitionid,
  skillid,
  formData
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/events/?competition=${competitionid}&skill=${skillid}`,
      requestOptions
    );
    if (promise.status === 201) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * POST Event Async
 * @returns HTTP Code
 */
export const postEvent = async (token, id, formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/events/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * DELETE Event Async
 * @returns HTTP Code
 */
export const deleteEvent = async (token, id) => {
  const requestOptions = {
    method: "DELETE",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/events/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};
