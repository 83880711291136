import {
  Col,
  Image,
  Row,
} from "react-bootstrap";
import * as _api from "../../Apis/Contacts_apis";
import "./contacts.scss";
import ContactsList from "./ContactsList";

const Contacts = () => {

  return (
    <>
      <Row className="header">
        <Col sm={2}>
          <Image
            className="img_title"
            src="images/icons/bigmenu_contacts.svg"
          />
          <span className="title">Contacts</span>
        </Col>
      </Row>
      <Row>
        <ContactsList />
      </Row>
    </>
  );
};

export default Contacts;