import React, { useContext, useEffect, useState } from "react";
import * as _api from "../../Apis/Documents_apis";
import { userContext } from "../../Stores/Store";
import { useKeycloak } from "@react-keycloak/web";
import { Accordion, Button, Col, NavLink, Row } from "react-bootstrap";
import DocumentsList from "./DocumentsList";
import { BsPencilFill, BsLockFill } from "react-icons/bs";
import FolderEdit from "./FolderEdit";
import FolderRights from "./FolderRights";
import DocumentAdd from "./DocumentAdd";
import FolderAdd from "./FolderAdd";
import Loading from "../../Helpers/Loading";

const DocumentsFolders = () => {
  const user = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const [folders, setFolders] = useState();
  const [folder, setFolder] = useState();
  const [folderId, setFolderId] = useState();
  const [showModalDocAdd, setShowModalDocAdd] = useState(false);
  const [showModalFolEdit, setShowModalFolEdit] = useState(false);
  const [showModalFolRights, setShowModalFolRights] = useState(false);
  const [showModalFolAdd, setShowModalFolAdd] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const handleModalFolEdit = (status) => setShowModalFolEdit(status); //fonction à passer au child pour MAJ status modal
  const handleModalFolRights = (status) => setShowModalFolRights(status); //fonction à passer au child pour MAJ status modal
  const handleModalDocAdd = (status) => setShowModalDocAdd(status); //fonction à passer au child pour MAJ status modal
  const handleModalFolAdd = (status) => setShowModalFolAdd(status); //fonction à passer au child pour MAJ status modal

  const { keycloak, initialized } = useKeycloak();

  //fonction à passer au child pour MAJ du State liste
  const majFromModal = (data, action) => {
    let arrayTemp = [{}];
    switch (action) {
      case "move":
        setRefresh(!refresh);
        break;
      case "deleteFolder":
        const deleteList = folders.filter((item) => item.name !== data.name);
        setFolders(deleteList);
        setRefresh(!refresh);
        break;
      case "updateFolder":
        let folderIndex = folders.findIndex((obj) => obj.id == data.id);
        arrayTemp = folders;
        arrayTemp[folderIndex] = data;
        setFolders(arrayTemp);
        setRefresh(!refresh);
        break;
      case "addFolder":
        arrayTemp = folders;
        arrayTemp.push(data);
        setFolders(arrayTemp);
        setRefresh(!refresh);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (user.competitionid !== 0 && user.skillid !== 0) {
      setLoading(true);
      _api
        .getDocumentsFolders(keycloak.token, user.competitionid, user.skillid)
        .then((res) => setFolders(res))
        .then(() => setLoading(false));
    }
  }, [user.skillid, user.competitionid, refresh]);

  const listFolders = () => {
    return folders
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((folder) => {
        return (
          <Accordion.Item  style={{background:"#F9FBFD"}} eventKey={folder.id}>
            <Accordion.Header
              className={
                folder.type == "g" ? "folder_worldskills" : "folder_skill"
              }
            >
              <Col sm={11}>{folder.name}</Col>
              <Col sm={1} className="edit-folder">
                {(folder.folderDelete || folder.folderUpdate) && (
                  <>
                  <a
                    onClick={() => {
                      setShowModalFolEdit(true);
                      setFolder(folder);
                    }}
                  >
                    <BsPencilFill />
                  </a>
                  <a
                    onClick={() => {
                      setShowModalFolRights(true);
                      setFolder(folder);
                    }}
                  >
                    <BsLockFill />
                  </a>
                  </>
                )}
              </Col>
            </Accordion.Header>
            <Accordion.Body>
              <DocumentsList maj={majFromModal} folder={folder} folderid={folder.id} />
            </Accordion.Body>
          </Accordion.Item>
        );
      });
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <>
      {showModalFolAdd === true && (
        <FolderAdd show={handleModalFolAdd} maj={majFromModal} />
      )}
      <Col sm={9} className="list_documents">
        {showModalFolEdit === true && (
          <FolderEdit
            show={handleModalFolEdit}
            data={folder}
            maj={majFromModal}
          />
        )}
        {showModalFolRights === true && (
          <FolderRights
            show={handleModalFolRights}
            id={folder.id}
            maj={majFromModal}
          />
        )}
        {showModalDocAdd === true && (
          <DocumentAdd
            show={handleModalDocAdd}
            data={folder}
            maj={majFromModal}
          />
        )}
        <Accordion flush>{folders && listFolders()}</Accordion>
      </Col>
      <Col sm={3} className="right_menu">
        {user.userRights && user.userRights.documentfolder.create && (
          <>
            <Button
              className="right_button"
              onClick={() => setShowModalFolAdd(true)}
            >
              Créer un dossier
            </Button>
          </>
        )}
      </Col>
    </>
  );
};

export default DocumentsFolders;
