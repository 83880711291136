import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Image, Row } from "react-bootstrap";
import { userContext } from "../../Stores/Store";
import "./cis.scss";
import Loading from "../../Helpers/Loading";
import * as _api from "../../Apis/Criteres_apis";
import { useKeycloak } from "@react-keycloak/web";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { set } from "draft-js/lib/DefaultDraftBlockRenderMap";
import { BsArrowLeft } from "react-icons/bs";

const Subcriterias = () => {
	const user = useContext(userContext);
	const navigate = useNavigate();
	const { username } = useParams();
	const { keycloak, initialized } = useKeycloak();
	const [baremeId, setBaremeId] = useState();
	const [baremes, setBaremes] = useState();
	const [showModalCreateBareme, setShowModalCreateBareme] = useState();
	const [showCriteresBoard, setShowCriteresBoard] = useState(true);
	const [showModalEditBareme, setShowModalEditBareme] = useState(false);
	const [loading, setLoading] = useState(false);
	const [results, setResults] = useState();
	const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
	const handleModalCreateBareme = (status) => setShowModalCreateBareme(status); //fonction à passer au child pour MAJ status modal
	const handleModalEditBareme = (status) => setShowModalEditBareme(status); //fonction à passer au child pour MAJ status modal

	//fonction à passer au child pour MAJ du State
	const majFromModal = (data, action) => {
		let arrayTemp = [{}];
		switch (action) {
			case "addBareme":
				arrayTemp = baremes;
				arrayTemp.push(data);
				setBaremes(arrayTemp);
				console.log(baremes);
				setBaremeId(data.id);
				setShowCriteresBoard(true);
				break;
			case "updateBareme":
				let eventIndex = baremes.findIndex((obj) => obj.id == data.id);
				arrayTemp = baremes;
				arrayTemp[eventIndex] = data;
				setBaremes(arrayTemp);
				setBaremeId(data.id);
				setShowCriteresBoard(true);
				break;
			default:
				break;
		}
	};

	// On récupère les baremes
	useEffect(() => {
		if (user.competitionid !== 0 && user.skillid !== 0) {
			setLoading(true);
			_api
				.getResults(keycloak.token, username, user.competitionid, user.skillid)
				.then((res) => {
					setResults(res);
				})
				.then(() => setLoading(false));
		}
	}, [user.skillid, user.competitionid, username]);

	const deleteResult = (uuid) => {
		_api.deleteResult(keycloak.token, uuid).then((res) => {
			if (res) {
				setResults(results.filter((item) => item.uuid != uuid));
			}
		});
	};

	const listResults = () => {
		if (results) {
			return results
				.sort((a, b) => (a.date > b.date ? 1 : -1))
				.map((result) => {
					return (
						<tr>
							<td>{moment(result.date).format("DD/MM/YYYY")}</td>
							<td>{result.criteria}</td>
							<td>{result.subcriteria}</td>
							<td>
							{result.value == 0 && <span className="round_toeval"></span>}
							{result.value == 33 && <span className="round_preparing"></span>}
							{result.value == 66 && <span className="round_near"></span>}
							{result.value == 100 && <span className="round_ready"></span>}
							</td>
							<td>
								<button onClick={() => deleteResult(result.uuid)}>
									Supprimer
								</button>
							</td>
						</tr>
					);
				});
		}
	};

	//Contrôle du chargement des données
	if (loading) return <Loading />;

	return (
		<>
			<Row className="header">
				<Col sm={4}>
					<Image
						className="img_title"
						src={
							window.location.origin +
							"/images/icons/bigmenu_criteres-evaluation.svg"
						}
					/>
					<span className="title">
						Résultats des sous-critères pour {username}
					</span>
				</Col>
				<Col sm={7}>
					<Button
						variant="primary"
						onClick={() => navigate(`/criteres`)}
					>
						<BsArrowLeft /> Retour aux critères
					</Button>
				</Col>
			</Row>
			<Row className="legende-resultats">
				<Col>
					<span className="round_toeval"></span>A évaluer
				</Col>
				<Col>
					<span className="round_preparing"></span>En cours de préparation
				</Col>
				<Col>
					<span className="round_near"></span>Encore quelques détails à
					peaufiner
				</Col>
				<Col>
					<span className="round_ready"></span>Prêt(e) pour la compétition
				</Col>
			</Row>
			<Row>
				<Col>
					<table className="table table-striped table-bordered table-hover">
						<thead>
							<th>Date</th>
							<th>Critère</th>
							<th>Sous-critère</th>
							<th>Résultat</th>
							<th></th>
						</thead>
						<tbody>{results && listResults()}</tbody>
					</table>
				</Col>
			</Row>
		</>
	);
};

export default Subcriterias;
