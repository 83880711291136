import { useKeycloak } from "@react-keycloak/web";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Card, Col, Form, Image, ListGroup, Row } from "react-bootstrap";
import {
  BsCalendar4Event,
  BsFileEarmark,
  BsListTask,
} from "react-icons/bs";
import { NavLink } from "react-router-dom";
import * as _api from "../../Apis/Comments_apis";
import { userContext } from "../../Stores/Store";
import PlanningView from "../Planning/PlanningView";
import TaskWidgetView from "./TaskWidgetView";

const CommentsWidget = () => {
  const [comments, setComments] = useState();
  const user = useContext(userContext);
  const { keycloak, initialized } = useKeycloak();
  const [showModalPlanning, setShowModalPlanning] = useState(false);
  const [showModalTask, setShowModalTask] = useState(false);
  const [elementId, setElementId] = useState();
  const handleModalPlanning = (status) => setShowModalPlanning(status); //fonction à passer au child pour MAJ status modal
  const handleModalTask = (status) => setShowModalTask(status); //fonction à passer au child pour MAJ status modal

  useEffect(() => {
    _api
      .getWidgetCommentsAsync(
        keycloak.token,
        user.competitionid,
        user.skillid,
        5
      )
      .then((res) => setComments(res));
  }, [user.skillid, user.competitionid]);

  const listComments = () =>
    comments.map((comment) => {
      return (
        <ListGroup.Item key={comment.id}>
          <span><small>{moment(comment.date).format("DD/MM/YYYY")}</small></span>{" "}
          <span>
            {comment.type == "document" && (
              <NavLink exact to={"/document/" + comment.element_id}>
                <BsFileEarmark /> {comment.name}
              </NavLink>
            )}
            {comment.type == "todo" && (
              <span style={{cursor : "pointer",color: "#0d6efd"}}
              onClick={() => {
                setShowModalTask(true);
                setElementId(comment.element_id);
              }}>
                <BsListTask />{" "}{comment.name}
              </span>
            )}
            {comment.type == "event" && (
              <span style={{cursor : "pointer",color: "#0d6efd"}}
              onClick={() => {
                setShowModalPlanning(true);
                setElementId(comment.element_id);
              }}>
                <BsCalendar4Event /> {comment.name}
              </span>
            )}
          </span>
        </ListGroup.Item>
      );
    });

  return (
    <>
    {showModalTask === true && (
      <TaskWidgetView show={handleModalTask} id={elementId} />
    )}
    {showModalPlanning === true && (
      <PlanningView show={handleModalPlanning} id={elementId}/>
    )}
    <div className="widget">
      <Card>
        <Card.Header className="widgetheader">
          <Row>
            <Col sm="2">
              <Image className="img_title" src="images/navboard/forum.svg" />
            </Col>
            <Col>Derniers commentaires</Col>
          </Row>
        </Card.Header>
        <ListGroup variant="flush">{comments && listComments()}</ListGroup>
      </Card>
    </div>
    </>
  );
};

export default CommentsWidget;
