import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { userContext } from "../../Stores/Store";
import * as _api from "../../Apis/Documents_apis";
import * as contacts_api from "../../Apis/Contacts_apis";
import { useKeycloak } from "@react-keycloak/web";
import * as datas from "../../Helpers/Data";
import Loading from "../../Helpers/Loading";

const DocumentsFolderRights = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const handleClose = () => props.show(false);
  const user = useContext(userContext);
  const [folder, setFolder] = useState();
  const [rolesRights, setRolesRights] = useState();
  const [usersRights, setUsersRights] = useState();
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState();
  const [errorForm, setErrorForm] = useState();

  useEffect(() => {
    if (props.id) {
      setLoading(true);
      _api
        .getDocumentFolder(keycloak.token, props.id)
        .then((res) => {
          setFolder(res);
          setRolesRights(res.rights.roles);
          setUsersRights(res.rights.users);
        })
        .then(() => setLoading(false));
    }
  }, [props.id]);

  useEffect(() => {
    if (props.competitionid && props.skillid) {
      setLoading(true);
      contacts_api
        .getContactsAsync(keycloak.token, props.competitionid, props.skillid)
        .then((res) => setContacts(res))
        .then(() => setLoading(false));
    } else {
      setContacts(user.contactsSkill);
    }
  }, [props.competitionid, props.skillid])

  const handleChange = (e, rights, name, action) => {
    if (rights === "usersRights") {
      if (e.target.checked) {
        setUsersRights({
          ...usersRights,
          [name]: { ...usersRights[name], [action]: true },
        });
      } else {
        setUsersRights({
          ...usersRights,
          [name]: { ...usersRights[name], [action]: false },
        });
      }
    }
    if (rights === "rolesRights") {
      if (e.target.checked) {
        setRolesRights({
          ...rolesRights,
          [name]: { ...rolesRights[name], [action]: true },
        });
      } else {
        setRolesRights({
          ...rolesRights,
          [name]: { ...rolesRights[name], [action]: false },
        });
      }
    }
  };

  const updateRightsFolder = () => {
    let rights = {};
    rights = { roles: { ...rolesRights }, users: { ...usersRights } };
    _api.postFolderRights(keycloak.token, folder.id, rights).then((results) => {
      if (results) {
        if(props.maj) props.maj(folder, "updateFolder");
        props.show(false);
      } else {
        setErrorForm("Une erreur technique s'est produite");
      }
    });
  };

  const listRoles = () =>
    datas.roles.map((role) => {
      return (
        <tr>
          <td>{role.name}</td>
          <td>
            <Form.Check
              inline
              name={`roles.${role.name_db}.read`}
              type="checkbox"
              value={`roles.${role.name_db}.read`}
              id={`inline-radio-${role.name_db}-read`}
              onChange={(e) =>
                handleChange(e, "rolesRights", role.name_db, "read")
              }
              checked={
                rolesRights[role.name_db] && rolesRights[role.name_db].read
              }
            />
          </td>
          <td>
            <Form.Check
              inline
              name={`roles.${role.name_db}.create`}
              type="checkbox"
              value={`roles.${role.name_db}.create`}
              id={`inline-radio-${role.name_db}-create`}
              onChange={(e) =>
                handleChange(e, "rolesRights", role.name_db, "create")
              }
              checked={
                rolesRights[role.name_db] && rolesRights[role.name_db].create
              }
            />
          </td>
          <td>
            <Form.Check
              inline
              name={`roles.${role.name_db}.update`}
              type="checkbox"
              value={`roles.${role.name_db}.update`}
              id={`inline-radio-${role.name_db}-update`}
              onChange={(e) =>
                handleChange(e, "rolesRights", role.name_db, "update")
              }
              checked={
                rolesRights[role.name_db] && rolesRights[role.name_db].update
              }
            />
          </td>
          <td>
            <Form.Check
              inline
              name={`roles.${role.name_db}.delete`}
              type="checkbox"
              value={`roles.${role.name_db}.delete`}
              id={`inline-radio-${role.name_db}-delete`}
              onChange={(e) =>
                handleChange(e, "rolesRights", role.name_db, "delete")
              }
              checked={
                rolesRights[role.name_db] && rolesRights[role.name_db].delete
              }
            />
          </td>
        </tr>
      );
    });

  const listContacts = () =>
    contacts.map((contact) => {
      return (
        <tr>
          <td>
            {contact.first_name} {contact.last_name}
          </td>
          <td>
            <Form.Check
              inline
              name={`users.${contact.username}.read`}
              type="checkbox"
              value={`users.${contact.username}.read`}
              id={`inline-radio-${contact.username}-read`}
              onChange={(e) =>
                handleChange(e, "usersRights", contact.username, "read")
              }
              checked={
                usersRights[contact.username] &&
                usersRights[contact.username].read
              }
            />
          </td>
          <td>
            <Form.Check
              inline
              name={`users.${contact.username}.create`}
              type="checkbox"
              value={`users.${contact.username}.create`}
              id={`inline-radio-${contact.username}-create`}
              onChange={(e) =>
                handleChange(e, "usersRights", contact.username, "create")
              }
              checked={
                usersRights[contact.username] &&
                usersRights[contact.username].create
              }
            />
          </td>
          <td>
            <Form.Check
              inline
              name={`users.${contact.username}.update`}
              type="checkbox"
              value={`users.${contact.username}.update`}
              id={`inline-radio-${contact.username}-update`}
              onChange={(e) =>
                handleChange(e, "usersRights", contact.username, "update")
              }
              checked={
                usersRights[contact.username] &&
                usersRights[contact.username].update
              }
            />
          </td>
          <td>
            <Form.Check
              inline
              name={`users.${contact.username}.delete`}
              type="checkbox"
              value={`users.${contact.username}.delete`}
              id={`inline-radio-${contact.username}-delete`}
              onChange={(e) =>
                handleChange(e, "usersRights", contact.username, "delete")
              }
              checked={
                usersRights[contact.username] &&
                usersRights[contact.username].delete
              }
            />
          </td>
        </tr>
      );
    });

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <Modal
      className="modal_app"
      show={props.show}
      onHide={handleClose}
      backdrop="static"
      centered="true"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Permissions du dossier</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Table hover responsive className="tab_droits">
              <thead>
                <tr className="tab_droits_header">
                  <th></th>
                  <th>Voir</th>
                  <th>Ajouter</th>
                  <th>Modifier</th>
                  <th>Supprimer</th>
                </tr>
              </thead>
              <tbody>{rolesRights && listRoles()}</tbody>
            </Table>
          </Row>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Droits par utilisateurs</Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Table hover responsive className="tab_droits">
                    <thead>
                      <tr className="tab_droits_header">
                        <th></th>
                        <th>Voir</th>
                        <th>Ajouter</th>
                        <th>Modifier</th>
                        <th>Supprimer</th>
                      </tr>
                    </thead>
                    <tbody>{contacts && listContacts()}</tbody>
                  </Table>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => updateRightsFolder()}>
          Mettre à jour les droits
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DocumentsFolderRights;
