/**
 * GET Last Documents Async
 * @returns promise Document
 */
export const getLastDocumentsAsync = async (token, limit, competitionid, skillid) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/ged/documents/last?limit=${limit}&competition=${competitionid}&skill=${skillid}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
* GET Document Async
* @returns promise Document
*/
export const getDocumentAsync = async (token, id) => {
 const requestOptions = {
   method: "GET",
   headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
 };
 try {
   let promise = await fetch(
     `${process.env.REACT_APP_API_URL}/ged/documents/${id}`,
     requestOptions
   );
   if (promise.status === 200) {
     let results = await promise.json();
     return results;
   }
 } catch (error) {
   console.log(error);
 }
};

/**
 * GET Documents Download Async
 * @returns promise Contact
 */
export const getDocumentDownload = async (token, tokendoc, inline=false) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/ged/documents/download?inline=${inline}&token=${tokendoc}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.text();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * GET Document Folder Async
 * @returns promise Contact
 */
export const getDocumentFolder = async (token, id) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/ged/folders/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * GET Documents Folders Async
 * @returns promise Contact
 */
export const getDocumentsFolders = async (token, competitionid, skillid) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/ged/folders/?competition=${competitionid}&skill=${skillid}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * PUT Document Async
 * @returns HTTP Code
 */
export const putDocument = async (token, folderid, type, formData) => {
  const requestOptions = {
    method: "PUT",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
    body: JSON.stringify(formData),
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/ged/documents/?folder=${folderid}&type=${type}`,
      requestOptions
    );
    if(promise.status === 201){
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * PUT Document Folder Async
 * @returns HTTP Code
 */
export const putDocumentFolder = async (token, competitionid, skillid, formData) => {
  const requestOptions = {
    method: "PUT",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
    body: JSON.stringify(formData),
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/ged/folders/?competition=${competitionid}&skill=${skillid}`,
      requestOptions
    );
    if(promise.status === 201){
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * GET Documents Async
 * @returns promise Contact
 */
export const getDocuments = async (token, folderid) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/ged/documents/?folder=${folderid}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * POST Documents Download Async
 * @returns promise Contact
 */
export const postDocumentDownload = async (token) => {
  const requestOptions = {
    method: "POST",
    headers: { Accept: "application/json" },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/ged/documents/download?token=${token}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise;
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * DELETE Document Folder Async
 * @returns HTTP Code
 */
 export const deleteFolder = async (token, id) => {
  const requestOptions = {
    method: "DELETE",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/ged/folders/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * POST Document Rights Folder Async
 * @returns HTTP Code
 */
export const postFolderRights = async (token, id, formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/ged/folders/${id}/rights`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * POST Document Folder Async
 * @returns HTTP Code
 */
export const postFolder = async (token, id, formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/ged/folders/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * POST Document Async
 * @returns HTTP Code
 */
export const postDocument = async (token, id, formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/ged/documents/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * POST MOVE Document Async
 * @returns HTTP Code
 */
export const moveDocument = async (token, id, new_folder) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/ged/documents/move/${id}?new_folder=${new_folder}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * POST Document Upload Async
 * @returns HTTP Code
 */
export const postDocumentUpload = async (token, id, formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/ged/documents/upload/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * DELETE Document Async
 * @returns HTTP Code
 */
 export const deleteDocument = async (token, id) => {
  const requestOptions = {
    method: "DELETE",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/ged/documents/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};