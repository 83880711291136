import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import Loading from "../../Helpers/Loading";
import { userContext } from "../../Stores/Store";
import * as _api from "../../Apis/Configuration_apis";
import * as me_api from "../../Apis/Me_apis";
import { useKeycloak } from "@react-keycloak/web";
import FolderRights from "../Documents/FolderRights";
import AlertMessage from "../../Helpers/AlertMessage";

const Folders = () => {
  const user = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const [folders, setFolders] = useState([]);
  const [folder, setFolder] = useState("");
  const [competitions, setCompetitions] = useState();
  const [competitionId, setCompetitionId] = useState(null);
  const [skills, setSkills] = useState();
  const [skillId, setSkillId] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const { keycloak, initialized } = useKeycloak();
  const [showModalFolRights, setShowModalFolRights] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState();
  const handleModalFolRights = (status) => setShowModalFolRights(status); //fonction à passer au child pour MAJ status modal
  const [competitionSearch, setCompetitionSearch] = useState();
  const [skillSearch, setSkillSearch] = useState();
  const [errorForm, setErrorForm] = useState();

  // On réinitialise les erreurs quand on ferme l'alerte
  const initError = () => {
    setErrorForm();
  };

  useEffect(() => {
    if (competitionSearch != null && !skillSearch != null) {
      setLoading(true);
      _api
        .getDocumentsFolders(keycloak.token)
        .then((res) => setFolders(res))
        .then(() => setLoading(false));
    } else {
      setLoading(true);
      _api
        .getDocumentsFolders(keycloak.token, user.competitionid, user.skillid)
        .then((res) => setFolders(res))
        .then(() => setLoading(false));
    }
  }, [user.skillid, user.competitionid]);

  useEffect(() => {
    if (competitionId) {
      setLoading(true);
      me_api
        .getSkillsAsync(keycloak.token, competitionId)
        .then((res) => setSkills(res))
        .then(() => setLoading(false));
    } else {
      setSkills();
      setSkillId(null);
    }
  }, [competitionId]);

  useEffect(() => {
    setLoading(true);
    if(!competitionSearch) setCompetitionSearch(user.competitionid);
    if(!skillSearch) setSkillSearch(user.skillid);
    me_api
      .getCompetitionsAsync(keycloak.token)
      .then((res) => {
        setCompetitions(res);
      })
      .then(() => setLoading(false));
  }, []);

  const addFolder = () => {
    if (folder.name) {
      _api
        .putFolder(keycloak.token, competitionId, skillId, folder)
        .then((res) => {
          if (res.status) {
            setErrorForm("Code Erreur " + res.status + ": " + res.msg);
          } else {
            setFolders([res, ...folders]);
            eraseForm();
          }
        });
    }
  };

  const updateFolder = () => {
    if (folder.name) {
      _api
        .postFolder(keycloak.token, competitionId, skillId, folder.id, folder)
        .then((res) => {
          if (res.status) {
            setErrorForm("Code Erreur " + res.status + ": " + res.msg);
          } else {
            let arrayTemp = [];
            let folderIndex = folders.findIndex((obj) => obj.id == res.id);
            arrayTemp = [...folders];
            arrayTemp[folderIndex] = res;
            setFolders(arrayTemp);
            eraseForm();
          }
        });
    }
  };

  const deleteFolder = () => {
    if (folder.id) {
      if (confirmDelete == folder.name) {
        _api
          .deleteFolder(keycloak.token, folder.id)
          .then((res) => {
            setFolders(folders.filter((item) => item.id !== folder.id));
          })
          .then(() => setFolder(""))
          .then(() => setShowDelete(false));
      }
    }
  };

  const eraseForm = () => {
    setFolder("");
    setCompetitionId("");
    setSkillId(null);
  };

  const search = () => {
    _api
      .getDocumentsFolders(keycloak.token, competitionSearch, skillSearch)
      .then((res) => setFolders(res));
  };

  const listFolders = () => {
    return folders.map((folder) => {
      return (
        <>
          <tr>
            <td>{folder.id}</td>
            <td>{folder.name}</td>
            <td>{folder.competition}</td>
            <td>{folder.skill}</td>
            <td>{folder.type}</td>
            <td>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setFolder(folder);
                  setCompetitionId(folder.competition);
                  setSkillId(folder.skill);
                  setShowDelete(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-pencil"
                  viewBox="0 0 16 16"
                  color="blue"
                >
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>
              </span>
              &nbsp;
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setFolder(folder);
                  setShowModalFolRights(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-lock"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
                </svg>
              </span>
              &nbsp;
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setFolder(folder);
                  setShowDelete(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-trash"
                  viewBox="0 0 16 16"
                  color="red"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fill-rule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </span>
            </td>
          </tr>
        </>
      );
    });
  };

  const listCompetitions = () => {
    return competitions
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((competition) => {
        return (
          <option
            selected={competition.id == competitionId}
            value={competition.id}
          >
            {competition.id} - {competition.name}
          </option>
        );
      });
  };

  const listSkills = () => {
    if (skills) {
      return skills
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((skill) => {
          return (
            <option value={skill.number}>
              {skill.number + " - " + skill.name}
            </option>
          );
        });
    }
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;
  return (
    <>
      {showModalFolRights == true && (
        <FolderRights
          show={handleModalFolRights}
          id={folder.id}
          competitionid={folder.competition}
          skillid={folder.skill}
        />
      )}
      <Container fluid>
        {/* Affichage des erreurs */}
        {errorForm && <AlertMessage message={errorForm} errorMaj={initError} />}
        <Row>
          <Col sm={7}>
            <Row>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="ID Compétition"
                        defaultValue={user.competitionid}
                        value={competitionSearch}
                        onChange={(e) => setCompetitionSearch(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="ID Métier"
                        defaultValue={user.skillid}
                        value={skillSearch}
                        onChange={(e) => setSkillSearch(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Button variant="outline-success" onClick={() => search()}>
                      Chercher
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Row>
            <Row>
              <Table hover responsive striped>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nom</th>
                    <th>Compétition</th>
                    <th>Métier</th>
                    <th>Type</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{folders && listFolders()}</tbody>
              </Table>
            </Row>
          </Col>
          {!showDelete && (
            <Col>
              <h3>{!folder.id ? "Ajouter un " : "Modifier le"} dossier</h3>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="Nom du dossier"
                        required
                        value={folder && folder.name}
                        onChange={(e) =>
                          setFolder({ ...folder, name: e.target.value })
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Select
                      name="competition_select"
                      onChange={(e) => setCompetitionId(e.target.value)}
                      size="sm"
                      value={competitionId}
                    >
                      <option value="">0 - Toutes les compétitions</option>
                      {competitions && listCompetitions()}
                    </Form.Select>
                  </Col>
                  <Col>
                    <Form.Select
                      size="sm"
                      name="skill_select"
                      onChange={(e) => setSkillId(e.target.value)}
                      value={skillId ? skillId : ""}
                    >
                      <option value="">0 - Tous les métiers</option>
                      {skills && listSkills()}
                    </Form.Select>
                  </Col>
                  <Col>
                    <Form.Select
                      name="type_select"
                      onChange={(e) =>
                        setFolder({ ...folder, type: e.target.value })
                      }
                      size="sm"
                      value={folder.type}
                    >
                      <option value="s">s - Skill</option>
                      <option value="g" defaultChecked>
                        g - Global
                      </option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {!folder.id && (
                      <Button
                        onClick={() => addFolder()}
                        style={{ marginTop: "10px" }}
                      >
                        Ajouter
                      </Button>
                    )}
                    {folder.id && (
                      <>
                        <Button
                          onClick={() => updateFolder()}
                          style={{ marginTop: "10px" }}
                        >
                          Modifier
                        </Button>
                        &nbsp;
                        <Button
                          variant="outline-secondary"
                          onClick={() => eraseForm()}
                          style={{ marginTop: "10px" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-x-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                          </svg>
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </Form>
            </Col>
          )}
          {showDelete && (
            <Col>
              <h3>Supprimer {folder.name}</h3>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Alert variant="warning">
                    Cette action sera irrévocable. Elle entrainera la
                    suppression des fichiers de ce dossier.
                    <br />
                    Pour confirmer la suppression veuillez saisir le nom du
                    dossier complet et cliquer sur "Supprimer"
                  </Alert>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="Nom du dossier à supprimer"
                        required
                        value={confirmDelete}
                        onChange={(e) => setConfirmDelete(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Button
                      variant="outline-danger"
                      onClick={() => deleteFolder()}
                    >
                      Supprimer
                    </Button>
                    &nbsp;
                    <Button
                      variant="outline-secondary"
                      onClick={() => {
                        setFolder("");
                        setShowDelete(false);
                      }}
                    >
                      Annuler
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Folders;
