/**
 * GET Search Users Async
 * @returns promise Search Users
 */
export const getSearchUsersAsync = async (token, searchUser) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/users/search?search=${searchUser}`,
      requestOptions
    );
    if(promise.status === 200){
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};