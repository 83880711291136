import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Image, Row } from "react-bootstrap";
import { userContext } from "../../Stores/Store";
import ModalCreateBareme from "./ModalCreateBareme";
import "./cis.scss";
import CriteresBoard from "./CriteresBoard";
import Loading from "../../Helpers/Loading";
import * as _api from "../../Apis/Criteres_apis";
import { useKeycloak } from "@react-keycloak/web";
import ModalEditBareme from "./ModalEditBareme";
import Previsions from "./Previsions";
import {
	BsPencilFill,
	BsPlusLg,
	BsBarChartFill,
	BsListCheck,
} from "react-icons/bs";
import Progressions from "./Progressions";
import moment from "moment";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import { useNavigate, useParams } from "react-router-dom";

const Cis = () => {
	const user = useContext(userContext);
	const navigate = useNavigate();
	const { keycloak, initialized } = useKeycloak();
	const [baremeId, setBaremeId] = useState();
	const username = useParams();
	const [competitorUsername, setCompetitorUsername] = useState();
	const [baremes, setBaremes] = useState();
	const [showModalCreateBareme, setShowModalCreateBareme] = useState();
	const [showCriteresBoard, setShowCriteresBoard] = useState(true);
	const [showModalEditBareme, setShowModalEditBareme] = useState(false);
	const [showPrevisions, setShowPrevisions] = useState(false);
	const [loading, setLoading] = useState(false);
	const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
	const handleModalCreateBareme = (status) => setShowModalCreateBareme(status); //fonction à passer au child pour MAJ status modal
	const handleModalEditBareme = (status) => setShowModalEditBareme(status); //fonction à passer au child pour MAJ status modal

	//fonction à passer au child pour MAJ du State
	const majFromModal = (data, action) => {
		let arrayTemp = [{}];
		switch (action) {
			case "addBareme":
				arrayTemp = baremes;
				arrayTemp.push(data);
				setBaremes(arrayTemp);
				console.log(baremes);
				setBaremeId(data.id);
				setShowCriteresBoard(true);
				break;
			case "updateBareme":
				let eventIndex = baremes.findIndex((obj) => obj.id == data.id);
				arrayTemp = baremes;
				arrayTemp[eventIndex] = data;
				setBaremes(arrayTemp);
				setBaremeId(data.id);
				setShowCriteresBoard(true);
				break;
			default:
				break;
		}
	};

	const handleChange = (event) => {
		setBaremeId(event.target.value);
	};

	// On récupère les baremes
	useEffect(() => {
		if (user.competitionid !== 0 && user.skillid !== 0) {
			setLoading(true);
			_api
				.getBaremesAsync(keycloak.token, user.competitionid, user.skillid)
				.then((res) => {
					console.log(res);
					if (res != "") {
						setBaremes(res);
						if (
							res.find((bareme) => bareme.competition_id == user.competitionid)
						)
							setBaremeId(
								res.find(
									(bareme) => bareme.competition_id == user.competitionid,
								).id,
							);
						else {
							setBaremeId(res.length > 0 ? res[res.length - 1].id : null);
						}
					} else {
						setBaremes([{}]);
						setBaremeId();
					}
				})
				.then(() => setLoading(false));
		}
	}, [user.skillid, user.competitionid]);

	useEffect(() => {
		setCompetitorUsername("");
		setShowCriteresBoard(true);
		setShowPrevisions(false);
	}, [user.skillid, user.competitionid]);

	useEffect(() => {
		const competitors = user.contactsSkill.filter(
			(item) => item.role == "competitor",
		);
		if (competitors.length === 1) {
			setCompetitorUsername(competitors[0].username);
		} else {
			competitors.map((competitor) => {
				if (competitor.username == keycloak.tokenParsed.preferred_username) {
					setCompetitorUsername(competitor.username);
				}
			});
		}
	}, [user.contactsSkill]);

	const listCompetitors = () => {
		if (
			user.contactsSkill.filter((item) => item.role == "competitor").length > 1
		) {
			return user.contactsSkill
				.filter((item) => item.role == "competitor")
				.map((competitor) => {
					if (user.userRoles == "competitor") {
						if (
							String(
								competitor.first_name + " " + competitor.last_name,
							).toUpperCase() == String(keycloak.tokenParsed.name).toUpperCase()
						)
							return (
								<>
									<option selected value={competitor.username}>
										{competitor.first_name + " " + competitor.last_name}
									</option>
								</>
							);
					} else {
						return (
							<option value={competitor.username}>
								{competitor.first_name + " " + competitor.last_name}
							</option>
						);
					}
				});
		} else if (
			user.contactsSkill.filter((item) => item.role == "competitor").length == 1
		) {
			return (
				<>
					<option
						selected
						value={
							user.contactsSkill.filter((item) => item.role == "competitor")[0]
								.username
						}
					>
						{user.contactsSkill.filter((item) => item.role == "competitor")[0]
							.first_name +
							" " +
							user.contactsSkill.filter((item) => item.role == "competitor")[0]
								.last_name}
					</option>
				</>
			);
		}
	};

	const listBaremes = () => {
		if (baremes) {
			return baremes
				.sort((a, b) => (a.name > b.name ? 1 : -1))
				.map((bareme) => {
					if (bareme.id)
						return (
							<option value={bareme.id}>
								{bareme.name}
								{bareme.competition_id.findIndex(
									(item) => item == user.competitionid,
								) != -1
									? ""
									: " 🔒"}
							</option>
						);
				});
		}
	};

	//Contrôle du chargement des données
	if (loading) return <Loading />;

	return (
		<>
			{showModalEditBareme == true && (
				<ModalEditBareme
					show={handleModalEditBareme}
					id={baremeId}
					maj={majFromModal}
				/>
			)}
			{showModalCreateBareme == true && (
				<ModalCreateBareme show={handleModalCreateBareme} maj={majFromModal} />
			)}
			<Row className="header">
				<Col sm={4}>
					<Image
						className="img_title"
						src={
							window.location.origin +
							"/images/icons/bigmenu_criteres-evaluation.svg"
						}
					/>
					<span className="title">
						{showCriteresBoard ? "Progression" : "Définir les objectifs"}
					</span>
				</Col>
				{showPrevisions && (
					<Col sm={2}>
						<Button
							size="sm"
							variant="outline-secondary"
							onClick={(e) => {
								setShowPrevisions(false);
								setShowCriteresBoard(true);
							}}
						>
							Terminer la saisie
						</Button>
					</Col>
				)}
				{showCriteresBoard == true && (
					<>
						<Col sm={2}>
							<Form.Select
								name="bareme"
								size="sm"
								value={baremeId && baremeId}
								onChange={handleChange}
							>
								{baremes ? (
									listBaremes()
								) : (
									<option>Créer ou Choisir un barème</option>
								)}
							</Form.Select>
						</Col>
						{/* {baremeId && (
              <Col sm={1}>
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={(e) => {
                    setShowModalEditBareme(true);
                  }}
                >
                  <BsPencilFill />
                </Button>
              </Col>
            )} */}
						<Col sm={4}>
							{/* {user.userRights && user.userRights.cis.create && (
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={() => {
                    setShowModalCreateBareme(true);
                  }}
                >
                  <BsPlusLg /> Créer un nouveau barème
                </Button>
              )} */}

							{competitorUsername &&
								user.userRights &&
								user.userRights.cis.prevision && (
									<>
										<Button
											style={{ marginLeft: "10px" }}
											size="sm"
											variant="outline-success"
											onClick={(e) => {
												setShowPrevisions(true);
												setShowCriteresBoard(false);
											}}
										>
											<BsBarChartFill /> Objectifs
										</Button>
										<Button
											style={{ marginLeft: "10px" }}
											size="sm"
											variant="outline-success"
											onClick={() =>
												navigate("/souscriteres/" + competitorUsername)
											}
										>
											<BsListCheck /> Résultats
										</Button>
									</>
								)}
						</Col>
					</>
				)}
			</Row>
			{showCriteresBoard && (
				<>
					<Row className="legende-resultats">
						<Col>
							<span className="round_toeval"></span>A évaluer
						</Col>
						<Col>
							<span className="round_preparing"></span>En cours de préparation
						</Col>
						<Col>
							<span className="round_near"></span>Encore quelques détails à
							peaufiner
						</Col>
						<Col>
							<span className="round_ready"></span>Prêt(e) pour la compétition
						</Col>
					</Row>
					<Row className="date-resultats">
						<Form.Group className="mb-3">
							<Form.Label>
								Sélectionnez la date de prévisualisation des résultats &nbsp;
							</Form.Label>
							<DateTimePicker
								locale="fr-FR"
								name="date"
								value={typeof date === "string" ? new Date(date) : date}
								onChange={(value) =>
									setDate(moment(value).format("YYYY-MM-DD"))
								}
								required="true"
								format="d/MM/yyyy"
								clearIcon=""
							/>
						</Form.Group>
					</Row>
				</>
			)}
			<Row>
				<Col sm={9}>
					{showCriteresBoard && baremeId ? (
						<CriteresBoard
							username={competitorUsername}
							baremeId={baremeId}
							date={date}
						/>
					) : (
						""
					)}
					{!competitorUsername && (
						<Alert variant="secondary" style={{ marginTop: "10px" }}>
							Pour afficher la progression, veuillez sélectionner un compétiteur
							➡️
						</Alert>
					)}
					{showPrevisions && competitorUsername ? (
						<Previsions username={competitorUsername} />
					) : (
						""
					)}
				</Col>
				<Col sm={3} className="right_menu">
					<Card>
						<Card.Header style={{ backgroundColor: "#FFF" }}>
							<Form.Select
								style={{ border: "0px", color: "#1E4EE2" }}
								name="bareme"
								size="sm"
								onChange={(e) => setCompetitorUsername(e.target.value)}
							>
								{" "}
								{!competitorUsername && <option>Choisir un competiteur</option>}
								{user.contactsSkill && listCompetitors()}
							</Form.Select>
						</Card.Header>
						<Card.Body>
							<Progressions username={competitorUsername} date={date} />
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default Cis;
