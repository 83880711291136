import { useKeycloak } from "@react-keycloak/web";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import Loading from "../../Helpers/Loading";
import * as _api from "../../Apis/Documents_apis";
import Commentaires from "../Commentaires/Commentaires";
import { BsCloudDownload, BsFillFolderFill } from "react-icons/bs";

const DocumentView = () => {
	const navigate = useNavigate();
  const { keycloak, initialized } = useKeycloak();
  const { documentId } = useParams();
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState();
  const [urlInline, setUrlInline] = useState();
  const [urlDownload, setUrlDownload] = useState();

  useEffect(() => {
    if (documentId) {
      setLoading(true);
      _api
        .getDocumentAsync(keycloak.token, documentId)
        .then((res) => setDocument(res))
        .then(() => setLoading(false));
    }
  }, [documentId]);

  useEffect(() => {
    if (document) {
      setUrlInline(
        process.env.REACT_APP_API_URL +
          "/ged/documents/download?inline=true&token=" +
          document.token
      );
      setUrlDownload(
        process.env.REACT_APP_API_URL +
          "/ged/documents/download?inline=false&token=" +
          document.token
      );
    }
  }, [document]);

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <>
      <Row className="header">
        <Col sm={5}>
          <Image
            className="img_title"            
            src={window.location.origin+"/images/icons/bigmenu_documents-formation.svg"}
          />
          <span className="title">{document && document.name}</span>
        </Col>
        <Col sm={7}>
        <Button variant="primary" onClick={() => navigate("/documents")}><BsFillFolderFill /> Retour aux dossiers</Button>
          <a href={urlDownload}>[<BsCloudDownload /> Télécharger le fichier]</a>
        </Col>
      </Row>
      <Row>
        <embed
          src={urlInline}
          style={{height:"75vh"}}
          pluginspage="http://www.adobe.com/products/acrobat/readstep2.html"
        />
      </Row>
      <Row>
        <Commentaires module="ged/documents" id={documentId} />
      </Row>
    </>
  );
};

export default DocumentView;
