import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useEffect, useState } from "react";
import { Alert, Col, Form, Image, Row } from "react-bootstrap";
import { userContext } from "../../Stores/Store";
import * as _api from "../../Apis/Me_apis";
import Loading from "../../Helpers/Loading";

const Cgu = () => {
  const [cguOk, setCguOk] = useState(false);
  const { keycloak, initialized } = useKeycloak();
  const user = useContext(userContext);
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    _api.getProfile(keycloak.token).then((res) => setCguOk(res.cgu_accepted))
    .then(() => setLoading(false));
  }, []);

  const handleChange = () => {
    if(!cguOk) _api.postAcceptCgu(keycloak.token).then(() => setCguOk(true));
  };
  

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <>
      <Row className="header">
        <Col sm={12}>
          <Image
            className="img_title"
            src={window.location.origin+"/images/icons/bigmenu_documents-formation.svg"}
          />
          <span className="title">CGU</span>
        </Col>
      </Row>
      <Row>
        <h2>1. Objet de la politique de protection des données</h2>

        <p>
          Cette politique vise à satisfaire les obligations d’information de
          WORLDSKILLS FRANCE en matière de protection des données traitées sur
          la plateforme « VICTOIRE » qui relèvent de sa responsabilité. Ces
          obligations d’information sont notamment définies aux article 5, 13 et
          14 du Règlement (UE) 2016/679 du Parlement européen et du Conseil du
          27 avril 2016 : Règlement relatif à la protection des personnes
          physiques à l’égard du traitement des données à caractère personnel et
          à la libre circulation de ces données, et abrogeant la directive
          95/46/CE (règlement général sur la protection des données – ci-après «
          RGPD »).
        </p>

        <h2>2. Identification de l’association et responsable du traitement</h2>

        <h3>2.1. Identification de l’association</h3>

        <p>
          WORLDSKILLS FRANCE (anciennement appelée COFOM : Comité Français des
          Olympiades des Métiers) est une association à but non lucratif (loi
          1901). WORLDSKILLS FRANCE est immatriculée sous le numéro 804 402 725
          et son siège social est situé au 33, rue de Naples 75008 Paris.
          WORLDSKILLS France coordonne un vaste réseau pour la promotion de
          l’apprentissage et l’alternance, la mobilité et la jeunesse des
          métiers.
        </p>

        <h3>2.2. Responsable du traitement des données</h3>

        <p>
          Toutes les demandes concernant les données collectées seront adressées
          à la Responsable des systèmes d’information : Niassa CHERGUI à
          l’adresse électronique : rsi@cofom.org.
        </p>

        <h2>3. Respect de la vie privée et des données personnelles</h2>

        <p>
          Les informations recueillies sur cette plateforme sont enregistrées
          dans un fichier informatisé par WORLDSKILLS FRANCE pour le suivi de la
          performance des compétiteurs. La base légale du traitement est le
          consentement. Les données collectées seront communiquées aux seuls
          destinataires suivants :
          <ul>
            <li>
              Salariés du département PERFORMANCE (au sein de WORLDSKILLS
              FRANCE)
            </li>
            <li>
              Les équipes métiers : expert métier, Team Leader, MOS (bénévoles)
            </li>
            <li>
              La responsable des systèmes d’information (au sein de WORLDSKILLS
              FRANCE)
            </li>
          </ul>
        </p>

        <p>
          WORLDSKILLS FRANCE s’engage par conséquent à respecter la vie privée
          des personnes utilisatrices du service et à protéger les informations
          et données personnelles que toute personne pourrait lui communiquer.
          WORLDSKILLS FRANCE garantit la confidentialité des informations
          enregistrées sur la plateforme et certifie que les adresses et les
          données personnelles des personnes ne sont pas visibles en dehors de
          la plateforme.
        </p>

        <p>
          WORLDSKILLS France précise également que toutes les données
          personnelles demandées et recueillies sont obligatoires pour le suivi
          de la performance des compétiteurs dans le cadre de la préparation aux
          compétitions internationales.
        </p>

        <p>
          Les données sont conservées pendant 3 éditions de la compétition, soit
          6 ans.
        </p>

        <p>
          Vous pouvez accéder aux données vous concernant, les rectifier,
          demander leur effacement ou exercer votre droit à la limitation du
          traitement de vos données.
        </p>

        <p>
          Pour exercer ces droits ou pour toute question sur le traitement de
          vos données dans ce dispositif, vous pouvez contacter Niassa CHERGUI
          <ul>
            <li>Adresse postale : 33 rue de Naples, 75008</li>
            <li>Adresse électronique : rsi@cofom.org</li>
            <li>Portable : 06.32.61.00.78</li>
          </ul>
          Si vous estimez, après nous avoir contactés, que vos droits «
          Informatique et Libertés » ne sont pas respectés, vous pouvez adresser
          une réclamation à la CNIL. Par ailleurs, les utilisateurs de la
          plateforme s’engagent à s’abstenir s’agissant des données à caractère
          personnel auxquelles ils pourraient accéder, de toute collecte, de
          toute utilisation détournée et d’une manière générale de tout acte
          susceptible de porter atteinte à la vie privée, à la réputation des
          personnes, de l’association et de ses événements.
        </p>
        <h2>4. Utilisation de la plateforme</h2>

        <h3>4.1. Accès aux services</h3>

        <p>
          La plateforme offre à l’utilisateur un tableau de bord composé des
          fonctionnalités suivantes :
          <ul>
            <li>
              Critères d’évaluation : il s’agit d’un barème mis en place par
              l’expert permettant d’évaluer le niveau de préparation du
              compétiteur pour des échéances définies d’un commun accord.
            </li>
            <li>
              Sujets d’entraînements : cette partie regroupera toutes les
              ressources pédagogiques construites par l’équipe métier et
              destinées à la préparation du compétiteur (fonctionnalité en cours
              de développement).
            </li>
            <li>
              Documents : il s’agit d’un stockage de tous les documents
              informatifs développés par l’équipe WSFR à la destination du
              compétiteur et de l’équipe métier (présentations Power Point,
              émargements ou autre document administratif).
            </li>
            <li>
              Évaluation : un outil permettant d’évaluer les projets réalisés
              par les compétiteurs (fonctionnalité en cours de développement).
            </li>
            <li>
              Planning : un calendrier regroupant les événements à venir prévus
              par l’équipe métier ou par WSFR.
            </li>
            <ul>
              <li>Un annuaire de contacts qui liste les profils suivants :</li>
              <li>Compétiteur</li>
              <li>Équipe métier</li>
              <li>Expert</li>
              <li>Team leader</li>
              <li>MOS</li>
              <li>Salarié WSFR</li>
            </ul>
            <li>
              Questionnaire : une partie regroupant les différents
              questionnaires (questionnaire de satisfaction après un
              regroupement, évaluation de la plateforme, des ressources… ;
              fonctionnalité en cours de développement).
            </li>
            <ul>
              <li>
                Cinq autres fonctionnalités qui renvoient vers des pages
                externes :
              </li>
              <li>
                CIS : Système de notation pour les compétitions internationales
                ;
              </li>
              <li>
                Forum : espace d’échanges de messages pour les compétitions
                internationales ;
              </li>
              <li>
                Egencia : espace de réservation de moyens de transport et
                d’hébergement ;
              </li>
              <li>
                Note de frais : espace pour ajouter les différentes notes de
                frais liées aux dépenses avancées par les experts ;
              </li>
              <li>
                Trombinoscope : dossier regroupant la liste et les photos des
                membres de l’équipe de France.
              </li>
            </ul>
          </ul>
        </p>

        <h3>4.2. Engagement de WORLDSKILLS FRANCE</h3>

        <p>
          La plateforme est accessible gratuitement en extranet à tout
          utilisateur ayant un accès internet. Tous les frais supportés par
          l’utilisateur pour accéder au service (matériel informatique,
          logiciels, connexion internet) sont à sa charge.
        </p>

        <p>
          WORLDSKILLS France met en œuvre tous les moyens possibles pour assurer
          un accès de qualité à la plateforme.
        </p>

        <p>
          L’accès aux services de la plateforme peut à tout moment faire l’objet
          d’une interruption, d’une suspension, d’une modification sans préavis
          pour une maintenance ou pour tout autre cas.
        </p>

        <p>
          L’utilisateur de la plateforme a la possibilité de contacter le
          service technique de la plateforme par messagerie électronique :
          (benjamin@differangles.fr).
        </p>

        <h3>4.3. Engagement de l’utilisateur</h3>

        <p>
          Tout utilisateur qui se connecte à la plateforme signe et confirme le
          respect de la clause de confidentialité détaillée ci-dessous :
          <ul>
            <li>
              Ne pas utiliser les données auxquelles l’utilisateur peut accéder
              à des fins autres que celles prévues par ses attributions ;
            </li>
            <li>
              Ne divulguer ces données qu’aux personnes dûment autorisées, en
              raison de leurs fonctions, à en recevoir communication, qu’il
              s’agisse de personnes privées, publiques, physiques ou morales ;
            </li>
            <li>
              Ne faire aucune copie de ces données sauf à ce que cela soit
              nécessaire à l’exécution de ses fonctions ;
            </li>
            <li>
              Prendre toutes les mesures conformes aux usages et à l’état de
              l’art dans le cadre de ses attributions afin d’éviter
              l’utilisation détournée ou frauduleuse de ces données ;
            </li>
            <li>
              Prendre toutes précautions conformes aux usages et à l’état de
              l’art pour préserver la sécurité physique et logique de ces
              données ;
            </li>
            <li>
              S’assurer, dans la limite de ses attributions, que seuls des
              moyens de communication sécurisés seront utilisés pour transférer
              ces données ;
            </li>
            <li>
              En cas de cessation de ses fonctions, restituer intégralement les
              données, fichiers informatiques et tout support d’information
              relatif à ces données.
            </li>
          </ul>
        </p>

        <p>
          Cet engagement de confidentialité, en vigueur pendant toute la durée
          de ses fonctions, demeurera effectif, sans limitation de durée après
          la cessation de ses fonctions, quelle qu’en soit la cause, dès lors
          que cet engagement concerne l’utilisation et la communication de
          données à caractère personnel.
        </p>

        <p>
          L’utilisateur est informé que toute violation du présent engagement
          l’expose à des sanctions disciplinaires et pénales conformément à la
          réglementation en vigueur, notamment au regard des articles 226-16 à
          226-24 du code pénal.
        </p>

        <h2>5. Droit de rétractation</h2>

        <p>
          Tout utilisateur a le droit d’accéder aux données collectées qui le
          concerne, les rectifier, demander leur effacement ou exercer son droit
          à la limitation du traitement de ses données.
        </p>

        <p>
          Pour exercer ces droits ou pour toute question sur le traitement des
          données, l’utilisateur peut formuler sa demande à l’adresse
          électronique suivante : rsi@cofom.org.{" "}
        </p>

        <h2>6. Propriété intellectuelle</h2>

        <h3>6.1. Définition</h3>

        <p>
          L’article L.111-1 du code de la Propriété intellectuelle pose le cadre
          de la protection du droit d’auteur comme suit : « L'auteur d'une œuvre
          de l'esprit jouit sur cette œuvre, du seul fait de sa création, d'un
          droit de propriété incorporelle exclusif et opposable à tous. Ce droit
          comporte des attributs d'ordre intellectuel et moral ainsi que des
          attributs d'ordre patrimonial, qui sont déterminés par les livres Ier
          et III du présent code ».
        </p>

        <h3>6.2. Contenus proposés sur la plateforme et propriété</h3>

        <p>
          La plateforme « Victoire », permet principalement aux différents
          utilisateurs d’échanger et de suivre la performance d’un compétiteur.
          Dans le cadre de ce suivi, les experts métiers sont amenés à déposer
          des documents de formation.
        </p>

        <p>
          Ces documents sont à destination des compétiteurs dans le cadre de
          leur préparation aux formations internationales.
        </p>

        <p>
          La propriété intellectuelle liée à ces documents revient à
          l’utilisateur qui les a créés. Il a donc le droit de demander la
          suppression de ces supports pédagogiques ainsi que la suppression
          totale de son compte personnel de la plateforme « Victoire » en
          formulant une demande à la responsable des systèmes d’information (via
          l’adresse électronique mentionnée dans le paragraphe 5).
        </p>

        <p>
          Par ailleurs, les documents conçus peuvent faire l’objet d’une cession
          de droits. À savoir, le droit patrimonial est cessible contrairement
          au droit moral qui est inaliénable et perpétuel. La cession de droits
          permettra à WorldSkills France la réutilisation des contenus
          pédagogiques, leur stockage, leur reproduction et représentation.
        </p>

        <h3>6.3. Cession des droits</h3>

        <p>
          La cession de droits doit obligatoirement être formulée dans un
          contrat écrit qui respecte l’article 131-3 du Code de la propriété
          intellectuelle . Les clauses qui doivent figurer dedans sont les
          suivantes :{" "}
          <ul>
            <li>L’objet du contrat ;</li>
            <li>Les droits cédés et le cadre de cession ;</li>
            <li>Les garanties</li>
            <li>La rémunération</li>
          </ul>
        </p>

        <p>
          1. https://www.cnil.fr/fr/reglement-europeen-protection-donnees
          <br />
          2. Member Organisation Support
          <br />
          3. Consultez le site cnil.fr pour plus d’informations sur vos droits.
          <br />
          4.
          https://www.legifrance.gouv.fr/codes/section_lc/LEGITEXT000006069414/LEGISCTA000006114031/#LEGISCTA000006114031
          <br />
          5.
          https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000006278958#:~:text=La%20transmission%20des%20droits%20de,et%20quant%20%C3%A0%20la%20dur%C3%A9e.
        </p>
      </Row>
      <hr />
      <Row>
        <Alert key="warning" variant="warning">
          <Form.Check
            type={"checkbox"}
            onChange={() => handleChange()}
            checked={cguOk}
            value={cguOk}
            id={`default-checkbox`}
            label={`En cochant cette case, je déclare avoir pris connaissance des conditions générales d'utilisation de Victoire et les accepter.`}
          />
        </Alert>
      </Row>
    </>
  );
};

export default Cgu;
