import { useKeycloak } from '@react-keycloak/web';
import React, { useContext, useEffect, useState } from 'react';
import Loading from '../../Helpers/Loading';
import { userContext } from '../../Stores/Store';
import * as _api from "../../Apis/Criteres_apis";
import ProgressBar from './ProgressBar';

const Progressions = (props) => {
    const { keycloak, initialized } = useKeycloak();
    const user = useContext(userContext);
    const [loading, setLoading] = useState(false);
    const [progressions, setProgressions] = useState();

      // On récupère les critères du bareme si existant
  useEffect(() => {
    if (props.username && props.date) {
      setLoading(true);
      _api
        .getProgressions(keycloak.token, props.username, user.competitionid, user.skillid, props.date)
        .then((res) => {
            setProgressions(res);
        })
        .then(() => setLoading(false));
    }
  }, [props.date, props.username]);

  const listProgressions = () => {
      return progressions
        .map((progression) => {
          return <><span style={{color:"grey"}}>Critère - {progression.letter} ( {progression.value}% {progression.prevision && `/  ${progression.prevision}%`} )</span><ProgressBar bgcolor={progression.color} completed={progression.value} goal={progression.prevision}/></>;
        });
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;

    return (
        <div>
            {progressions && listProgressions()}
        </div>
    );
};

export default Progressions;