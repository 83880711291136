import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Form, Image, Row, Table } from "react-bootstrap";
import { userContext } from "../../Stores/Store";
import * as _api from "../../Apis/QTrains_apis";
import { useKeycloak } from "@react-keycloak/web";
import Loading from "../../Helpers/Loading";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
	ContentState,
	EditorState,
	convertFromHTML,
	convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import moment from "moment";
import { BsArrowLeft } from "react-icons/bs";

const QTrainView = () => {
	const navigate = useNavigate();
	const user = useContext(userContext);
	const { qtrainId } = useParams();
	const { keycloak, initialized } = useKeycloak();
	const [entrainement, setEntrainement] = useState();
	const [showAlert, setShowAlert] = useState();
	const [loading, setLoading] = useState(true);
	const [validDelete, setValidDelete] = useState(false);
	const [editorState, setEditorState] = useState(() =>
		EditorState.createWithContent(
			ContentState.createFromBlockArray(convertFromHTML("")),
		),
	);
	const [urlChrono, setUrlChrono] = useState(false);

	const traduction = {
		others: "Autres",
		training_center: "Centre d'Excellence",
		distanciel: "Distanciel",
	};

	useEffect(() => {
		setEntrainement({
			...entrainement,
			description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
		});
	}, [editorState]);

	useEffect(() => {
		if (user.competitionid !== 0 && user.skillid !== 0) {
			setLoading(true);
			_api
				.getQTrainAsync(keycloak.token, qtrainId)
				.then((res) => {
					setEntrainement(res);
					if (res.lien_externe) setUrlChrono(true);
				})
				.then(() => setLoading(false));
		}
	}, [user.skillid, user.competitionid]);

	const updateEntrainement = () => {
		if (entrainement) {
			_api
				.postQTrain(keycloak.token, entrainement.id, entrainement)
				.then((res) => {
					if (res) {
						setShowAlert("success");
					} else {
						setShowAlert("failed");
					}
				});
		}
	};

	const deleteEntrainement = () => {
		if (!validDelete) {
			setValidDelete(true);
		} else {
			_api.deleteQTrain(keycloak.token, entrainement.id).then((res) => {
				navigate("/qtrains/");
			});
		}
	};

	const listProgression = () => {
		if (entrainement && entrainement.duree > 0) {
			const jourForms = [];
			for (let index = 1; index <= entrainement.duree; index++) {
				jourForms.push(
					<div
						key={index}
						style={{
							margin: "30px",
							border: "1px solid #ddd",
						}}
					>
						<div
							style={{
								backgroundColor: "#f7f7f7",
								padding: "10px",
								textAlign: "center",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								borderBottom: "1px solid #ddd",
							}}
						>
							<h5
								style={{
									margin: 0,
									textDecoration: "bold",
									textTransform: "uppercase",
								}}
							>
								📅 Jour {index}
							</h5>
							<div>
								<Form.Label style={{ marginRight: "10px" }}>
									Volume d'heures
								</Form.Label>
								<Form.Control
									style={{ width: "80px", display: "inline" }}
									name={`${index}_duree`}
									value={
										entrainement.progression[`${index}`] &&
										entrainement.progression[`${index}`].duree
									}
									rows={10}
									onChange={(e) =>
										setEntrainement((prevEntrainement) => ({
											...prevEntrainement,
											progression: {
												...prevEntrainement.progression,
												[`${index}`]: {
													...prevEntrainement.progression[`${index}`],
													duree: e.target.value,
												},
											},
										}))
									}
								/>
							</div>
						</div>

						<div style={{ display: "flex", flexWrap: "wrap" }}>
							<div style={{ flex: "1 1 30%", margin: "10px" }}>
								<Form.Group className="mb-3">
									<Form.Label>Programme</Form.Label>
									<Form.Control
										as="textarea"
										name={`${index}_programme`}
										value={
											entrainement.progression[`${index}`] &&
											entrainement.progression[`${index}`].programme
										}
										rows={10}
										onChange={(e) =>
											setEntrainement((prevEntrainement) => ({
												...prevEntrainement,
												progression: {
													...prevEntrainement.progression,
													[`${index}`]: {
														...prevEntrainement.progression[`${index}`],
														programme: e.target.value,
													},
												},
											}))
										}
									/>
									<Form.Text>
										Activités prévues pour atteindre l'objectif général
									</Form.Text>
								</Form.Group>
							</div>
							<div style={{ flex: "1 1 30%", margin: "10px" }}>
								<Form.Group className="mb-3">
									<Form.Label>Contenu détaillé</Form.Label>
									<Form.Control
										as="textarea"
										name={`${index}_contenu`}
										value={
											entrainement.progression[`${index}`] &&
											entrainement.progression[`${index}`].contenu
										}
										rows={10}
										onChange={(e) =>
											setEntrainement((prevEntrainement) => ({
												...prevEntrainement,
												progression: {
													...prevEntrainement.progression,
													[`${index}`]: {
														...prevEntrainement.progression[`${index}`],
														contenu: e.target.value,
													},
												},
											}))
										}
									/>
								</Form.Group>
							</div>
							<div style={{ flex: "1 1 30%", margin: "10px" }}>
								<Form.Group className="mb-3">
									<Form.Label>Méthodes pédagogiques</Form.Label>
									<Form.Control
										as="textarea"
										name={`${index}_methodes`}
										value={
											entrainement.progression[`${index}`] &&
											entrainement.progression[`${index}`].methodes
										}
										rows={10}
										onChange={(e) =>
											setEntrainement((prevEntrainement) => ({
												...prevEntrainement,
												progression: {
													...prevEntrainement.progression,
													[`${index}`]: {
														...prevEntrainement.progression[`${index}`],
														methodes: e.target.value,
													},
												},
											}))
										}
									/>
									<Form.Text>
										Stratégie pédagogique pour atteindre les objectifs : cours
										théorique, jeu de rôle, étude de cas, mise en scène,
										exercice, débat, démonstration, témoignage, mise en
										situation, sketch...
									</Form.Text>
								</Form.Group>
							</div>
							<div style={{ flex: "1 1 30%", margin: "10px" }}>
								<Form.Group className="mb-3">
									<Form.Label>Supports pédagogiques</Form.Label>
									<Form.Control
										as="textarea"
										name={`${index}_supports`}
										value={
											entrainement.progression[`${index}`] &&
											entrainement.progression[`${index}`].supports
										}
										rows={10}
										onChange={(e) =>
											setEntrainement((prevEntrainement) => ({
												...prevEntrainement,
												progression: {
													...prevEntrainement.progression,
													[`${index}`]: {
														...prevEntrainement.progression[`${index}`],
														supports: e.target.value,
													},
												},
											}))
										}
									/>
									<Form.Text>
										Support vidéo, doucment de préparation, cas pratique, fiche
										technique, revues techniques…
									</Form.Text>
								</Form.Group>
							</div>
							<div style={{ flex: "1 1 30%", margin: "10px" }}>
								<Form.Group className="mb-3">
									<Form.Label>
										Documents et outils remis aux apprenants
									</Form.Label>
									<Form.Control
										as="textarea"
										name={`${index}_documents_outils`}
										value={
											entrainement.progression[`${index}`] &&
											entrainement.progression[`${index}`].documents_outils
										}
										rows={10}
										onChange={(e) =>
											setEntrainement((prevEntrainement) => ({
												...prevEntrainement,
												progression: {
													...prevEntrainement.progression,
													[`${index}`]: {
														...prevEntrainement.progression[`${index}`],
														documents_outils: e.target.value,
													},
												},
											}))
										}
									/>
									<Form.Text>
										Document de synthèse, dossier technique, fiche technique,
										fiche de cas, cahier des charges, support de cours papier ou
										numérique, référence lien internet…
									</Form.Text>
								</Form.Group>
							</div>
							<div style={{ flex: "1 1 30%", margin: "10px" }}>
								<Form.Group className="mb-3">
									<Form.Label>Modalités d'évaluation</Form.Label>
									<Form.Control
										as="textarea"
										name={`${index}_evaluation`}
										value={
											entrainement.progression[`${index}`] &&
											entrainement.progression[`${index}`].evaluation
										}
										rows={10}
										onChange={(e) =>
											setEntrainement((prevEntrainement) => ({
												...prevEntrainement,
												progression: {
													...prevEntrainement.progression,
													[`${index}`]: {
														...prevEntrainement.progression[`${index}`],
														evaluation: e.target.value,
													},
												},
											}))
										}
									/>
									<Form.Text>Compétence évaluée et comment ?</Form.Text>
								</Form.Group>
							</div>
						</div>
					</div>,
				);
			}
			return jourForms;
		}
	};

	//Contrôle du chargement des données
	if (loading) return <Loading />;

	return (
		<>
			<Row className="header">
				<Col sm={4}>
					<Image
						className="img_title"
						src={window.location.origin + "/images/navboard/evaluation.svg"}
					/>
					<span className="title">Sujet {entrainement.training_number}</span>
				</Col>

				<Col sm={7}>
					<Button variant="primary" onClick={() => navigate("/qtrains")}>
						<BsArrowLeft /> Retour aux programmes de formation
					</Button>
				</Col>
			</Row>
			<Form onSubmit={(e) => e.preventDefault()}>
				<Row>
					<Col>
						<Row>
							<Col>
								<h3>Progression pédagogique</h3>
							</Col>
						</Row>
						<Row>
							<Col>
								<Row>
									<Form.Label>Numéro du stage</Form.Label>
									<Form.Group className="mb-3">
										<Form.Control
											name="training_number"
											value={entrainement.training_number}
											onChange={(e) =>
												setEntrainement((prevEntrainement) => ({
													...prevEntrainement,
													training_number: e.target.value,
												}))
											}
										/>
									</Form.Group>
								</Row>
								<Row>
									<Form.Label>Titre de la formation</Form.Label>
									<Form.Group className="mb-3">
										<Form.Control
											name="titre"
											value={entrainement.titre}
											onChange={(e) =>
												setEntrainement((prevEntrainement) => ({
													...prevEntrainement,
													titre: e.target.value,
												}))
											}
										/>
									</Form.Group>
								</Row>
								<Row>
									<Form.Label>Nom du formateur</Form.Label>
									<Form.Group className="mb-3">
										<Form.Control
											name="formateur"
											value={entrainement.formateur}
											onChange={(e) =>
												setEntrainement((prevEntrainement) => ({
													...prevEntrainement,
													formateur: e.target.value,
												}))
											}
										/>
									</Form.Group>
								</Row>
							</Col>
							<Col>
								<Form.Label>Objectifs Généraux</Form.Label>
								<Form.Group className="mb-3">
									<Form.Control
										as="textarea"
										rows={8}
										name="objectifs_generaux"
										value={
											entrainement.objectifs_generaux &&
											entrainement.objectifs_generaux.replace(/<br>/g, "\n")
										}
										onChange={(e) =>
											setEntrainement((prevEntrainement) => ({
												...prevEntrainement,
												objectifs_generaux: e.target.value.replace(
													/\n/g,
													"<br>",
												),
											}))
										}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								Durée :{" "}
								<b>
									{entrainement.planification !== "A planifier" ? (
										entrainement.duree
									) : (
										<input
											type="number"
											onChange={(e) =>
												setEntrainement((prevEntrainement) => ({
													...prevEntrainement,
													duree: e.target.value,
												}))
											}
										/>
									)}{" "}
									jour(s)
								</b>
								<br />
								{entrainement.planification !== "A planifier" && (
									<>
										<b>
											Du {moment(entrainement.start_date).format("DD/MM/YYYY")}{" "}
											au {moment(entrainement.end_date).format("DD/MM/YYYY")}
										</b>
										<br />
										Localisation :{" "}
										<b>
											{traduction[entrainement.localisation] ||
												entrainement.localisation}
										</b>
									</>
								)}
							</Col>
							<Col>
								Planification : <b>{entrainement.planification}</b>&nbsp;
								{entrainement.planification === "Planification confirmée" && (
									<span role="img" aria-label="confirmed-planning-icon">
										📅
									</span>
								)}
								<br />
								{entrainement.planification !== "A planifier" && (
									<>
										Statut : <b>{entrainement.statut}</b>&nbsp;
										{entrainement.statut === "Remplis" && (
											<span role="img" aria-label="filled-icon">
												✅
											</span>
										)}
									</>
								)}
							</Col>
						</Row>
						<hr />
						<Row>
							<Col>
								{showAlert == "success" && (
									<Alert onClick={() => setShowAlert()} variant="success">
										Programme de formation sauvegardé !
									</Alert>
								)}
								{showAlert == "failed" && (
									<Alert onClick={() => setShowAlert()} variant="warning">
										Une erreur est survenue.
									</Alert>
								)}
							</Col>
						</Row>
						<Row className="justify-content-center mb-3">
							<Col xs="auto">
								<Button onClick={() => updateEntrainement()}>
									Sauvegarder
								</Button>
							</Col>
							<Col xs="auto">
								<Button variant={"danger"} onClick={() => deleteEntrainement()}>
									{!validDelete
										? "Supprimer"
										: "Cliquez de nouveau pour confirmer la suppression"}
								</Button>
							</Col>
						</Row>
						<Row>
							<Col sm={2}>
								<h3>Chronologie</h3>
							</Col>
							<Col sm={2}>
								<button
									style={{
										display: "flex",
										alignItems: "center",
										textAlign: "center",
										cursor: "pointer",
										color: "#1E4EE2",
										border: "1px solid #1E4EE2",
										margin: "6px auto",
										backgroundColor: urlChrono ? "#f7f7f7" : "#FFFFFF",
									}}
									onClick={() => setUrlChrono(!urlChrono)}
								>
									Utiliser un lien externe
								</button>
							</Col>
						</Row>
						{urlChrono ? (
							<Row>
								<Col>
									<Form.Label>Lien externe vers la Chronologie</Form.Label>
									<Form.Group className="mb-3">
										<Form.Control
											name="lien_externe"
											value={entrainement.lien_externe}
											onChange={(e) =>
												setEntrainement((prevEntrainement) => ({
													...prevEntrainement,
													lien_externe: e.target.value,
												}))
											}
										/>
									</Form.Group>
								</Col>
							</Row>
						) : (
							<Row>
								<Col>{entrainement && listProgression()}</Col>
							</Row>
						)}
					</Col>
				</Row>
				<hr />
				<Row>
					<Col>
						{showAlert == "success" && (
							<Alert onClick={() => setShowAlert()} variant="success">
								Programme de formation sauvegardé !
							</Alert>
						)}
						{showAlert == "failed" && (
							<Alert onClick={() => setShowAlert()} variant="warning">
								Une erreur est survenue.
							</Alert>
						)}
					</Col>
				</Row>
				<Row className="justify-content-center mb-3">
					<Col xs="auto">
						<Button onClick={() => updateEntrainement()}>Sauvegarder</Button>
					</Col>
					<Col xs="auto">
						<Button variant={"danger"} onClick={() => deleteEntrainement()}>
							{!validDelete
								? "Supprimer"
								: "Cliquez de nouveau pour confirmer la suppression"}
						</Button>
					</Col>
				</Row>
			</Form>
		</>
	);
};

export default QTrainView;
