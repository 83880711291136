/**
 * GET Contacts Async
 * @returns promise Contact
 */
export const getContactsAsync = async (token, competitionid, skillid) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/contacts/?competition=${competitionid}&skill=${skillid}`,
      requestOptions
    );
    if(promise.status === 200){
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * PUT Contacts In Skill Async
 * @returns promise Search Contact
 */
export const putContactInSkill = async (
  token,
  user,
  competitionid,
  skillid,
  role,
) => {
  const requestOptions = {
    method: "PUT",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/contacts/?username=${user}&competition=${competitionid}&skill=${skillid}&role=${role}`,
      requestOptions
    );
    if(promise.status === 201){
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * PUT Contacts Async
 * @returns HTTP Code
 */
export const putContact = async (token, competitionid, skillid, role, job_title, specialities, formData) => {
  const requestOptions = {
    method: "PUT",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
    body: JSON.stringify(formData),
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/contacts/users/?competition=${competitionid}&skill=${skillid}&role=${role}&job_title=${job_title}&specialities=${specialities}`,
      requestOptions
    );
    if(promise.status === 201){
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * DELETE Contacts Async
 * @returns HTTP Code
 */
export const deleteContact = async (token, user, competitionid, skillid) => {
  const requestOptions = {
    method: "DELETE",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/contacts/?username=${user}&competition=${competitionid}&skill=${skillid}`,
      requestOptions
    );
    if(promise.status === 200){
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * POST Contacts Async
 * @returns HTTP Code
 */
export const postContact = async (token, user, competitionid, skillid, role, job_title, specialities) => {
  const requestOptions = {
    method: "POST",
    headers: {Accept: "application/json", Authorization: `Bearer ${token}`},
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/contacts/${user}?competition=${competitionid}&skill=${skillid}&role=${role}&job_title=${job_title}&specialities=${specialities}`,
      requestOptions
    );
    if(promise.status === 200){
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};