const inputsContact = [
  { type: "text", field: "last_name", name: "Nom", required: true },
  { type: "text", field: "first_name", name: "Prénom", required: true },
  { type: "phone", field: "phone_number", name: "Téléphone", required: true },
  { type: "email", field: "email", name: "Email", required: true },
  { type: "checkbox", field: "role", name: "Rôle", required: true },
];

const inputsEvent = [
  { type: "checkbox", field: "color", name: "Couleur", required: true },
  { type: "text", field: "title", name: "Titre", required: true },
  { type: "date", field: "start", name: "Date de début", required: true },
  { type: "date", field: "end", name: "Date de fin", required: true },
];

const inputsTask = [
  { type: "checkbox", field: "color", name: "Couleur", required: true },
  { type: "text", field: "name", name: "Titre", required: true },
  { type: "text", field: "description", name: "Description", required: true },
  { type: "text", field: "category", name: "Catégorie", required: true },
];

export const controlForm = (object, data) => {
  let errorArray = [];
  switch (object) {
    case "contact":
      inputsContact.map((item) => {
        if (
          item.type === "email" &&
          !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/.test(
            data[item.field]
          )
        ) {
          errorArray.push("Le champ " + item.name + " n'est pas au bon format");
        }
        if (item.required) {
          if (data[item.field] === "") {
            errorArray.push("Le champ " + item.name + " est requis");
          }
        }
      });
      return errorArray;
      break;
    case "planning":
      inputsEvent.map((item) => {
        if (item.required) {
          if (data[item.field] === "") {
            errorArray.push("Le champ " + item.name + " est requis");
          }
        }
        if (item.type === "date" && item.field === "start") {
          if (data.start > data.end) {
            errorArray.push("La date de fin est avant la date de début");
          }
        }
      });
      return errorArray;
      break;
    case "task":
      inputsTask.map((item) => {
        if (item.required) {
          if (data[item.field] === "") {
            errorArray.push("Le champ " + item.name + " est requis");
          }
        }
      });
      return errorArray;
      break;

    default:
      break;
  }
};
