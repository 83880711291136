import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as _api from "../../Apis/Criteres_apis";
import { userContext } from "../../Stores/Store";

const ModalCritereView = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const handleClose = () => props.show(false);
  const [errorForm, setErrorForm] = useState();
  const [critere, setCritere] = useState(props.data);
  const user = useContext(userContext);
  const [colorSelect, setColorSelect] = useState();

  const handleChange = (e) => {
    setCritere({ ...critere, [e.target.name]: e.target.value });
  };

  const updateCritere = () => {
    critere.color = colorSelect;
    _api.postCritere(keycloak.token, critere.id, critere).then((results) => {
      if (results) {
        props.maj(results, "update"); //on renvoie le résultat vers le state à modifier du parent
        props.show(false);
      } else {
        setErrorForm("Une erreur technique s'est produite");
      }
    });
  };

  const deleteCritere = () => {
    _api.deleteCritere(keycloak.token, critere.id).then((results) => {
      if (results) {
        props.maj(critere, "delete"); //on renvoie le résultat vers le state à modifier du parent
        props.show(false);
      } else {
        setErrorForm("Une erreur technique s'est produite");
      }
    });
  };

  const addCritere = () => {
    critere.color = colorSelect;
    _api.putCritere(keycloak.token, props.id, critere).then((results) => {
      if (results) {
        props.maj(results, "add"); //on renvoie le résultat vers le state à modifier du parent
        props.show(false);
      } else {
        setErrorForm("Une erreur technique s'est produite");
      }
    });
  };

  const listColors = () => {
    let colors = [
      "#f08700",
      "#efca08",
      "#00a6a6",
      "#bbdef0",
      "#42e2b8",
      "#eb8a90",
      "#f3dfbf",
      "#4e4ef8",
      "#878787",
      "#f9d961",
      "#ff0000",
      "#014a01",
      "#06357a",
      "#ffc183",
      "#00a95c",
      "#7732a6",
      "#2b0e4f",
      "#aaaaaa",
      "#262626",
      "#4568dc",
    ];
    return colors.map((color) => {
      return (
        <Button
          style={{ background: color, border: 0, margin:10 }}
          onClick={() => setColorSelect(color)}
        >&nbsp;
        </Button>
      );
    });
  };

  const listAlphabet = () => {
    let alphabet = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    return alphabet.map((letter) => {
      return <option value={letter}>{letter}</option>;
    });
  };

  return (
    <Modal
      className="modal_app"
      show={props.show}
      onHide={handleClose}
      backdrop="static"
      centered="true"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.data ? "Modifier" : "Créer "} un critère
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Row className="mb-3">
            <Col sm={1}>
              <Form.Group className="mb-3">
                <Form.Control
                style={{background:colorSelect}}
                disabled
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Select
                className="mb-3"
                name="letter"
                value={critere.letter}
                onChange={handleChange}
                disabled={props.data && "true"}
              >
                <option>Choisir une Lettre</option>
                {listAlphabet()}
              </Form.Select>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control
                  placeholder="Nom du critère"
                  name="name"
                  value={critere.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row xs={1} md={6}>
            {listColors()}
          </Row>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        {props.data && (
          <>
            {user.userRights && user.userRights.cis.update && (
              <Button onClick={() => updateCritere()}>Modifier</Button>
            )}

            {user.userRights && user.userRights.cis.delete && (
              <Button variant="danger" onClick={() => deleteCritere()}>
                Supprimer
              </Button>
            )}
          </>
        )}
        {!props.data && <Button onClick={() => addCritere()}>Ajouter</Button>}
        <Button variant="secondary" onClick={handleClose}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCritereView;
