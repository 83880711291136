import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import * as _api from "../../Apis/Criteres_apis";
import { userContext } from "../../Stores/Store";
import { BsFillXCircleFill } from "react-icons/bs";

const Resultats = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const [errorForm, setErrorForm] = useState();
  const user = useContext(userContext);

  const majResult = (data) => {
    let result = {
      date: props.date,
      subcriteria_id: props.sousCritereId,
      username: props.username,
      competition_id: user.competitionid,
      skill_number: user.skillid,
      value: data,
    };
    _api.putResultat(keycloak.token, result).then(() => {
      props.maj(result, "result");
      props.show(false);
    });
  };

  return (
    <Alert variant="info">
      <Row>
        <Col sm={10}>
          <small>Modifier le résultat : </small>
          <a style={{cursor:"pointer"}} onClick={() => majResult(0)}>
            <span className="round_toeval"></span>
          </a>
          &nbsp;
          <a style={{cursor:"pointer"}} onClick={() => majResult(33)}>
            <span className="round_preparing"></span>
          </a>
          &nbsp;
          <a style={{cursor:"pointer"}} onClick={() => majResult(66)}>
            <span className="round_near"></span>
          </a>
          &nbsp;
          <a style={{cursor:"pointer"}} onClick={() => majResult(100)}>
            <span className="round_ready"></span>
          </a>
          &nbsp;
        </Col>
        <Col sm={2}><a href="#" onClick={() => props.show(false)}><BsFillXCircleFill /></a></Col>
      </Row>
    </Alert>
  );
};

export default Resultats;
