/**
 * GET Questions LogsAsync
 * @returns promise Contact
 */
export const getQuestionLogs = async (token, id) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/questionnaires/logs?id=${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * GET Questions Async
 * @returns promise Contact
 */
export const getQuestions = async (token, competitionid, skillid) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let competition = "";
    let skill = "";
    if (competitionid != "" && competitionid != null)
      competition = `?competition=${competitionid}`;
    if (skillid != null && skillid != "")
      skill = `${competition == "" ? "?" : "&"}skill=${skillid}`;
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/questionnaires/${competition}${skill}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * GET Documents Folders Async
 * @returns promise Contact
 */
export const getDocumentsFolders = async (token, competitionid, skillid) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let competition = "";
    let skill = "";
    if (competitionid != "" && competitionid != null)
      competition = `?competition=${competitionid}`;
    if (skillid != null && skillid != "")
      skill = `${competition == "" ? "?" : "&"}skill=${skillid}`;
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/folders/${competition}${skill}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * GET Skills Async
 * @returns promise Contact
 */
export const getSkills = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/skills/`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * PUT User Async
 * @returns HTTP Code
 */
export const putUser = async (token, formData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/users/`,
      requestOptions
    );
    if (promise.status === 201) {
      let results = await promise.json();
      return results;
    } else if (promise.status === 422) {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail[0].msg };
      return response;
    } else {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail };
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};
/**
 * GET Competitions Async
 * @returns promise Contact
 */
export const getCompetitions = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/competitions/`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * GET Competition ID Async
 * @returns promise Contact
 */
export const getCompetitionID = async (token, id) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/competitions/`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * GET Events Async
 * @returns promise Contact
 */
export const getEvents = async (token, competitionid, skillid) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let competition = "";
    let skill = "";
    if (competitionid != "" && competitionid != null)
      competition = `?competition=${competitionid}`;
    if (skillid != null && skillid != "")
      skill = `${competition == "" ? "?" : "&"}skill=${skillid}`;
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/events/${competition}${skill}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * GET Contacts Async
 * @returns promise Contacts
 */
export const getContacts = async (token, competitionid, skillid, login) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let competition = "";
    let skill = "";
    let loginquery = "";
    if (competitionid != "" && competitionid != null)
      competition = `?competition=${competitionid}`;
    if (skillid != null && skillid != "")
      skill = `${competition == "" ? "?" : "&"}skill=${skillid}`;
    if (login != null && login != "")
      loginquery = `${competition == "" ? "?" : "&"}login=${login}`;
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/contacts/${competition}${skill}${loginquery}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * GET Users Async
 * @returns promise Contact
 */
export const getUsers = async (token, userSearch, competitionid, skillid) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let search = "";
    let competition = "";
    let skill = "";
    if (userSearch != "" && userSearch != null)
      search = `?search=${userSearch}`;
    if (competitionid != "" && competitionid != null)
      competition = `${search == "" ? "?" : "&"}competition=${competitionid}`;
    if (skillid != null && skillid != "")
      skill = `${competition == "" ? "?" : "&"}skill=${skillid}`;
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/users/${search}${competition}${skill}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * PUT Skill Async
 * @returns HTTP Code
 */
export const putSkill = async (token, formData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/skills/`,
      requestOptions
    );
    if (promise.status === 201) {
      let results = await promise.json();
      return results;
    } else if (promise.status === 422) {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail[0].msg };
      return response;
    } else {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail };
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * PUT Competition Async
 * @returns HTTP Code
 */
export const putCompetition = async (token, formData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/competitions/`,
      requestOptions
    );
    if (promise.status === 201) {
      let results = await promise.json();
      return results;
    } else if (promise.status === 422) {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail[0].msg };
      return response;
    } else {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail };
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * PUT Question Async
 * @returns HTTP Code
 */
export const putQuestion = async (token, formData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/questionnaires/`,
      requestOptions
    );
    if (promise.status === 201) {
      let results = await promise.json();
      return results;
    } else if (promise.status === 422) {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail[0].msg };
      return response;
    } else {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail };
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * PUT Document Folder Async
 * @returns HTTP Code
 */
export const putFolder = async (token, competitionid, skillid, formData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  try {
    let competition = "";
    let skill = "";
    if (competitionid != "" && competitionid != null)
      competition = `?competition=${competitionid}`;
    if (skillid != null && skillid != "") skill = `&skill=${skillid}`;
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/folders/${competition}${skill}`,
      requestOptions
    );
    if (promise.status === 201) {
      let results = await promise.json();
      return results;
    } else if (promise.status === 422) {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail[0].msg };
      return response;
    } else {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail };
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * PUT Event Async
 * @returns HTTP Code
 */
export const putEvent = async (token, competitionid, skillid, formData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  try {
    let competition = "";
    let skill = "";
    if (competitionid != "" && competitionid != null)
      competition = `?competition=${competitionid}`;
    if (skillid != null && skillid != "") skill = `&skill=${skillid}`;
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/events/${competition}${skill}`,
      requestOptions
    );
    if (promise.status === 201) {
      let results = await promise.json();
      return results;
    } else if (promise.status === 422) {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail[0].msg };
      return response;
    } else {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail };
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

// /**
//  * PUT User Async
//  * @returns HTTP Code
//  */
// export const putUser = async (token, formData) => {
//   const requestOptions = {
//     method: "PUT",
//     headers: {
//       Accept: "application/json",
//       Authorization: `Bearer ${token}`,
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(formData),
//   };
//   try {
//     let promise = await fetch(
//       `${process.env.REACT_APP_API_URL}/admin/users/`,
//       requestOptions
//     );
//     if (promise.status === 201) {
//       let results = await promise.json();
//       return results;
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };

/**
 * PUT Contact Async
 * @returns HTTP Code
 */
export const putContact = async (token, formData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/contacts/`,
      requestOptions
    );
    if (promise.status === 201) {
      let results = await promise.json();
      return results;
    } else if (promise.status === 422) {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail[0].msg };
      return response;
    } else {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail };
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * DELETE Question Async
 * @returns HTTP Code
 */
export const deleteQuestion = async (token, id) => {
  const requestOptions = {
    method: "DELETE",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/questionnaires/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * DELETE Skill Async
 * @returns HTTP Code
 */
export const deleteSkill = async (token, id) => {
  const requestOptions = {
    method: "DELETE",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/skills/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * DELETE Competition Async
 * @returns HTTP Code
 */
export const deleteCompetition = async (token, id) => {
  const requestOptions = {
    method: "DELETE",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/competitions/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * DELETE Document Folder Async
 * @returns HTTP Code
 */
export const deleteFolder = async (token, id) => {
  const requestOptions = {
    method: "DELETE",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/folders/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * DELETE Event Async
 * @returns HTTP Code
 */
export const deleteEvent = async (token, id) => {
  const requestOptions = {
    method: "DELETE",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/events/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * DELETE User Async
 * @returns HTTP Code
 */
export const deleteUser = async (token, username) => {
  const requestOptions = {
    method: "DELETE",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/users/${username}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * DELETE Contact Async
 * @returns HTTP Code
 */
export const deleteContact = async (token, id) => {
  const requestOptions = {
    method: "DELETE",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/contacts/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
* POST Competition Async
* @returns HTTP Code
*/
export const postQuestion = async (token, id, purge, formData) => {
 const requestOptions = {
   method: "POST",
   headers: {
     Accept: "application/json",
     Authorization: `Bearer ${token}`,
     "Content-Type": "application/json",
   },
   body: JSON.stringify(formData),
 };
 try {
   let promise = await fetch(
     `${process.env.REACT_APP_API_URL}/admin/questionnaires/${id}?purge_visits=${purge}`,
     requestOptions
   );
   if (promise.status === 200) {
     let results = await promise.json();
     return results;
   } else if (promise.status === 422) {
     let results = await promise.json();
     let response = { status: promise.status, msg: results.detail[0].msg };
     return response;
   } else {
     let results = await promise.json();
     let response = { status: promise.status, msg: results.detail };
     return response;
   }
 } catch (error) {
   console.log(error);
 }
};

/**
 * POST Skill Async
 * @returns HTTP Code
 */
export const postSkill = async (token, id, name) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/skills/${id}?name=${name}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    } else if (promise.status === 422) {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail[0].msg };
      return response;
    } else {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail };
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * POST Competition Async
 * @returns HTTP Code
 */
export const postCompetition = async (token, id, formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/competitions/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    } else if (promise.status === 422) {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail[0].msg };
      return response;
    } else {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail };
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * POST Event Async
 * @returns HTTP Code
 */
export const postEvent = async (
  token,
  competitionid,
  skillid,
  id,
  formData
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  try {
    let competition = "";
    let skill = "";
    if (competitionid != "" && competitionid != null)
      competition = `?competition=${competitionid}`;
    if (skillid != null && skillid != "") skill = `&skill=${skillid}`;
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/events/${id}${competition}${skill}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    } else if (promise.status === 422) {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail[0].msg };
      return response;
    } else {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail };
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * POST Contact Async
 * @returns HTTP Code
 */
export const postContact = async (token, id, formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/contacts/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    } else if (promise.status === 422) {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail[0].msg };
      return response;
    } else {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail };
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * POST User Async
 * @returns HTTP Code
 */
export const postUser = async (token, username, formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/users/${username}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    } else if (promise.status === 422) {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail[0].msg };
      return response;
    } else {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail };
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * POST Document Folder Async
 * @returns HTTP Code
 */
export const postFolder = async (
  token,
  competitionid,
  skillid,
  id,
  formData
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  try {
    let competition = "";
    let skill = "";
    if (competitionid != "" && competitionid != null)
      competition = `?competition=${competitionid}`;
    if (skillid != null && skillid != "") skill = `&skill=${skillid}`;
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/folders/${id}${competition}${skill}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    } else if (promise.status === 422) {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail[0].msg };
      return response;
    } else {
      let results = await promise.json();
      let response = { status: promise.status, msg: results.detail };
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};
