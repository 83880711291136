import React, { createContext, useEffect, useState } from "react";

export const userContext = createContext();

const UserContextProvider = (props) => {
  const [skillid, setSkillid] = useState(0);
  const [competitionid, setCompetitionid] = useState(0);
  const [contactsSkill, setContactsSkill] = useState([]);
  const [userRights, setUserRights] = useState();
  const [userRoles, setUserRoles] = useState();
  const value = {
    skillid,
    setSkillid,
    competitionid,
    setCompetitionid,
    contactsSkill,
    setContactsSkill,
    userRights,
    setUserRights,
    userRoles,
    setUserRoles,
  };

  return (
    <userContext.Provider value={value}>{props.children}</userContext.Provider>
  );
};

export default UserContextProvider;
