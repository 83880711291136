/**
 * GET Entrainements Async
 * @returns promise Events
 */
export const getEntrainementsAsync = async (token, competitionid, skillid) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/trainingprojects/?competition=${competitionid}&skill=${skillid}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * GET Documents Download Async
 * @returns promise Contact
 */
export const geEntrainementDocDownload = async (token, projId, tokendoc) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/trainingprojects/${projId}/files/${tokendoc}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.text();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * GET Entrainements Criterias Async
 * @returns promise Events
 */
export const getEntrainementCritsAsync = async (token, projId) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/trainingprojects/${projId}/criterias/`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * GET Entrainements Documents Async
 * @returns promise Events
 */
export const getEntrainementDocsAsync = async (token, projId) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/trainingprojects/${projId}/files/`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * GET Entrainement by ID Async
 * @returns promise Tasks
 */
export const getEntrainementIdAsync = async (token, id) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/trainingprojects/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * PUT Entrainements Async
 * @returns HTTP Code
 */
export const putEntrainement = async (
  token,
  competitionid,
  skillid,
  formData
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/trainingprojects/?competition=${competitionid}&skill=${skillid}`,
      requestOptions
    );
    if (promise.status === 201) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * PUT Entrainements Criteria Async
 * @returns HTTP Code
 */
export const putEntrainementCrit = async (
  token,
  entrainement_id,
  formData
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/trainingprojects/${entrainement_id}/criterias/`,
      requestOptions
    );
    if (promise.status === 201) {
      let results = await promise.json();
      return results
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * POST Entrainements Async
 * @returns HTTP Code
 */
export const postEntrainement = async (token, id, formData) => {
  const requestOptions = {
    method: "POST",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}`, 
    "Content-Type": "application/json"},
    body: JSON.stringify(formData),
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/trainingprojects/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * POST Document Upload Async
 * @returns HTTP Code
 */
export const putEntrainementUpload = async (token, id, file) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: file,
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/trainingprojects/${id}/files/`,
      requestOptions
    );
    if (promise.status === 201) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * DELETE Criteria Async
 * @returns HTTP Code
 */
export const deleteCriteria = async (token, projId, id) => {
  const requestOptions = {
    method: "DELETE",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/trainingprojects/${projId}/criterias/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * DELETE Document Async
 * @returns HTTP Code
 */
export const deleteDocument = async (token, projId, id) => {
  const requestOptions = {
    method: "DELETE",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/trainingprojects/${projId}/files/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * DELETE Entrainement Async
 * @returns HTTP Code
 */
export const deleteEntrainement = async (token, id) => {
  const requestOptions = {
    method: "DELETE",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/trainingprojects/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};
