import React, { useContext, useEffect, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import keycloak from "../../Keycloak";
import NavigationBoard from "./NavigationBoard";
import PlanningWidget from "./PlanningWidget";
import TasksWidgetList from "./TasksWidgetList";
import "./home.scss";
import CommentsWidget from "./CommentsWidget";
import DocumentsWidget from "./DocumentsWidget";
import CisWidgetCharts from "./CisWidgetCharts";
import { NavLink } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { userContext } from "../../Stores/Store";
import * as _api from "../../Apis/Me_apis";
import moment from "moment";

const Home = () => {
  const { keycloak, initialized } = useKeycloak();
  const user = useContext(userContext);
  const [profile, setProfile] = useState();

  useEffect(() => {
    _api.getProfile(keycloak.token).then((res) => setProfile(res));
  }, []);

  return (
    <>
      {(profile && !profile.cgu_accepted) && (
        <Container>
          <Alert
            style={{ textAlign: "center" }}
            key="primary"
            variant="primary"
          >
            L'application Victoire possède ses Conditions Générales
            d'Utilisation, merci d'en prendre connaissance. &nbsp;
            <NavLink exact to={"/cgu"}>
              Accèder aux CGU
            </NavLink>
          </Alert>
        </Container>
      )}
      <div className="hometitle">
        Vous êtes connecté en tant que{" "}
        <span className="hometitle_name">{keycloak.tokenParsed.name}</span>, dernière visite le {profile && moment(profile.last_visit).format("DD/MM/YYYY")}
      </div>
      <Container>
        <Row>
          <Col sm={8}>
            <NavigationBoard />
            <CisWidgetCharts />
          </Col>
          <Col sm={4}>
            <TasksWidgetList />
            <PlanningWidget />
            <CommentsWidget />
            <DocumentsWidget />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
