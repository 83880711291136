import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row, Table } from "react-bootstrap";
import { userContext } from "../../Stores/Store";
import * as _api from "../../Apis/Entrainements_apis";
import { useKeycloak } from "@react-keycloak/web";
import Loading from "../../Helpers/Loading";
import { useNavigate, useParams } from "react-router-dom";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { BsDownload, BsFolder, BsTrash } from "react-icons/bs";
import { MdScore } from "react-icons/md";

const EntrainementView = () => {
  const navigate = useNavigate();
  const user = useContext(userContext);
  const { entrainementId } = useParams();
  const [result, setResult] = useState();
  const { keycloak, initialized } = useKeycloak();
  const [entrainement, setEntrainement] = useState();
  const [showModalDoc, setShowModalDoc] = useState(false);
  const [docs, setDocs] = useState();
  const [criterias, setCriterias] = useState();
  const [loading, setLoading] = useState(false);
  const [validDelete, setValidDelete] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(""))
    )
  );
  const [updateStatus, setUpdateStatus] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    setEntrainement({
      ...entrainement,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  }, [editorState]);

  useEffect(() => {
    setLoading(true);
    _api
      .getEntrainementCritsAsync(keycloak.token, entrainementId)
      .then((res) => setCriterias(res))
      .then(() => setLoading(false));
    _api
      .getEntrainementDocsAsync(keycloak.token, entrainementId)
      .then((res) => setDocs(res))
      .then(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (user.competitionid !== 0 && user.skillid !== 0) {
      setLoading(true);
      _api
        .getEntrainementIdAsync(keycloak.token, entrainementId)
        .then((res) => setEntrainement(res))
        .then(() => setLoading(false));
    }
  }, [user.skillid, user.competitionid]);

  const listDocs = () => {
    if (docs) {
      return docs.map((doc) => {
        return (
          <tr>
            <td>
              <a
                href={`${process.env.REACT_APP_API_URL}/trainingprojects/${entrainementId}/files/${doc.token}`}
              >
                {doc.name}
              </a>
            </td>
          </tr>
        );
      });
    }
  };

  const listCrits = () => {
    if (criterias) {
      return criterias.map((criteria) => {
        return (
          <tr>
            <td>{criteria.name}</td>
            <td>{criteria.points}</td>
          </tr>
        );
      });
    }
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <>
      <Row>
        <Col lg="8">
          <Form onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col>
                <h1>{entrainement && entrainement.name}</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3>{entrainement && entrainement.category}</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  {entrainement &&
                    "Temps estimé : " +
                      entrainement.estimated_hours +
                      " heure(s)"}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                {entrainement && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: entrainement.description,
                    }}
                  />
                )}
              </Col>
            </Row>
          </Form>
        </Col>
        <Col>
          <Row>
            <Col>
              <span className="title">
                <BsFolder /> Documents
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table hover responsive className="tab_contacts">
                <thead>
                  <tr>
                    <th>Nom du fichier</th>
                  </tr>
                </thead>
                <tbody>{docs && listDocs()}</tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="title">
                <MdScore /> Barème
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table hover responsive className="tab_contacts">
                <thead>
                  <tr>
                    <th>Nom du critère</th>
                    <th>Points</th>
                  </tr>
                </thead>
                <tbody>{criterias && listCrits()}</tbody>
              </Table>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EntrainementView;
