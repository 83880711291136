import React, { useContext, useEffect, useState } from "react";
import { Col, Image, Row, Button } from "react-bootstrap";
import "./qtrains.scss";
import * as _api from "../../Apis/QTrains_apis";
import QTrainsList from "./QTrainsList";
import { BsPlusLg } from "react-icons/bs";
import QTrainModal from "./QTrainModal";

const QTrains = () => {
	const [showCreateModal, setShowCreateModal] = useState(false);

	return (
		<>
			<Row className="header">
				<Col sm={4}>
					<Image
						className="img_title"
						src={window.location.origin + "/images/navboard/evaluation.svg"}
					/>
					<span className="title">Programmes de formation</span>
				</Col>
				<Col sm={3}>
					<Button
						size="sm"
						variant="outline-secondary"
						onClick={() => setShowCreateModal(true)}
					>
						<BsPlusLg /> Créer un programme de formation
					</Button>
				</Col>
			</Row>
			{showCreateModal && <QTrainModal show={setShowCreateModal} />}
			<Row>
				<QTrainsList />
			</Row>
		</>
	);
};

export default QTrains;
