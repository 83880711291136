import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import * as _api from "../../Apis/Contacts_apis";
import Loading from "../../Helpers/Loading";
import { userContext } from "../../Stores/Store";
import ContactView from "./ContactView";
import * as datas from "../../Helpers/Data";
import { BsFillPencilFill } from "react-icons/bs";
import {MdEmail} from "react-icons/md";
import * as _api2 from "../../Apis/Me_apis";

const ContactsList = () => {
  const user = useContext(userContext);

  const { keycloak, initialized } = useKeycloak();
  const [contacts, setContacts] = useState();
  const [contact, setContact] = useState();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
	const [competitionActive, setCompetitionActive] = useState();

  const handleModal = (status) => setShowModal(status); //fonction à passer au child pour MAJ status modal

  //fonction à passer au child pour MAJ du State liste contacts
  const majFromModal = (data, action) => {
    let arrayTemp = [{}];
    switch (action) {
      case "delete":
        const deleteList = contacts.filter(
          (item) => item.username !== data.username
        );
        const indexDelete = contacts.indexOf(data.username)
        setContacts(deleteList);
        user.contactsSkill.splice(indexDelete, 1); // On pense à remettre à jour le context
        break;
      case "pushinskill":
        const addList = contacts.concat(data);
        setContacts(addList);
        user.setContactsSkill(addList) // On pense à remettre à jour le context
        break;
      case "update":
        let userIndex = contacts.findIndex(
          (obj) => obj.username == data.username
        );
        arrayTemp = contacts;
        arrayTemp[userIndex] = data;
        setContacts(arrayTemp);
        user.setContactsSkill(arrayTemp) // On pense à remettre à jour le context
        break;
      case "add":
        setContacts([...contacts, data]);
        user.setContactsSkill([...user.contactsSkill, data]); // On pense à remettre à jour le context
        break;
      default:
        break;
    }
  };


  useEffect(() => {
		setLoading(true);
		_api2
			.getCompetitionsAsync(keycloak.token)
			.then((res) => {
				res.map((competition) => {
					if (competition.id == localStorage.getItem("competitionid")) {
						setCompetitionActive(competition);
					}
				});
			})
			.then(() => setLoading(false));
	}, [localStorage.getItem("competitionid")]);

  useEffect(() => {
    if (user.competitionid !== 0 && user.skillid !== 0) {
      setLoading(true);
      _api
        .getContactsAsync(keycloak.token, user.competitionid, user.skillid)
        .then((res) => setContacts(res))
        .then(() => setLoading(false));
    }
  }, [user.skillid, user.competitionid]);
  
  const listContacts = () => {
    return contacts.sort((a, b) => (a.first_name > b.first_name) ? 1 : -1).map((contact) => {
      return (
        <tr
          key={contact.username}
          className="tab_contacts_body"
        >
          <td>
            <span className={"round_" + contact.role}></span>
          </td>
          <td>{contact.first_name + " " + contact.last_name}</td>
          <td>{contact.job_title}</td>
          <td>{contact.phone_number}</td>
          <td>{contact.email}</td>
          <td>{contact.specialities}</td>
          <td><a className="icon_action" href="#" title="Editer le contact" onClick={() => {
            setShowModal(true);
            setContact(contact);
          }}><BsFillPencilFill /></a>
          <a className="icon_action" href={`mailto:${contact.email}`} title="Envoyer une email" ><MdEmail /></a>
          {/* <a href="#" title="Renvoyer le password" ><RiLockPasswordFill /></a> */}
          </td>
        </tr>
      );
    });
  };

  const filterRoleContactList = (e) => {
    if (e.target.value === "all") {
      setContacts(user.contactsSkill);
    } else {
      let contactsFiltered = user.contactsSkill.filter(
        (contact) => contact.role === e.target.value
      );
      setContacts(contactsFiltered);
    }
  };

  const listRoles = () =>
    datas.roles.map((role) => {
      if(role.name_db != 'admin')
        return (
          <Col>
            <Form.Check
              className={role.name_db}
              inline
              label={(competitionActive && competitionActive.national && role.name_db === "team") ? "Jurés" : role.name}
              name="role"
              type="radio"
              id={`inline-radio-${role.name_db}`}
              value={role.name_db}
              onChange={filterRoleContactList}
            />
          </Col>
        );
    });

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <>
      {showModal === true && (
        <ContactView show={handleModal} data={contact} maj={majFromModal} national={competitionActive && competitionActive.national} />
      )}

      <Row className="actions">
        <Col>
        {user.userRights && user.userRights.contact.create && (
          <Button
            onClick={() => {
              setShowModal(true);
              setContact("");
            }}
          >
            Ajouter un contact
          </Button>
        )}</Col>
      </Row>

      {/* Filtre de la liste par roles */}
      <Row>
        <Col>
          <Form.Check
            inline
            label="Tous"
            name="role"
            type="radio"
            value="all"
            id="inline-radio-all"
            onChange={filterRoleContactList}
            defaultChecked
          />
        </Col>
        {listRoles()}
      </Row>
      <Table hover responsive className="tab_contacts">
        <thead>
          <tr className="tab_contacts_header">
            <th></th>
            <th>Nom</th>
            <th>Fonction</th>
            <th>Téléphone</th>
            <th>E-mail</th>
            <th>Expertises</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{contacts && listContacts()}</tbody>
      </Table>
    </>
  );
};

export default ContactsList;
