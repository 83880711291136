import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import * as _api from "../../Apis/Criteres_apis";
import moment from "moment";
import { userContext } from "../../Stores/Store";
import { useKeycloak } from "@react-keycloak/web";
import Loading from "../../Helpers/Loading";
import {
  BsPlusLg,
  BsBarChartFill,
  BsTrash,
  BsFillCalendar2WeekFill,
} from "react-icons/bs";

const Previsions = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const [prevision, setPrevision] = useState({
    name: "",
    value: "",
    date: new Date(),
  });
  const [previsions, setPrevisions] = useState();
  const [errorForm, setErrorForm] = useState();
  const user = useContext(userContext);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    if (e.target.name == "value" && Number(e.target.value) > 100)
      e.target.value = 100;
    setPrevision({ ...prevision, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setLoading(true);
    _api
      .getPrevisions(
        keycloak.token,
        props.username,
        user.competitionid,
        user.skillid
      )
      .then((res) => setPrevisions(res))
      .then(() => setLoading(false));
  }, [props.username]);

  const listAlphabet = () => {
    let alphabet = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    return alphabet.map((letter) => {
      return <option value={letter}>Critère {letter}</option>;
    });
  };

  const deletePrevision = (data) => {
    _api.deletePrevision(keycloak.token, data).then((results) => {
      if (results) {
        let deleteList = previsions.filter((item) => item != data);
        setPrevisions(deleteList);
      } else {
        setErrorForm("Une erreur technique s'est produite");
      }
    });
  };

  const addPrevision = () => {
    let date = moment(prevision.date).format("YYYY-MM-DD");
    prevision.date = date;
    prevision.competition_id = user.competitionid;
    prevision.skill_number = user.skillid;
    prevision.username = props.username;
    _api.putPrevision(keycloak.token, prevision).then((results) => {
      if (results) {
        let letterIndex = previsions.findIndex(
          (obj) => obj.letter == results.letter
        );
        let dateIndex = previsions.findIndex((obj) => obj.date == results.date);
        if (letterIndex != -1 && dateIndex != -1) {
          //Mise à jour du tableau sans l'ID
          let isInArray = (element) =>
            element.date == prevision.date &&
            element.letter == prevision.letter;
          let items = [...previsions];
          let item = { ...previsions[previsions.findIndex(isInArray)] };
          item.value = prevision.value;
          items[previsions.findIndex(isInArray)] = item;
          setPrevisions(items);
        } else {
          setPrevisions([...previsions, results]);
        }
      } else {
        setErrorForm("Une erreur technique s'est produite");
      }
    });
  };

  const handleChangeDate = (date, field) => {
    setPrevision({ ...prevision, [field]: date });
  };

  const listPrevisions = () => {
    if (previsions) {
      return previsions
      .sort((a, b) => ( (a.letter > b.letter) || (a.letter == b.letter && a.date > b.date)) ? 1 : -1)
        .map((prevision) => {
          return (
            <tr>
              <td>{prevision.letter}</td>
              <td>{moment(prevision.date).format("DD-MM-YYYY")}</td>
              <td>{prevision.value}</td>
              <td>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => deletePrevision(prevision)}
                >
                  <BsTrash />
                </Button>
              </td>
            </tr>
          );
        });
    }
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <>
      <Row>
        <Col sm={4}>
          <Card>
            <Card.Header>
              <BsBarChartFill /> Définir un objectif
            </Card.Header>
            <Card.Body>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Form.Select
                    className="mb-3"
                    name="letter"
                    value={prevision.letter}
                    onChange={handleChange}
                  >
                    <option>Choisir une Lettre</option>
                    {listAlphabet()}
                  </Form.Select>
                  <Form.Group className="mb-3">
                    <Form.Label>Date de l'objectif &nbsp;</Form.Label>
                    <DateTimePicker
                      locale="fr-FR"
                      name="date"
                      value={
                        typeof prevision.date === "string"
                          ? new Date(prevision.date)
                          : prevision.date
                      }
                      onChange={(value) => handleChangeDate(value, "date")}
                      required="true"
                      format="d/MM/yyyy"
                      clearIcon=""
                    />
                  </Form.Group>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Valeur de l'objectif"
                      name="value"
                      value={prevision.value}
                      onChange={handleChange}
                      required
                    />
                    <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                  </InputGroup>
                  <Form.Group className="mb-3">
                    <Button
                      size="sm"
                      variant="primary"
                      onClick={() => addPrevision()}
                    >
                      <BsPlusLg /> Ajouter l'objectif
                    </Button>
                  </Form.Group>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={8}>
          <Row>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <td>Critère</td>
                  <td>Date</td>
                  <td>Valeur</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>{previsions && listPrevisions()}</tbody>
            </Table>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Previsions;
