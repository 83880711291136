import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useEffect, useState } from "react";
import { Card, Col, ListGroup, Row, Button } from "react-bootstrap";
import * as _api from "../../Apis/Tasks_apis";
import { userContext } from "../../Stores/Store";
import Loading from "../../Helpers/Loading";
import TaskWidgetView from "./TaskWidgetView";
import moment from "moment";

const TasksWidgetList = () => {
  const user = useContext(userContext);

  const { keycloak, initialized } = useKeycloak();

  const [task, setTask] = useState();
  const [tasks, setTasks] = useState();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleModal = (status) => setShowModal(status); //fonction à passer au child pour MAJ status modal

  //fonction à passer au child pour MAJ du State liste contacts
  const majFromModal = (data, action) => {
    let arrayTemp = [{}];
    switch (action) {
      case "delete":
        const deleteList = tasks.filter((item) => item.id !== data.id);
        setTasks(deleteList);
        break;
      case "update":
        let eventIndex = tasks.findIndex((obj) => obj.id == data.id);
        arrayTemp = tasks;
        data.due_date = moment(data.due_date).toISOString();
        arrayTemp[eventIndex] = data;
        setTasks(arrayTemp);
        break;
      case "add":
        arrayTemp = tasks;
        arrayTemp.push(data);
        setTasks(arrayTemp);
        break;
      default:
        break;
    }
  };

  const handleDone = (done, task) => {
    if (done === true) {      
      setLoading(true);
      _api.postTaskDone(keycloak.token, task.id).then((res) => {
        let eventIndex = tasks.findIndex((obj) => obj.id == res.id);
        let arrayTemp = tasks;
        arrayTemp[eventIndex] = res;
        setTasks(arrayTemp);
        console.log(tasks);
      }).then(() => setLoading(false));
    } else {    
      setLoading(true);
      _api.postTaskUndone(keycloak.token, task.id).then((res) => {
        let eventIndex = tasks.findIndex((obj) => obj.id == res.id);
        let arrayTemp = tasks;
        arrayTemp[eventIndex] = res;
        setTasks(arrayTemp);
      }).then(() => setLoading(false))
    }
  };

  useEffect(() => {
    if (user.competitionid !== 0 && user.skillid !== 0) {
      setLoading(true);
      _api
        .getTasksAsync(keycloak.token, user.competitionid, user.skillid)
        .then((res) => setTasks(res))
        .then(() => setLoading(false));
    }
  }, [user.skillid, user.competitionid]);

  const listTasks = () => {
    return tasks
      .sort((a, b) => (a.due_date > b.due_date ? 1 : -1))
      .map((task) => {
        return (
          <ListGroup.Item className="taskline" key={task.id}>
            {task.status === 1 || 
            (task.solved_by && (task.solved_by.indexOf(keycloak.tokenParsed.preferred_username) !== -1)) ? (
              <Row>
                <Col sm="1">
                  <input
                    type="checkbox"
                    checked
                    onClick={() => handleDone(false, task)}
                  />
                </Col>
                <Col>
                  <strike>
                    <a
                      className="task_a"
                      href="#"
                      onClick={() => {
                        setShowModal(true);
                        setTask(task);
                      }}
                    >
                      {task.name}
                    </a>
                  </strike>
                </Col>
                <Col className="widget_info_task" sm="4">
                  <span className="widget_task_icon">X</span>
                  <br />
                  {moment(task.due_date).format("DD/MM/YYYY HH:mm")}
                </Col>
              </Row>
            ) : (
              <Row>
                <Col sm="1">
                  <input type="checkbox" onClick={() => handleDone(true, task)} />
                </Col>
                <Col>
                  <a
                    className="task_a"
                    href="#"
                    onClick={() => {
                      setShowModal(true);
                      setTask(task);
                    }}
                  >
                    {task.name}
                  </a>
                </Col>
                <Col className="widget_info_task" sm="4">
                  <span className="widget_task_icon">?</span>
                  <br />
                  {moment(task.due_date).format("DD/MM/YYYY HH:mm")}
                </Col>
              </Row>
            )}
          </ListGroup.Item>
        );
      });
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <>
      {showModal === true && (
        <TaskWidgetView show={handleModal} id={task.id} maj={majFromModal} />
      )}
      <div className="widget">
        <Card className="taskswidget">
          <Card.Header className="widgetheader">
            <Row>
              <Col sm="3">To do</Col>
              <Col sm="7">
                <span style={{ fontSize: "small" }}></span>
              </Col>
              <Col sm="2">
                {user.userRights && user.userRights.todo.create && (
                  <Button
                    className="add_button"
                    onClick={() => {
                      setShowModal(true);
                      setTask("");
                    }}
                  >
                    +
                  </Button>
                )}
              </Col>
            </Row>
          </Card.Header>
          <ListGroup variant="flush">{tasks && listTasks()}</ListGroup>
        </Card>
      </div>
    </>
  );
};

export default TasksWidgetList;
