import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Col, ListGroup, Row } from "react-bootstrap";
import * as _api from "../../Apis/Documents_apis";
import { userContext } from "../../Stores/Store";
import { useKeycloak } from "@react-keycloak/web";
import {
  BsFillEyeFill,
  BsFillEyeSlashFill,
  BsPlusCircle,
  BsDownload,
  BsCloudDownload,
  BsFileEarmark,
  BsBoxArrowInRight,
  BsBoxArrowUpRight,
  BsFillTrashFill,
} from "react-icons/bs";
import DocumentText from "./DocumentText";
import DocumentEdit from "./DocumentEdit";
import DocumentFile from "./DocumentFile";
import Loading from "../../Helpers/Loading";
import DocumentCreate from "./DocumentCreate";
import DocumentMove from "./DocumentMove";
import { NavLink } from "react-router-dom";
import moment from "moment";

const DocumentsList = (props) => {
  const [documents, setDocuments] = useState();
  const [document, setDocument] = useState();
  const user = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const [showModalDocEdit, setShowModalDocEdit] = useState(false);
  const [showModalDocFile, setShowModalDocFile] = useState(false);
  const [showModalDocText, setShowModalDocText] = useState(false);
  const [showModalDocMove, setShowModalDocMove] = useState(false);
  const { keycloak, initialized } = useKeycloak();
  const [errorForm, setErrorForm] = useState();
  const [showModalDoc, setShowModalDoc] = useState(false);
  const [typeDoc, setTypeDoc] = useState();
  const [confirm_delete, setConfirm_delete] = useState(0);
  const handleModalDoc = (status) => setShowModalDoc(status); //fonction à passer au child pour MAJ status modal
  const handleModalDocEdit = (status) => setShowModalDocEdit(status); //fonction à passer au child pour MAJ status modal
  const handleModalDocFile = (status) => setShowModalDocFile(status); //fonction à passer au child pour MAJ status modal
  const handleModalDocText = (status) => setShowModalDocText(status); //fonction à passer au child pour MAJ status modal
  const handleModalDocMove = (status) => setShowModalDocMove(status); //fonction à passer au child pour MAJ status modal

  //fonction à passer au child pour MAJ du State liste
  const majFromModal = (data, action) => {
    let arrayTemp = [{}];
    switch (action) {
      case "move":
        props.maj(data, "move");
        break;
      case "add":
        arrayTemp = documents;
        arrayTemp.push(data);
        setDocuments(arrayTemp);
        break;
      case "delete":
        const deleteList = documents.filter((item) => item.id !== data.id);
        setDocuments(deleteList);
        break;
      case "update":
        let documentIndex = documents.findIndex((obj) => obj.id == data.id);
        arrayTemp = documents;
        arrayTemp[documentIndex] = data;
        setDocuments(arrayTemp);
        break;
      default:
        break;
    }
  };

  const changeStatus = (data, status) => {
    setLoading(true);
    let temp = { ...data };
    temp.status = status;
    _api
      .postDocument(keycloak.token, data.id, temp)
      .then((results) => {
        let arrayTemp;
        let documentIndex = documents.findIndex((obj) => obj.id == data.id);
        arrayTemp = documents;
        arrayTemp[documentIndex] = temp;
        setDocuments(arrayTemp);
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    if (user.competitionid !== 0 && user.skillid !== 0) {
      setLoading(true);
      _api
        .getDocuments(keycloak.token, props.folderid)
        .then((res) => setDocuments(res))
        .then(() => setLoading(false));
    }
  }, [props.categoryid]);

  const deleteDocument = (id) => {
    _api.deleteDocument(keycloak.token, id).then((results) => {
      if (results) {
        const deleteList = documents.filter(
          (documents) => documents.id !== id
        );
        setDocuments(deleteList);
        setConfirm_delete(0);
      } else {
        setErrorForm("Une erreur technique s'est produite");
      }
    });
  };

  const listDocuments = () =>
    documents.map((document) => {
      let buttonStatus;
      let buttonDownload;
      let buttonDoc;
      let buttonVisualize;
      buttonDoc = (
        <a
          style={{ cursor: "pointer" }}
          title="Modifier le fichier"
          onClick={() => {
            //fonction pour modifier le contenu du fichier avec modal
            setShowModalDoc(true);
            setDocument(document);
            if (document.type === "text/html") setTypeDoc("html");
            else setTypeDoc("file");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-pencil"
            viewBox="0 0 16 16"
          >
            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
          </svg>
        </a>
      );
      let buttonMove;
      buttonMove = (
        <a
          style={{ cursor: "pointer" }}
          title="Déplacer le fichier"
          onClick={() => {
            //fonction pour déplacer le fichier avec modal
            setShowModalDocMove(true);
            setDocument(document);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-folder-symlink"
            viewBox="0 0 16 16"
          >
            <path d="m11.798 8.271-3.182 1.97c-.27.166-.616-.036-.616-.372V9.1s-2.571-.3-4 2.4c.571-4.8 3.143-4.8 4-4.8v-.769c0-.336.346-.538.616-.371l3.182 1.969c.27.166.27.576 0 .742z" />
            <path d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14h10.348a2 2 0 0 0 1.991-1.819l.637-7A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm.694 2.09A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09l-.636 7a1 1 0 0 1-.996.91H2.826a1 1 0 0 1-.995-.91l-.637-7zM6.172 2a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z" />
          </svg>
        </a>
      );
      if (document.status == 1) {
        buttonStatus = (
          <a
            style={{ cursor: "pointer" }}
            title="Cacher le fichier"
            onClick={() => {
              changeStatus(document, 0);
            }}
          >
            <BsFillEyeFill size={20} />
          </a>
        );
      } else {
        buttonStatus = (
          <a
            style={{ cursor: "pointer" }}
            title="Afficher le fichier"
            onClick={() => {
              changeStatus(document, 1);
            }}
          >
            <BsFillEyeSlashFill size={20} />
          </a>
        );
      }
      if (document.size > 0) {
        buttonDownload = (
          <a
            title="Télécharger le document"
            href={`${process.env.REACT_APP_API_URL}/ged/documents/download?token=${document.token}`}
          >
            <BsCloudDownload />
          </a>
        );
        buttonVisualize = (
          <NavLink exact to={"/document/" + document.id}>
            Visualiser
          </NavLink>
        );
      }
      return (
        <ListGroup.Item style={{fontSize: 15 }} draggable>
          <Row>
            <Col sm={8}>
              <BsFileEarmark size={15} />
              &nbsp;
              <b>{document.name}</b>&nbsp;<small>{moment(document.creation_date).format("DD/MM/YYYY hh:mm")}</small>
              <a
                style={{cursor:"pointer"}}
                onClick={() => {
                  setShowModalDocEdit(true);
                  setDocument(document);
                }}
              >
                &nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-info-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                </svg>
              </a>
              <a 
                style={{cursor:"pointer"}} onClick={() => setConfirm_delete(document.id)}>
                &nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-trash"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                </svg>
              </a>
              {(confirm_delete != 0 && confirm_delete == document.id) && (
                <Alert style={{fontSize : 15}} key={document.id} variant="warning">
                  Êtes vous sûr de vouloir supprimer ce document "
                  {document.name}" ? &nbsp;
                  <Button  size="sm"
                    onClick={() => deleteDocument(document.id)}
                    variant="outline-success"
                  >
                    Je confirme
                  </Button>&nbsp;
                  <Button  size="sm"
                    onClick={() => setConfirm_delete(0)}
                    variant="outline-danger"
                  >
                    Annuler la suppression
                  </Button>
                </Alert>
              )}
            </Col>
            <Col style={{ fontSize: 15}} sm={2}>
              {buttonVisualize}
            </Col>

            <Col sm={2} style={{ fontSize: 15}}>
              {buttonDownload}
              &nbsp;
              {(keycloak.tokenParsed.preferred_username == document.author ||
                document.update) &&
                buttonDoc}
              &nbsp;
              {props.folder.delete && buttonMove}
              &nbsp;
              {(keycloak.tokenParsed.preferred_username == document.author ||
                document.update) &&
                buttonStatus}
            </Col>
          </Row>
        </ListGroup.Item>
      );
    });

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <>
      {showModalDocMove === true && (
        <DocumentMove
          show={handleModalDocMove}
          maj={majFromModal}
          data={document}
          folderid={props.folderid}
        />
      )}
      {showModalDocFile === true && (
        <DocumentFile
          show={handleModalDocFile}
          maj={majFromModal}
          data={document}
        />
      )}
      {showModalDocText === true && (
        <DocumentText
          show={handleModalDocText}
          maj={majFromModal}
          data={document}
        />
      )}
      {showModalDocEdit === true && (
        <DocumentEdit
          show={handleModalDocEdit}
          data={document}
          folder={props.folder}
          maj={majFromModal}
        />
      )}
      {showModalDoc === true && (
        <DocumentCreate
          show={handleModalDoc}
          data={document}
          maj={majFromModal}
          folderid={props.folderid}
          type={typeDoc}
        />
      )}
      {props.folder.create == true && (
        <Row style={{ textAlign: "center", marginBottom: 20 }}>
          <Col>
            <Button
              style={{ marginRight: 10 }}
              size="sm"
              variant="outline-primary"
              onClick={() => {
                setShowModalDoc(true);
                setTypeDoc("html");
                setDocument();
              }}
            >
              <BsPlusCircle /> Créer un nouvel article
            </Button>
          </Col>
          <Col>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => {
                setShowModalDoc(true);
                setTypeDoc("file");
                setDocument();
              }}
            >
              <BsDownload /> Uploader un nouveau document
            </Button>
          </Col>
        </Row>
      )}
      <ListGroup>{documents && listDocuments()}</ListGroup>
    </>
  );
};

export default DocumentsList;
