import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as _api from "../../Apis/Criteres_apis";
import { userContext } from "../../Stores/Store";

const ModalSousCritereView = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const handleClose = () => props.show(false);
  const [errorForm, setErrorForm] = useState();
  const [sousCritere, setSousCritere] = useState(props.data);
  const user = useContext(userContext);

  const handleChange = (e) => {
    setSousCritere({ ...sousCritere, [e.target.name]: e.target.value });
  };

  const updateSousCritere = () => {
    _api
      .postSousCritere(keycloak.token, sousCritere.id, sousCritere)
      .then((results) => {
        if (results) {
          props.maj(results, "update"); //on renvoie le résultat vers le state à modifier du parent
          props.show(false);
        } else {
          setErrorForm("Une erreur technique s'est produite");
        }
      });
  };

  const deleteSousCritere = () => {
    _api.deleteSousCritere(keycloak.token, sousCritere.id).then((results) => {
      if (results) {
        props.maj(sousCritere, "delete"); //on renvoie le résultat vers le state à modifier du parent
        props.show(false);
      } else {
        setErrorForm("Une erreur technique s'est produite");
      }
    });
  };

  const addSousCritere = () => {
    _api
      .putSousCritere(keycloak.token, props.id, sousCritere)
      .then((results) => {
        if (results) {
          props.maj(results, "add"); //on renvoie le résultat vers le state à modifier du parent
          props.show(false);
        } else {
          setErrorForm("Une erreur technique s'est produite");
        }
      });
  };

  return (
    <Modal
      className="modal_app"
      show={props.show}
      onHide={handleClose}
      backdrop="static"
      centered="true"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.data ? "Modifier" : "Créer "} un sous-critère
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control
                  placeholder="Nom du sous-critère"
                  name="name"
                  value={sousCritere.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        {props.data && (
          <>
            {user.userRights && user.userRights.cis.update && (
              <Button onClick={() => updateSousCritere()}>Modifier</Button>
            )}

            {user.userRights && user.userRights.cis.delete && (
              <Button variant="danger" onClick={() => deleteSousCritere()}>
                Supprimer
              </Button>
            )}
          </>
        )}
        {!props.data && (
          <Button onClick={() => addSousCritere()}>Ajouter</Button>
        )}
        <Button variant="secondary" onClick={handleClose}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSousCritereView;
