import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import Loading from "../../Helpers/Loading";
import { userContext } from "../../Stores/Store";
import * as _api from "../../Apis/Configuration_apis";
import * as me_api from "../../Apis/Me_apis";
import { useKeycloak } from "@react-keycloak/web";
import * as datas from "../../Helpers/Data";
import AlertMessage from "../../Helpers/AlertMessage";

const Contacts = () => {
  const user = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [contact, setContact] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const { keycloak, initialized } = useKeycloak();
  const [confirmDelete, setConfirmDelete] = useState();
  const [competitions, setCompetitions] = useState();
  const [competitionId, setCompetitionId] = useState(null);
  const [skills, setSkills] = useState();
  const [skillId, setSkillId] = useState(null);
  const [competitionSearch, setCompetitionSearch] = useState();
  const [skillSearch, setSkillSearch] = useState();
  const [loginSearch, setLoginSearch] = useState();
  const [errorForm, setErrorForm] = useState();

  // On réinitialise les erreurs quand on ferme l'alerte
  const initError = () => {
    setErrorForm();
  };

  useEffect(() => {
    if (competitionSearch != null && !skillSearch != null) {
      setLoading(true);
      _api
        .getContacts(keycloak.token)
        .then((res) => setContacts(res))
        .then(() => setLoading(false));
    } else {
      setLoading(true);
      _api
        .getContacts(keycloak.token, user.competitionid, user.skillid)
        .then((res) => setContacts(res))
        .then(() => setLoading(false));
    }
  }, [user.skillid, user.competitionid]);

  useEffect(() => {
    if (competitionId) {
      setLoading(true);
      me_api
        .getSkillsAsync(keycloak.token, competitionId)
        .then((res) => setSkills(res))
        .then(() => setLoading(false));
    } else {
      setSkills();
      setSkillId(null);
    }
  }, [competitionId]);

  useEffect(() => {
    setLoading(true);
    if(!competitionSearch) setCompetitionSearch(user.competitionid);
    if(!skillSearch) setSkillSearch(user.skillid);
    me_api
      .getCompetitionsAsync(keycloak.token)
      .then((res) => {
        setCompetitions(res);
      })
      .then(() => setLoading(false));
  }, []);

  const addContact = () => {
    if (contact.user_username && contact.role) {
      if (skillId) contact.skill_number = skillId;
      if (competitionId) contact.competition_id = competitionId;
      _api.putContact(keycloak.token, contact).then((res) => {
        if (res.status) {
          setErrorForm("Code Erreur " + res.status + ": " + res.msg);
        } else {
          setContacts([res, ...contacts]);
          eraseForm();
        }
      });
    }
  };

  const udapteContact = () => {
    if (contact.user_username && contact.role) {
      if (skillId) {contact.skill_number = skillId} else {contact.skill_number = null};
      if (competitionId) {contact.competition_id = competitionId} else {contact.competition_id = null};
      _api.postContact(keycloak.token, contact.id, contact).then((res) => {
        if (res.status) {
          setErrorForm("Code Erreur " + res.status + ": " + res.msg);
        } else {
          let arrayTemp = [];
          let userIndex = contacts.findIndex(
            (obj) => obj.user_username == res.user_username
          );
          arrayTemp = [...contacts];
          arrayTemp[userIndex] = res;
          setContacts(arrayTemp);
          eraseForm();
        }
      });
    }
  };

  const deleteContact = () => {
    if (contact.id) {
      if (confirmDelete == contact.user_username) {
        _api
          .deleteContact(keycloak.token, contact.id)
          .then((res) => {
            setContacts(
              contacts.filter(
                (item) => item.user_username !== contact.user_username
              )
            );
          })
          .then(() => setContact(""))
          .then(() => setShowDelete(false));
      }
    }
  };

  const search = () => {
    _api
      .getContacts(keycloak.token, competitionSearch, skillSearch, loginSearch)
      .then((res) => setContacts(res));
  };

  const eraseForm = () => {
    setContact("");
    setCompetitionId(null);
    setSkillId(null);
  };

  const listContacts = () => {
    return contacts.map((contact) => {
      return (
        <>
          <tr>
            <td>{contact.id}</td>
            <td>{contact.user_username}</td>
            <td>{contact.role}</td>
            <td>{contact.job_title}</td>
            <td>{contact.specialities}</td>
            <td>{contact.skill_number}</td>
            <td>{contact.competition_id}</td>
            <td>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setContact(contact);
                  setCompetitionId(contact.competition_id);
                  setSkillId(contact.skill_number);
                  setShowDelete(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-pencil"
                  viewBox="0 0 16 16"
                  color="blue"
                >
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>
              </span>
              &nbsp;
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setContact(contact);
                  setShowDelete(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-trash"
                  viewBox="0 0 16 16"
                  color="red"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fill-rule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </span>
            </td>
          </tr>
        </>
      );
    });
  };

  const listCompetitions = () => {
    return competitions
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((competition) => {
        return (
          <option
            selected={competition.id == competitionId}
            value={competition.id}
          >
            {competition.id} - {competition.name}
          </option>
        );
      });
  };

  const listSkills = () => {
    if (skills) {
      return skills
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((skill) => {
          return (
            <option value={skill.number}>
              {skill.number + " - " + skill.name}
            </option>
          );
        });
    }
  };

  const listRoles = () => {
    if (datas.roles) {
      return datas.roles.map((role) => {
        return <option value={role.name_db}>{role.name}</option>;
      });
    }
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;
  return (
    <>
      <Container fluid>
        {/* Affichage des erreurs */}
        {errorForm && <AlertMessage message={errorForm} errorMaj={initError} />}
        <Row>
          <Col sm={9}>
            <Row>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="ID Compétition"
                        value={competitionSearch}
                        defaultValue={user.competitionid}
                        onChange={(e) => setCompetitionSearch(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="ID Métier"
                        value={skillSearch}
                        defaultValue={user.skillid}
                        onChange={(e) => setSkillSearch(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="Login"
                        value={loginSearch}
                        onChange={(e) => setLoginSearch(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Button variant="outline-success" onClick={() => search()}>
                      Filtrer
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Row>
            <Row>
              <Table hover responsive striped>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Login</th>
                    <th>Role</th>
                    <th>Fonction</th>
                    <th>Specialités</th>
                    <th>Skill ID</th>
                    <th>Compétition ID</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{contacts && listContacts()}</tbody>
              </Table>
            </Row>
          </Col>
          {!showDelete && (
            <Col sm={3}>
              <h3>
                {!contact.id ? "Ajouter un " : "Modifier le"} contact
              </h3>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Form.Group className="mb-3">
                    <Form.Control
                      placeholder="Login"
                      value={contact && contact.user_username}
                      onChange={(e) =>
                        setContact({
                          ...contact,
                          user_username: e.target.value,
                        })
                      }
                      required
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Col>
                    <Form.Select
                      name="role_select"
                      onChange={(e) =>
                        setContact({ ...contact, role: e.target.value })
                      }
                      size="sm"
                      value={contact.role}
                    >
                      <option value="">Choisir un rôle</option>
                      {datas.roles && listRoles()}
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <Form.Group className="mb-3">
                    <Form.Control
                      placeholder="Fonction"
                      value={contact && contact.job_title}
                      onChange={(e) =>
                        setContact({ ...contact, job_title: e.target.value })
                      }
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3">
                    <Form.Control
                      placeholder="Spécialités"
                      value={contact && contact.specialities}
                      onChange={(e) =>
                        setContact({ ...contact, specialities: e.target.value })
                      }
                      required
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Col>
                    <Form.Select
                      name="competition_select"
                      onChange={(e) => setCompetitionId(e.target.value)}
                      size="sm"
                      value={competitionId}
                    >
                      <option value="">0 - Toutes les compétitions</option>
                      {competitions && listCompetitions()}
                    </Form.Select>
                  </Col>
                  <Col>
                    <Form.Select
                      size="sm"
                      name="skill_select"
                      onChange={(e) => setSkillId(e.target.value)}
                      value={skillId ? skillId : ""}
                    >
                      <option value="">0 - Tous les métiers</option>
                      {skills && listSkills()}
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {!contact.id && (
                      <Button
                        onClick={() => addContact()}
                        style={{ marginTop: "10px" }}
                      >
                        Ajouter
                      </Button>
                    )}
                    {contact.id && (
                      <>
                        <Button
                          onClick={() => udapteContact()}
                          style={{ marginTop: "10px" }}
                        >
                          Modifier
                        </Button>
                        &nbsp;
                        <Button
                          variant="outline-secondary"
                          onClick={() => eraseForm()}
                          style={{ marginTop: "10px" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-x-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                          </svg>
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </Form>
            </Col>
          )}
          {showDelete && (
            <Col sm={3}>
              <h3>Supprimer {contact.user_username}</h3>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Alert variant="warning">
                    Cette action sera irrévocable. Elle entrainera la
                    suppression de ce contact et de ses liens.
                    <br />
                    Pour confirmer la suppression veuillez saisir le login du
                    contact et cliquer sur "Supprimer"
                  </Alert>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="Login de l'utilisateur à supprimer"
                        required
                        value={confirmDelete}
                        onChange={(e) => setConfirmDelete(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Button
                      variant="outline-danger"
                      onClick={() => deleteContact()}
                    >
                      Supprimer
                    </Button>
                    &nbsp;
                    <Button
                      variant="outline-secondary"
                      onClick={() => {
                        setContact("");
                        setShowDelete(false);
                      }}
                    >
                      Annuler
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Contacts;
