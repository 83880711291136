import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import SearchContacts from "./SearchContacts";
import * as _api from "../../Apis/Contacts_apis";
import { useKeycloak } from "@react-keycloak/web";
import { userContext } from "../../Stores/Store";
import * as datas from "../../Helpers/Data";
import { controlForm } from "../../Helpers/FormValidate";
import AlertMessage from "../../Helpers/AlertMessage";

const ContactView = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const user = useContext(userContext);
  const [contact, setContact] = useState({});
  const [addContactView, setAddContactView] = useState(false);
  const [errorForm, setErrorForm] = useState();

  const handleClose = () => props.show(false);

  // On réinitialise les erreurs quand on ferme l'alerte
  const initError = () => {
    setErrorForm();
  };

  const deleteUserInSkill = (username, contact) => {
    let checkErrors = controlForm("contact", contact); // Contrôle du formulaire
    if (checkErrors.length === 0) {
      _api
        .deleteContact(
          keycloak.token,
          username,
          user.competitionid,
          user.skillid
        )
        .then((results) => {
          if (results) {
            props.maj(contact, "delete");
            props.show(false);
          } else {
            setErrorForm("Une erreur technique s'est produite");
          }
        });
    } else {
      setErrorForm(checkErrors);
    }
  };

  const updateUser = () => {
    let checkErrors = controlForm("contact", contact); // Contrôle du formulaire
    if (checkErrors.length === 0) {
      _api
        .postContact(
          keycloak.token,
          contact.username,
          user.competitionid,
          user.skillid,
          contact.role,
          contact.job_title,
          contact.specialities
        )
        .then((results) => {
          if (results) {
            props.maj(contact, "update");
            props.show(false);
          } else {
            setErrorForm("Une erreur technique s'est produite");
          }
        });
    } else {
      setErrorForm(checkErrors);
    }
  };

  const addUser = () => {
    let checkErrors = controlForm("contact", contact); // Contrôle du formulaire
    if (checkErrors.length === 0) {
      _api
        .putContact(
          keycloak.token,
          user.competitionid,
          user.skillid,
          contact.role,
          contact.job_title,
          contact.specialities,
          contact
        )
        .then((results) => {
          if (results) {
            props.maj(results, "add"); //on renvoie le résultat vers le state à modifier du parent
            props.show(false);
          } else {
            setErrorForm("Une erreur technique s'est produite");
          }
        });
    } else {
      setErrorForm(checkErrors);
    }
  };

  useEffect(() => {
    setContact(props.data);
  }, [props.data]);

  const handleChange = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  const showAddContact = () => {
    if (addContactView === false) {
      setAddContactView(true);
      setContact({
        email: "",
        first_name: "",
        last_name: "",
        role: "",
        phone_number: "",
        job_title: "",
        specialities: "",
      });
    }
  };

  const listRoles = () =>
    datas.roles.map((role) => {
      if (role.notadminlist)
        return (
          <Form.Check
            className={role.name_db}
            checked={contact.role === role.name_db && true}
            inline
            label={(props.national && role.name_db === "team") ? "Jurés" : role.name}
            name="role"
            type="radio"
            id={`modal-inline-radio-${role.name_db}`}
            value={role.name_db}
            onChange={handleChange}
          />
        );
    });

  return (
    <>
      <Modal
        className="modal_app"
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        centered="true"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.data ? "Modifier" : "Ajouter"} un contact
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!props.data && (
            <>
              <SearchContacts show={props.show} maj={props.maj} />
              <a href="#" onClick={() => showAddContact()}>
                Le contact n'existe pas ? Créez le.
              </a>
            </>
          )}
          {(contact || addContactView === true) && (
            <Form onSubmit={(e) => e.preventDefault()}>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Nom (Requis)"
                      name="last_name"
                      value={contact.last_name}
                      onChange={handleChange}
                      required
                      readOnly={!addContactView && "yes"}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Prénom (Requis)"
                      name="first_name"
                      value={contact.first_name}
                      onChange={handleChange}
                      required
                      readOnly={!addContactView && "yes"}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Téléphone (Requis)"
                      name="phone_number"
                      value={contact.phone_number}
                      onChange={handleChange}
                      readOnly={!addContactView && "yes"}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="email"
                      placeholder="Email (Requis)"
                      name="email"
                      value={contact.email}
                      onChange={handleChange}
                      required
                      readOnly={!addContactView && "yes"}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Statut</Form.Label>
                    {listRoles()}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Fonction"
                      name="job_title"
                      value={contact.job_title}
                      onChange={handleChange}
                      readOnly={!user.userRights.contact.update && "yes"}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Expertises"
                      name="specialities"
                      value={contact.specialities}
                      onChange={handleChange}
                      readOnly={!user.userRights.contact.update && "yes"}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Affichage des erreurs */}
              {errorForm && (
                <AlertMessage messages={errorForm} errorMaj={initError} />
              )}
            </Form>
          )}
        </Modal.Body>

        <Modal.Footer>
          {user.userRights &&
            user.userRights.contact.update &&
            contact &&
            addContactView === false && (
              <Button onClick={() => updateUser()}>Modifier</Button>
            )}
          {addContactView === true && (
            <Button onClick={() => addUser()}>Ajouter</Button>
          )}
          {/* Eviter la suppression de l'utilisateur actuel */}
          {user.userRights &&
            user.userRights.contact.delete &&
            props.data &&
            keycloak.tokenParsed.preferred_username !== contact.username && (
              <Button
                variant="danger"
                onClick={() => deleteUserInSkill(contact.username, contact)}
              >
                Supprimer
              </Button>
            )}
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ContactView;
