import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row, Table } from "react-bootstrap";
import { userContext } from "../../Stores/Store";
import * as _api from "../../Apis/Entrainements_apis";
import { useKeycloak } from "@react-keycloak/web";
import Loading from "../../Helpers/Loading";
import { useNavigate, useParams } from "react-router-dom";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { BsDownload, BsFolder, BsPlusCircle, BsTrash } from "react-icons/bs";
import DocumentCreate from "./DocumentCreate";
import { MdScore } from "react-icons/md";
import CriteriaCreate from "./CriteriaCreate";

const EntrainementEdit = () => {
  const navigate = useNavigate();
  const user = useContext(userContext);
  const { entrainementId } = useParams();
  const [result, setResult] = useState();
  const { keycloak, initialized } = useKeycloak();
  const [entrainement, setEntrainement] = useState();
  const [showModalDoc, setShowModalDoc] = useState(false);
  const [showModalCrit, setShowModalCrit] = useState(false);
  const [docs, setDocs] = useState();
  const [criterias, setCriterias] = useState();
  const [loading, setLoading] = useState(false);
  const [validDelete, setValidDelete] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(""))
    )
  );
  const [updateStatus, setUpdateStatus] = useState(false);
  const [showAlert, setShowAlert] = useState();

  const deleteDoc = (id) => {
    if (id) {
      _api.deleteDocument(keycloak.token, entrainementId, id).then((res) => {
        if (res) {
          _api
            .getEntrainementDocsAsync(keycloak.token, entrainementId)
            .then((res) => setDocs(res));
        }
      });
    }
  };

  const deleteCrit = (id) => {
    if (id) {
      _api.deleteCriteria(keycloak.token, entrainementId, id).then((res) => {
        if (res) {
          _api
            .getEntrainementCritsAsync(keycloak.token, entrainementId)
            .then((res) => setCriterias(res));
        }
      });
    }
  };

  const deleteEntrainement = () => {
    if (!validDelete) {
      setValidDelete(true);
    } else {
      _api.deleteEntrainement(keycloak.token, entrainementId).then(() => {
        navigate("/entrainements/");
      });
    }
  };

  const handleModalDoc = (status) => setShowModalDoc(status); //fonction à passer au child pour MAJ status modal
  const handleModalCrit = (status) => setShowModalCrit(status); //fonction à passer au child pour MAJ status modal

  const handleChange = (e) => {
    setEntrainement({ ...entrainement, [e.target.name]: e.target.value });
    setUpdateStatus(true);
  };

  useEffect(() => {
    setEntrainement({
      ...entrainement,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
    setUpdateStatus(true);
  }, [editorState]);

  useEffect(() => {
    if (user.competitionid !== 0 && user.skillid !== 0) {
      setLoading(true);
      _api
        .getEntrainementIdAsync(keycloak.token, entrainementId)
        .then((res) => {
          setEntrainement(res);
          if (res.description)
            setEditorState(
              EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  convertFromHTML(res.description)
                )
              )
            );
        })
        .then(() => setLoading(false));
    }
  }, [user.skillid, user.competitionid]);

  useEffect(() => {
    setLoading(true);
    _api
      .getEntrainementCritsAsync(keycloak.token, entrainementId)
      .then((res) => setCriterias(res));
    _api
      .getEntrainementDocsAsync(keycloak.token, entrainementId)
      .then((res) => setDocs(res))
      .then(() => setLoading(false));
  }, [result]);

  const updateEntrainement = () => {
    if (entrainement) {
      let data = {
        name: entrainement.name,
        category: entrainement.category,
        estimated_hours: entrainement.estimated_hours,
        description: entrainement.description,
        draft: entrainement.draft,
        archived: entrainement.archived,
      };
      _api
        .postEntrainement(keycloak.token, entrainementId, data)
        .then((res) => {
          if (res) {
            setUpdateStatus(false);
            setShowAlert("success");
          } else {
            setShowAlert("failed");
          }
        });
    }
  };

  const listCrits = () => {
    if (criterias) {
      return criterias.map((criteria) => {
        return (
          <tr>
            <td>{criteria.name}</td>
            <td>{criteria.points}</td>
            <td>
              <Button
                variant="danger"
                style={{ padding: 0 }}
                onClick={() => deleteCrit(criteria.id)}
              >
                <BsTrash />
              </Button>
            </td>
          </tr>
        );
      });
    }
  };

  const listDocs = () => {
    if (docs) {
      return docs.map((doc) => {
        return (
          <tr>
            <td>
              <a
                href={`${process.env.REACT_APP_API_URL}/trainingprojects/${entrainementId}/files/${doc.token}`}
              >
                {doc.name}
              </a>
            </td>
            <td>
              <Button
                variant="danger"
                style={{ padding: 0 }}
                onClick={() => deleteDoc(doc.id)}
              >
                <BsTrash />
              </Button>
            </td>
          </tr>
        );
      });
    }
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <>
      <Row>
        <Col lg="8">
          <Form onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <label>Nom du sujet</label>
                  <Form.Control
                    placeholder="Nom du sujet"
                    name="name"
                    value={entrainement && entrainement.name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <label>Catégorie</label>
                  <Form.Control
                    placeholder="Catégorie"
                    name="category"
                    value={entrainement && entrainement.category}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <label>Temps estimé (en heures)</label>
                  <Form.Control
                    type="number"
                    placeholder="Temps estimé (en heures)"
                    value={entrainement && entrainement.estimated_hours}
                    name="estimated_hours"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <label>Description du sujet</label>
                  <Editor
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                  />
                </Form.Group>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Brouillon</Form.Label>{" "}
                  <Form.Check
                    inline
                    name="draft"
                    type="checkbox"
                    id="draft"
                    onChange={(e) => {
                      setEntrainement({
                        ...entrainement,
                        [e.target.name]: e.target.checked,
                      });
                      setUpdateStatus(true);
                    }}
                    checked={entrainement && entrainement.draft}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Archiver</Form.Label>{" "}
                  <Form.Check
                    inline
                    name="archived"
                    type="checkbox"
                    id="archived"
                    onChange={(e) => {
                      setEntrainement({
                        ...entrainement,
                        [e.target.name]: e.target.checked,
                      });
                      setUpdateStatus(true);
                    }}
                    checked={entrainement && entrainement.archived}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  disabled={!updateStatus}
                  onClick={() => updateEntrainement()}
                >
                  Sauvegarder
                </Button>
              </Col>
              <Col>
                <Button variant={"danger"} onClick={() => deleteEntrainement()}>
                  {!validDelete
                    ? "Supprimer"
                    : "Cliquez de nouveau pour confirmer la suppression"}
                </Button>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                {showAlert == "success" && (
                  <Alert onClick={() => setShowAlert()} variant="success">
                    Sujet sauvegardé !
                  </Alert>
                )}
                {showAlert == "failed" && (
                  <Alert onClick={() => setShowAlert()} variant="warning">
                    Une erreur est survenue.
                  </Alert>
                )}
              </Col>
            </Row>
          </Form>
        </Col>
        <Col>
          <Row>
            <Col>
              <span className="title">
                <BsFolder /> Documents
              </span>{" "}
              {showModalDoc === true && (
                <DocumentCreate
                  show={handleModalDoc}
                  data={document}
                  result={setResult}
                  entrainementId={entrainement && entrainement.id}
                  type={"file"}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => {
                  setShowModalDoc(true);
                }}
              >
                <BsDownload /> Uploader un nouveau document
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table hover responsive className="tab_contacts">
                <thead>
                  <tr>
                    <th>Nom du fichier</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{docs && listDocs()}</tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="title">
                <MdScore /> Barème
              </span>{" "}
              {showModalCrit === true && (
                <CriteriaCreate
                  show={handleModalCrit}
                  data={document}
                  result={setResult}
                  entrainementId={entrainement && entrainement.id}
                  type={"file"}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => {
                  setShowModalCrit(true);
                }}
              >
                <BsPlusCircle /> Ajouter un nouveau critère
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table hover responsive className="tab_contacts">
                <thead>
                  <tr>
                    <th>Nom du critère</th>
                    <th>Points</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{criterias && listCrits()}</tbody>
              </Table>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EntrainementEdit;
