import React from "react";
import { useContext } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { userContext } from "../../Stores/Store";
import Competitions from "./Competitions";
import Contacts from "./Contacts";
import Events from "./Events";
import Folders from "./Folders";
import Skills from "./Skills";
import Users from "./Users";
import Questions from "./Questions";

const Configurations = () => {
  const user = useContext(userContext);

  if(user.userRoles && (user.userRoles.find(obj => obj == "admin" || user.userRoles.find(obj => obj == "staff"))))
  return (
    <div>
      <Tabs
        defaultActiveKey="users"
        id="uncontrolled-tab-example"
        className="mb-3"
        fill
      >
        <Tab eventKey="users" title="Utilisateurs">
            <Users />
        </Tab>
        <Tab eventKey="contacts" title="Contacts">
            <Contacts />
        </Tab>
        <Tab eventKey="folders" title="Dossiers">
           <Folders />
        </Tab>
        <Tab eventKey="questionnaires" title="Questionnaires">
           <Questions />
        </Tab>
        <Tab eventKey="events" title="Evénements">
           <Events />
        </Tab>
        <Tab eventKey="skills" title="Métiers">
           <Skills />
        </Tab>
        <Tab eventKey="competitions" title="Compétitions">
           <Competitions />
        </Tab>
      </Tabs>
    </div>
  );
  else 
  return null
};

export default Configurations;
