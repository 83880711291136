import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import Loading from "../../Helpers/Loading";
import { userContext } from "../../Stores/Store";
import * as _api from "../../Apis/Configuration_apis";
import * as me_api from "../../Apis/Me_apis";
import { useKeycloak } from "@react-keycloak/web";
import moment from "moment";
import DateTimePicker from "react-datetime-picker";
import AlertMessage from "../../Helpers/AlertMessage";

const Events = () => {
  const user = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState("");
  const [competitions, setCompetitions] = useState();
  const [competitionId, setCompetitionId] = useState(null);
  const [skills, setSkills] = useState();
  const [skillId, setSkillId] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const { keycloak, initialized } = useKeycloak();
  const [confirmDelete, setConfirmDelete] = useState();
  const [showTime, setShowTime] = useState(true);
  const [competitionSearch, setCompetitionSearch] = useState();
  const [skillSearch, setSkillSearch] = useState();
  const [errorForm, setErrorForm] = useState();

  // On réinitialise les erreurs quand on ferme l'alerte
  const initError = () => {
    setErrorForm();
  };

  useEffect(() => {
    if (competitionSearch != null && !skillSearch != null) {
      setLoading(true);
      _api
        .getEvents(keycloak.token)
        .then((res) => setEvents(res))
        .then(() => setLoading(false));
    } else {
      setLoading(true);
      _api
        .getEvents(keycloak.token, user.competitionid, user.skillid)
        .then((res) => setEvents(res))
        .then(() => setLoading(false));
    }
  }, [user.skillid, user.competitionid]);

  useEffect(() => {
    if (competitionId) {
      setLoading(true);
      me_api
        .getSkillsAsync(keycloak.token, competitionId)
        .then((res) => setSkills(res))
        .then(() => setLoading(false));
    } else {
      setSkills();
      setSkillId(null);
    }
  }, [competitionId]);

  useEffect(() => {
    setLoading(true);
    if(!competitionSearch) setCompetitionSearch(user.competitionid);
    if(!skillSearch) setSkillSearch(user.skillid);
    me_api
      .getCompetitionsAsync(keycloak.token)
      .then((res) => {
        setCompetitions(res);
      })
      .then(() => setLoading(false));
  }, []);

  const addEvent = () => {
    if (event.title) {
      _api
        .putEvent(keycloak.token, competitionId, skillId, event)
        .then((res) => {
          if (res.status) {
            setErrorForm("Code Erreur " + res.status + ": " + res.msg);
          } else {
            setEvents([res, ...events]);
          }
        });
    }
  };

  const updateEvent = () => {
    if (event.title) {
      // On force l'heure si All_Day
      if (!showTime) {
        let startDate = moment(event.start).format("YYYY-MM-DDT08:00:00");
        let endDate = moment(event.end).format("YYYY-MM-DDT23:00:00");
        event.start = new Date(startDate);
        event.end = new Date(endDate);
      }
      _api
        .postEvent(keycloak.token, competitionId, skillId, event.id, event)
        .then((res) => {
          if (res.status) {
            setErrorForm("Code Erreur " + res.status + ": " + res.msg);
          } else {
          let arrayTemp = [];
          let eventIndex = events.findIndex((obj) => obj.id == res.id);
          arrayTemp = [...events];
          arrayTemp[eventIndex] = res;
          setEvents(arrayTemp);
          errorForm();
          }
        })
    }
  };

  const deleteEvent = () => {
    if (event.id) {
      if (confirmDelete == event.title) {
        _api
          .deleteEvent(keycloak.token, event.id)
          .then((res) => {
            setEvents(events.filter((item) => item.id !== event.id));
          })
          .then(() => setEvent(""))
          .then(() => setShowDelete(false));
      }
    }
  };

  const handleChangeDate = (date, field) => {
    setEvent({ ...event, [field]: date });
  };

  const handleChangeAllDay = (e) => {
    if (e.target.checked) {
      setShowTime(false);
      setEvent({ ...event, [e.target.name]: e.target.checked });
    }
    if (!e.target.checked) {
      setShowTime(true);
      setEvent({ ...event, [e.target.name]: e.target.checked });
    }
  };

  const eraseForm = () => {
    setEvent("");
    setCompetitionId("");
    setSkillId(null);
  };

  const search = () => {
    _api
      .getEvents(keycloak.token, competitionSearch, skillSearch)
      .then((res) => setEvents(res));
  };

  const listEvents = () => {
    return events.map((event) => {
      return (
        <>
          <tr>
            <td>{event.id}</td>
            <td>{event.title}</td>
            <td>{event.competition_id}</td>
            <td>{event.skill_number}</td>
            <td>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEvent(event);
                  setCompetitionId(event.competition_id);
                  setSkillId(event.skill_number);
                  setShowDelete(false);
                  if (event.all_day) {
                    setShowTime(false);
                  } else {
                    setShowTime(true);
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-pencil"
                  viewBox="0 0 16 16"
                  color="blue"
                >
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>
              </span>
              &nbsp;
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEvent(event);
                  setShowDelete(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-trash"
                  viewBox="0 0 16 16"
                  color="red"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fill-rule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </span>
            </td>
          </tr>
        </>
      );
    });
  };

  const listCompetitions = () => {
    return competitions
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((competition) => {
        return (
          <option
            selected={competition.id == competitionId}
            value={competition.id}
          >
            {competition.id} - {competition.name}
          </option>
        );
      });
  };

  const listSkills = () => {
    if (skills) {
      return skills
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((skill) => {
          return (
            <option value={skill.number}>
              {skill.number + " - " + skill.name}
            </option>
          );
        });
    }
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;
  return (
    <>
      <Container fluid>
        {/* Affichage des erreurs */}
        {errorForm && <AlertMessage message={errorForm} errorMaj={initError} />}
        <Row>
          <Col sm={7}>
            <Row>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="ID Compétition"
                        defaultValue={user.competitionid}
                        value={competitionSearch}
                        onChange={(e) => setCompetitionSearch(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="ID Métier"
                        defaultValue={user.skillid}
                        value={skillSearch}
                        onChange={(e) => setSkillSearch(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Button variant="outline-success" onClick={() => search()}>
                      Chercher
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Row>
            <Row>
              <Table hover responsive striped>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nom</th>
                    <th>Compétition</th>
                    <th>Métier</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{events && listEvents()}</tbody>
              </Table>
            </Row>
          </Col>
          {!showDelete && (
            <Col>
              <h3>{!event.id ? "Ajouter un " : "Modifier l'"} évènement</h3>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Check
                        className="event_blue"
                        inline
                        name="color"
                        type="radio"
                        value="blue"
                        onChange={(e) =>
                          setEvent({ ...event, color: e.target.value })
                        }
                        checked={event.color === "blue" ? true : false}
                      />
                      <Form.Check
                        className="event_green"
                        inline
                        name="color"
                        type="radio"
                        value="green"
                        onChange={(e) =>
                          setEvent({ ...event, color: e.target.value })
                        }
                        checked={event.color === "green" ? true : false}
                      />
                      <Form.Check
                        className="event_orange"
                        inline
                        name="color"
                        type="radio"
                        value="orange"
                        onChange={(e) =>
                          setEvent({ ...event, color: e.target.value })
                        }
                        checked={event.color === "orange" ? true : false}
                      />
                      <Form.Check
                        className="event_red"
                        inline
                        name="color"
                        type="radio"
                        value="red"
                        onChange={(e) =>
                          setEvent({ ...event, color: e.target.value })
                        }
                        checked={event.color === "red" ? true : false}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={2}>
                    <Form.Label htmlFor="start">Date de début</Form.Label>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <DateTimePicker
                        locale="fr-FR"
                        value={
                          typeof event.start === "string"
                            ? new Date(event.start)
                            : event.start
                        }
                        name="start"
                        onChange={(value) => handleChangeDate(value, "start")}
                        format={showTime ? "" : "d/MM/yyyy"}
                        required="true"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={2}>
                    <Form.Label htmlFor="end">Date de fin</Form.Label>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <DateTimePicker
                        locale="fr-FR"
                        name="end"
                        value={
                          typeof event.end === "string"
                            ? new Date(event.end)
                            : event.end
                        }
                        onChange={(value) => handleChangeDate(value, "end")}
                        format={showTime ? "" : "d/MM/yyyy"}
                        required="true"
                      />
                    </Form.Group>
                  </Col>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Check
                          inline
                          label="Toute la journée"
                          name="all_day"
                          type="switch"
                          value="true"
                          onChange={handleChangeAllDay}
                          checked={event.all_day}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="Nom de l'évènement"
                        required
                        value={event && event.title}
                        onChange={(e) =>
                          setEvent({ ...event, title: e.target.value })
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Form.Group className="mb-3">
                    <Form.Control
                      placeholder="Lieu de l'événement"
                      name="localisation"
                      value={event && event.localisation}
                      onChange={(e) =>
                        setEvent({ ...event, localisation: e.target.value })
                      }
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3">
                    <Form.Control
                      as="textarea"
                      placeholder="Note"
                      name="note"
                      value={event && event.note}
                      onChange={(e) =>
                        setEvent({ ...event, note: e.target.value })
                      }
                      rows={3}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Col>
                    <Form.Select
                      name="competition_select"
                      onChange={(e) => setCompetitionId(e.target.value)}
                      size="sm"
                      value={competitionId}
                    >
                      <option value="">0 - Toutes les compétitions</option>
                      {competitions && listCompetitions()}
                    </Form.Select>
                  </Col>
                  <Col>
                    <Form.Select
                      size="sm"
                      name="skill_select"
                      onChange={(e) => setSkillId(e.target.value)}
                      value={skillId ? skillId : ""}
                    >
                      <option value="">0 - Tous les métiers</option>
                      {skills && listSkills()}
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {!event.id && (
                      <Button
                        onClick={() => addEvent()}
                        style={{ marginTop: "10px" }}
                      >
                        Ajouter
                      </Button>
                    )}
                    {event.id && (
                      <>
                        <Button
                          onClick={() => updateEvent()}
                          style={{ marginTop: "10px" }}
                        >
                          Modifier
                        </Button>
                        &nbsp;
                        <Button
                          variant="outline-secondary"
                          onClick={() => eraseForm()}
                          style={{ marginTop: "10px" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-x-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                          </svg>
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </Form>
            </Col>
          )}
          {showDelete && (
            <Col>
              <h3>Supprimer {event.title}</h3>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Alert variant="warning">
                    Cette action sera irrévocable. Elle entrainera la
                    suppression de cet évènement et de ses commentaires.
                    <br />
                    Pour confirmer la suppression veuillez saisir le nom de
                    l'évènement complet et cliquer sur "Supprimer"
                  </Alert>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="Nom de l'évènement à supprimer"
                        required
                        value={confirmDelete}
                        onChange={(e) => setConfirmDelete(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Button
                      variant="outline-danger"
                      onClick={() => deleteEvent()}
                    >
                      Supprimer
                    </Button>
                    &nbsp;
                    <Button
                      variant="outline-secondary"
                      onClick={() => {
                        setEvent("");
                        setShowDelete(false);
                      }}
                    >
                      Annuler
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Events;
