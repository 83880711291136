import React from "react";

const ProgressBar = (props) => {
  const { bgcolor, completed, goal } = props;

  const containerGoalStyles = {
    height: 25,
    width: "100%",
  };

  const containerStyles = {
    height: 20,
    width: "100%",
    backgroundColor: "#F9FBFD",
    borderRadius: 50,
    marginBottom: -8,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: "inherit",
    textAlign: "right",
  };

  const fillerGoalStyles = {
    height: "100%",
    marginLeft: `${goal-2}%`,
    display: "inline-block",
    height: "0",
    width: "0",
    borderRight: "5px solid transparent",
    borderBottom: `8px solid ${bgcolor}`,
    borderLeft: "5px solid transparent",
  };

  const labelStyles = {
    padding: 5,
    color: "white",
  };

  return (
    <>
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <span></span>
        </div>
      </div>
      <div style={containerGoalStyles}>
        {goal >= 1 && <div style={fillerGoalStyles}></div>}
      </div>
    </>
  );
};

export default ProgressBar;
