import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as _api from "../../Apis/Criteres_apis";
import { userContext } from "../../Stores/Store";

const ModalEditBareme = (props) => {
  const [bareme, setBareme] = useState({});
  const { keycloak, initialized } = useKeycloak();
  const handleClose = () => props.show(false);
  const [errorForm, setErrorForm] = useState();
  const user = useContext(userContext);
  
  useEffect(() => {
    if (props.id) {
      _api
        .getBareme(keycloak.token, props.id)
        .then((res) => setBareme(res));
    }
  }, [props.id]);

  const handleChange = (e) => {
    setBareme({ ...bareme, [e.target.name]: e.target.value });
  };

  const updateBareme = () => {
    _api.postBareme(keycloak.token, bareme.id, bareme).then((results) => {
      if (results) {
        props.maj(results, "updateBareme"); //on renvoie le résultat vers le state à modifier du parent
        props.show(false);
      } else {
        setErrorForm("Une erreur technique s'est produite");
      }
    });
    props.show(false);
  };

  return (
    <Modal
      className="modal_app"
      show={props.show}
      onHide={handleClose}
      backdrop="static"
      centered="true"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Modifier un barème</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control
                  placeholder="Nom du barème"
                  name="name"
                  value={bareme.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        
      {user.userRights && user.userRights.cis.update && (
        <Button onClick={() => updateBareme()}>Modifier</Button>
      )}
      
      {/* {user.userRights && user.userRights.cis.delete && (
        <Button variant="danger">Supprimer</Button>
      )} */}
        <Button variant="secondary" onClick={handleClose}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalEditBareme;
