import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Form, Image, ListGroup, Row } from "react-bootstrap";
import * as _api from "../../Apis/Documents_apis";
import Loading from "../../Helpers/Loading";
import { userContext } from "../../Stores/Store";
import { BsFillFileEarmarkFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";

const DocumentsWidget = (props) => {
  const [documents, setDocuments] = useState();
  const user = useContext(userContext);
  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    if (user.competitionid !== 0 && user.skillid !== 0) {
      _api
        .getLastDocumentsAsync(
          keycloak.token,
          5,
          user.competitionid,
          user.skillid
        )
        .then((res) => setDocuments(res));
    }
  }, [user.skillid, user.competitionid]);

  const listDocuments = () =>
    documents.map((document) => {
      return (
        <ListGroup.Item key={document.id}>
          <BsFillFileEarmarkFill />{" "}
          <NavLink exact to={"/document/" + document.id}>
            {document.name}
          </NavLink>
        </ListGroup.Item>
      );
    });

  return (
    <div className="widget">
      <Card>
        <Card.Header className="widgetheader">
          <Row>
            <Col sm="2">
              <Image className="img_title" src="images/navboard/docs.svg" />
            </Col>
            <Col>Documents</Col>
          </Row>
        </Card.Header>
        <ListGroup variant="flush">
          {documents && listDocuments()}
          <ListGroup.Item>
            <NavLink exact to="documents">
              Tous les documents
            </NavLink>
          </ListGroup.Item>
        </ListGroup>
      </Card>
    </div>
  );
};

export default DocumentsWidget;
