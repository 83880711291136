import React, { useContext, useEffect, useState } from "react";
import "./entrainements.scss";
import * as _api from "../../Apis/Entrainements_apis";
import { useKeycloak } from "@react-keycloak/web";

import {
  Accordion,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
  BsCheckCircle,
  BsDownload,
  BsEye,
  BsEyeSlash,
  BsLock,
} from "react-icons/bs";
import Loading from "../../Helpers/Loading";
import { userContext } from "../../Stores/Store";
import moment from "moment";

const EntrainementsListStaff = () => {
  const { keycloak, initialized } = useKeycloak();
  const [entrainements, setEntrainements] = useState();
  const user = useContext(userContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user.competitionid !== 0 && user.skillid !== 0) {
      setLoading(true);
      _api
        .getEntrainementsAsync(keycloak.token, user.competitionid, user.skillid)
        .then((res) => setEntrainements(res))
        .then(() => setLoading(false));
    }
  }, [user.skillid, user.competitionid]);

  const listEntrainements = (filtre) => {
    return entrainements
      .sort((a, b) => a.competition.id > b.competition.id)
      .sort((a, b) => a.date > b.date)
      .map((entrainement) => {
        if (filtre == "id" && !entrainement.archived && !entrainement.draft) {
          return (
            <Col style={{ marginBottom: "10px" }}>
              <Card className="sujet" style={{ margin: "auto" }}>
                <Card.Body>
                  <NavLink exact to={"/entrainement/" + entrainement.id}>
                    <h3>{entrainement.name}</h3>
                  </NavLink>
                  <Card.Text>
                    Par {entrainement.author.first_name}{" "}
                    {entrainement.author.last_name}
                    <br />
                    <small>
                      Le {moment(entrainement.date).format("DD/MM/YYYY")}
                    </small>
                    <div className="tooltip_sujet">
                      {entrainement.draft && (
                        <span className="">
                          <BsEyeSlash />
                        </span>
                      )}
                      {entrainement.competition.id != user.competitionid && (
                        <span className="">
                          <BsLock />
                        </span>
                      )}
                    </div>
                  </Card.Text>
                  <hr />
                  <Card.Text>
                    <b>Compétition</b> : {entrainement.competition.name}
                    <br />
                    <b>Catégorie</b> : {entrainement.category}
                    <br />
                    <b>Temps Estimé</b> : {entrainement.estimated_hours}{" "}
                    heure(s)
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          );
        } else if (filtre != "id" && entrainement[filtre] > 0)
          return (
            <Col style={{ marginBottom: "10px" }}>
              <Card className="sujet" style={{ margin: "auto" }}>
                <Card.Body>
                  <NavLink exact to={"/entrainement/" + entrainement.id}>
                    <h3>{entrainement.name}</h3>
                  </NavLink>
                  <Card.Text>
                    Par {entrainement.author.first_name}{" "}
                    {entrainement.author.last_name}
                    <br />
                    <small>
                      Le {moment(entrainement.date).format("DD/MM/YYYY")}
                    </small>
                    <div className="tooltip_sujet">
                      {entrainement.draft && (
                        <span className="">
                          <BsEyeSlash />
                        </span>
                      )}
                      {entrainement.competition.id != user.competitionid && (
                        <span className="">
                          <BsLock />
                        </span>
                      )}
                    </div>
                  </Card.Text>
                  <hr />
                  <Card.Text>
                    <b>Compétition</b> : {entrainement.competition.name}
                    <br />
                    <b>Catégorie</b> : {entrainement.category}
                    <br />
                    <b>Temps Estimé</b> : {entrainement.estimated_hours}{" "}
                    heure(s)
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col>
                      <BsDownload /> Télécharger
                    </Col>
                    <Col>
                      <BsCheckCircle /> Evaluer
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          );
      });
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <>
      <Row>
        <Accordion flush>
          {" "}
          <Accordion.Item style={{ background: "#F9FBFD" }} eventKey={1}>
            <Accordion.Header>
              <Col sm={11}>Tous les sujets</Col>
            </Accordion.Header>
            <Accordion.Body>
              <Row>{entrainements && listEntrainements("id")}</Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
      <Row>
        <Accordion flush>
          {" "}
          <Accordion.Item style={{ background: "#F9FBFD" }} eventKey={1}>
            <Accordion.Header>
              <Col sm={11}>A évaluer</Col>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Row>{entrainements && listEntrainements("last_percent")}</Row>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
      <Row>
        <Accordion flush>
          {" "}
          <Accordion.Item style={{ background: "#F9FBFD" }} eventKey={1}>
            <Accordion.Header>
              <Col sm={11}>En brouillon</Col>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Row>{entrainements && listEntrainements("draft")}</Row>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
      <Row>
        <Accordion flush>
          {" "}
          <Accordion.Item style={{ background: "#F9FBFD" }} eventKey={1}>
            <Accordion.Header>
              <Col sm={11}>Archives</Col>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Row>{entrainements && listEntrainements("archived")}</Row>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    </>
  );
};

export default EntrainementsListStaff;
