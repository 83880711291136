/**
 * GET Documents Async
 * @returns promise Contact
 */
export const getQuestions = async (token, competitionid, skillid) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/questionnaires/?competition=${competitionid}&skill=${skillid}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * POST Question ok Async
 * @returns HTTP Code
 */
export const postQuestion = async (token, id) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/questionnaires/${id}/visit`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};