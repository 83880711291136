import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";
import "./navigation.scss";
import * as _api from "../../Apis/Bookmarks_apis";
import { navigationleft_data } from "../../Helpers/Data";
import { userContext } from "../../Stores/Store";
import { useKeycloak } from "@react-keycloak/web";
import * as me_api from "../../Apis/Me_apis";

const Navigation = () => {
	const { keycloak, initialized } = useKeycloak();
	const user = useContext(userContext);
	const [urlAide, setUrlAide] = useState();

	// Initialisation des competitions
	useEffect(() => {
		me_api.getCompetitionsAsync(keycloak.token).then((res) => {
			if (res) {
				let competition = res.find((item) => item.id == user.competitionid);
				if (competition) setUrlAide(competition.url_aide);
			}
		});
	}, [localStorage.getItem("competitionid")]);

	const listNavigationleft = () =>
		navigationleft_data.map((navigationleft) => {
			if (navigationleft.id == 9) {
				return (
					<>
						{user.userRoles &&
						(user.userRoles.find((obj) => obj == "admin") ||
							user.userRoles.find((obj) => obj == "staff")) ? (
							<NavLink exact to={navigationleft.route}>
								<img src={navigationleft.icon} />
							</NavLink>
						) : (
							""
						)}
					</>
				);
			} else {
				return (
					<NavLink exact to={navigationleft.route}>
						<img src={navigationleft.icon} />
					</NavLink>
				);
			}
		});

	return (
		<>
			<div className="navigation_left">
				<span id="triangle-bottomleft"></span>
				<Nav defaultActiveKey="/" className="flex-column vertical_menu">
					{listNavigationleft()}

					{/* Ajout manuel du lien dropbox tutoriel et suppr dans le data helper*/}
					{urlAide && (
						<a href={urlAide}>
							<img
								src={
									window.location.origin + "/images/icons/smallmenu_aides.svg"
								}
							/>
						</a>
					)}
				</Nav>
			</div>
		</>
	);
};

export default Navigation;
