import React, { useContext, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as _api from "../../Apis/Entrainements_apis";
import { useKeycloak } from "@react-keycloak/web";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { userContext } from "../../Stores/Store";
import { useNavigate } from "react-router-dom";

const EntrainementModal = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();
  const user = useContext(userContext);
  const handleClose = () => props.show(false);
  const [entrainement, setEntrainement] = useState();
  const [errorForm, setErrorForm] = useState();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(""))
    )
  );

  const handleChange = (e) => {
    setEntrainement({ ...entrainement, [e.target.name]: e.target.value });
  };

  const addEntrainement = () => {
    entrainement.draft = 1
    _api
      .putEntrainement(
        keycloak.token,
        user.competitionid,
        user.skillid,
        entrainement
      )
      .then((results) => {
        if (results) {
          handleClose();
          navigate("/entrainement/"+results.id);
        } else {
          setErrorForm("Une erreur technique s'est produite");
        }
      });
  };

  return (
    <Modal
      className="modal_app"
      show={props.show}
      onHide={handleClose}
      backdrop="static"
      centered="true"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Ajouter un sujet d'entrainement</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control
                  placeholder="Nom du sujet"
                  name="name"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control
                  placeholder="Catégorie"
                  value=""
                  name="category"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Editor
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                />
              </Form.Group>
            </Col>
          </Row> */}
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => addEntrainement()}>Ajouter</Button>
        <Button variant="secondary" onClick={handleClose}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EntrainementModal;
