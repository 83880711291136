import React from 'react';

const Aides = () => {
    return (
        <div>
        <i>Fonctionnalité en cours de développement</i>
        </div>
    );
};

export default Aides;