/**
 * GET Comments Async
 * @returns promise Comment
 */
export const getCommentsAsync = async (token, module, id) => {
  const requestOptions = {
    method: "GET",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/${module}/comments/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
* GET Widget Comments Async
* @returns promise Comment
*/
export const getWidgetCommentsAsync = async (token, competitionid, skillid, limit) => {
 const requestOptions = {
   method: "GET",
   headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
 };
 try {
   let promise = await fetch(
     `${process.env.REACT_APP_API_URL}/general/last_comments?competition_id=${competitionid}&skill_number=${skillid}&limits=${limit}`,
     requestOptions
   );
   if (promise.status === 200) {
     let results = await promise.json();
     return results;
   }
 } catch (error) {
   console.log(error);
 }
};

/**
 * PUT Event Async
 * @returns HTTP Code
 */
export const putComment = async (token, module, id, formData) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/${module}/comments/${id}`,
      requestOptions
    );
    if (promise.status === 201) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * POST UnLike comment Async
 * @returns HTTP Code
 */
export const postUnLikeComment = async (token, module, id) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/${module}/comments/${id}/unlike`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * POST Like comment Async
 * @returns HTTP Code
 */
export const postLikeComment = async (token, module, id) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/${module}/comments/${id}/like`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * POST comment Async
 * @returns HTTP Code
 */
 export const postComment = async (token, module, id, formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/${module}/comments/${id}`,
      requestOptions
    );
    if(promise.status === 200){
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};