import React, { useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import * as _api from "../../Apis/Documents_apis";
import { useKeycloak } from "@react-keycloak/web";
import moment from "moment";
import { useContext } from "react";
import { userContext } from "../../Stores/Store";

const DocumentEdit = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const handleClose = () => props.show(false);
  const [document, setDocument] = useState(props.data);
  const [errorForm, setErrorForm] = useState();
  const user = useContext(userContext);
  const [folder, setFolder] = useState(props.folder);

  const handleChange = (e) => {
    setDocument({ ...document, [e.target.name]: e.target.value });
  };

  const updateDocument = () => {
    _api.postDocument(keycloak.token, document.id, document).then((results) => {
      if (results) {
        props.maj(document, "update");
        props.show(false);
      } else {
        setErrorForm("Une erreur technique s'est produite");
      }
    });
  };

  const deleteDocument = () => {
    _api.deleteDocument(keycloak.token, document.id).then((results) => {
      if (results) {
        props.maj(document, "delete");
        props.show(false);
      } else {
        setErrorForm("Une erreur technique s'est produite");
      }
    });
  };

  const replaceUsername = (username) => {
    let fullname = user.contactsSkill.find((author) => {
      return author.username === username
    })
    return fullname ? fullname.first_name+" "+fullname.last_name : username
  }

  return (
    <Modal
      className="modal_app"
      show={props.show}
      onHide={handleClose}
      backdrop="static"
      centered="true"
      size="lg"
    >
      {console.log(document.token)}
      <Modal.Header closeButton>
        <Modal.Title>Modifier un fichier</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control
                  placeholder="Nom du fichier"
                  value={document.name}
                  name="name"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col>
            <b>Auteur :</b> {replaceUsername(document.author)}
            <br />
            <b>Publié :</b> {document.status == 1 ? "Oui" : "Non"}
            <br />
            <b>Date de création :</b>{" "}
            {moment(document.creation_date).format("D/M/yyyy")}
            <br />
            <b>Type de document :</b> {document.extension}
            <br />
            <b>Taille du fichier :</b> {document.size} octets
            <br />
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        {document.update && (
          <Button onClick={() => updateDocument()}>Modifier</Button>
        )}
        {folder.delete && (
          <Button variant="danger" onClick={() => deleteDocument()}>
            Supprimer
          </Button>
        )}
        <Button variant="secondary" onClick={handleClose}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DocumentEdit;
