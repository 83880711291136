/**
 * GET Tasks Async
 * @returns promise Tasks
 */
export const getTasksAsync = async (token, competitionid, skillid) => {
    const requestOptions = {
      method: "GET",
      headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
    };
    try {
      let promise = await fetch(
        `${process.env.REACT_APP_API_URL}/todolists/?competition=${competitionid}&skill=${skillid}`,
        requestOptions
      );
      if(promise.status === 200){
        let results = await promise.json();
        return results;
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * GET Task by ID Async
   * @returns promise Tasks
   */
   export const getTaskIdAsync = async (token, id) => {
    const requestOptions = {
      method: "GET",
      headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
    };
    try {
      let promise = await fetch(
        `${process.env.REACT_APP_API_URL}/todolists/${id}`,
        requestOptions
      );
      if(promise.status === 200){
        let results = await promise.json();
        return results;
      }
    } catch (error) {
      console.log(error);
    }
  };

/**
 * PUT Task Async
 * @returns HTTP Code
 */
export const putTask = async (
  token,
  competitionid,
  skillid,
  formData
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/todolists/?competition=${competitionid}&skill=${skillid}`,
      requestOptions
    );
    if (promise.status === 201) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * POST Task Async
 * @returns HTTP Code
 */
 export const postTask = async (token, id, formData) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };
    try {
      let promise = await fetch(
        `${process.env.REACT_APP_API_URL}/todolists/${id}`,
        requestOptions
      );
      if (promise.status === 200) {
        let results = await promise.json();
        return results;
      }
    } catch (error) {
      console.log(error);
    }
  };

/**
 * DELETE Task Async
 * @returns HTTP Code
 */
export const deleteTask = async (token, id) => {
  const requestOptions = {
    method: "DELETE",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/todolists/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * POST Task done Async
 * @returns HTTP Code
 */
 export const postTaskDone = async (token, id) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let promise = await fetch(
        `${process.env.REACT_APP_API_URL}/todolists/done/${id}`,
        requestOptions
      );
      if (promise.status === 200) {
        let results = await promise.json();
        return results;
      }
    } catch (error) {
      console.log(error);
    }
  };
  
/**
 * POST Task undone Async
 * @returns HTTP Code
 */
 export const postTaskUndone = async (token, id) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    let promise = await fetch(
      `${process.env.REACT_APP_API_URL}/todolists/undone/${id}`,
      requestOptions
    );
    if (promise.status === 200) {
      let results = await promise.json();
      return results;
    }
  } catch (error) {
    console.log(error);
  }
};