import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Form, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { IoNotifications } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { useKeycloak } from "@react-keycloak/web";
import * as me_api from "../Apis/Me_apis";
import { userContext } from "../Stores/Store";
import * as _api from "../Apis/Contacts_apis";
import ListCompsAndSkills from "../Components/Header/ListCompsAndSkills";
import SearchApp from "../Components/Header/SearchApp";

const Header = () => {
	const { keycloak, initialized } = useKeycloak();
	const [diffDays, setDiffDays] = useState();
	const [urlAide, setUrlAide] = useState();
	const user = useContext(userContext);
	const [competitions, setCompetitions] = useState();

	// Initialisation des competitions
	useEffect(() => {
		me_api.getCompetitionsAsync(keycloak.token).then((res) => {
			if (res) {
				setCompetitions(res);
				let competition = res.find((item) => item.id == user.competitionid);
				if (competition) {
					setUrlAide(competition.url_aide);
				}
			}
		});
	}, [localStorage.getItem("competitionid"), user]);

	return (
		<Container fluid className="top">
			<Row>
				<Col sm={4}>
					<Row>
						<Col sm={3} className="logo">
							<NavLink exact to="/">
								<img
									src={window.location.origin + "/logo_def.svg"}
									alt="Wordlskills France"
								/>
							</NavLink>
						</Col>
					</Row>
					<Row>
						<Row></Row>
						<Col className="sponsor">
							Financé par{" "}
							<img
								src={window.location.origin + "/images/ccca-btp.png"}
								width="80"
								alt="CCCA BTP"
							/>
						</Col>
					</Row>
				</Col>
				<Col sm={8} className="rightpanel">
					<Row>
						<ListCompsAndSkills
							setDiffDays={setDiffDays}
							competitions={competitions}
						/>
						{/* <SearchApp /> */}
						<Col sm={3} className="menu_top">
							<NavLink exact to="/notifications">
								<IoNotifications />
							</NavLink>
							<NavLink exact to="/profil">
								<FaUser />
							</NavLink>
							{/* <NavLink exact to="/tutoriels"> */}
							{urlAide && (
								<a href={urlAide} target="_blank">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="25"
										height="25"
										fill="currentColor"
										class="bi bi-info-circle"
										viewBox="0 0 16 16"
									>
										<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
										<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
									</svg>
									{/* </NavLink> */}
								</a>
							)}
							&nbsp;
							<span
								style={{ cursor: "pointer" }}
								onClick={() => {
									sessionStorage.clear();
									localStorage.clear();
									keycloak.logout();
								}}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="25"
									height="25"
									fill="currentColor"
									class="bi bi-power"
									viewBox="0 0 16 16"
								>
									<path d="M7.5 1v7h1V1h-1z" />
									<path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
								</svg>
							</span>
						</Col>
					</Row>
					<Row>
						<Col
							style={{
								fontSize: "18px",
								color: "red",
								padding: "10px",
								marginLeft: "20px",
								fontWeight: "bold",
								marginTop: "10px",
							}}
						>
							{diffDays && diffDays}
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default Header;
