import React, { useContext, useEffect, useState } from "react";
import { Col, Image, Modal, Button, Row, Form } from "react-bootstrap";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./documents.scss";
import * as _api from "../../Apis/Documents_apis";
import { userContext } from "../../Stores/Store";
import { useKeycloak } from "@react-keycloak/web";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { convertToRaw } from "draft-js";
import { ContentState } from "draft-js";
import { convertFromHTML } from "draft-js";
import Loading from "../../Helpers/Loading";

const DocumentText = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const handleClose = () => props.show(false);
  const user = useContext(userContext);
  const [folder, setFolder] = useState(props.data);
  const [document, setDocument] = useState(props.data);
  const [loading, setLoading] = useState(false);
  const [errorForm, setErrorForm] = useState();
  const [html, setHtml] = useState();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(""))
    )
  );

  useEffect(() => {
    
    setLoading(true);
    _api
      .postDocumentDownload(document.token)
      .then((res) => setHtml(res))
      .then(() => setLoading(false));
  }, []);

  const uploadText = () => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const formData = new FormData();
    formData.append("file", new Blob([html], { type: "text/html" }));
    _api
      .postDocumentUpload(keycloak.token, document.id, formData)
      .then((results) => {
        if (results) {
          props.maj(document, "addUpload");
          props.show(false);
        } else {
          setErrorForm("Une erreur technique s'est produite");
        }
      });
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <>
      <Modal
        className="modal_app"
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        centered="true"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Ecrire un article</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Editor
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            editorState={editorState}
            onEditorStateChange={setEditorState}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => uploadText()}>Modifier</Button>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DocumentText;
