import React, { useContext, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as _api from "../../Apis/QTrains_apis";
import { useKeycloak } from "@react-keycloak/web";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { userContext } from "../../Stores/Store";
import { useNavigate } from "react-router-dom";

const QTrainModel = (props) => {
	const { keycloak, initialized } = useKeycloak();
	const navigate = useNavigate();
	const user = useContext(userContext);
	const handleClose = () => props.show(false);
	const [entrainement, setEntrainement] = useState();
	const [errorForm, setErrorForm] = useState();
	const [editorState, setEditorState] = useState(() =>
		EditorState.createWithContent(
			ContentState.createFromBlockArray(convertFromHTML("")),
		),
	);

	const handleChange = (e) => {
		const value =
			e.target.name === "objectifs_generaux"
				? e.target.value.replace(/\n/g, "<br>")
				: e.target.value;
		setEntrainement({ ...entrainement, [e.target.name]: value });
	};

	const addEntrainement = () => {
		entrainement.draft = 1;
		_api
			.putQTrain(keycloak.token, user.competitionid, user.skillid, entrainement)
			.then((results) => {
				if (results) {
					handleClose();
					navigate("/qtrains/" + results.id);
				} else {
					setErrorForm("Une erreur technique s'est produite");
				}
			});
	};

	return (
		<Modal
			className="modal_app"
			show={props.show}
			onHide={handleClose}
			backdrop="static"
			centered="true"
			size="lg"
		>
			<Modal.Header closeButton>
				<Modal.Title>Ajouter un programme de formation</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={(e) => e.preventDefault()}>
					<Row>
						<Col>
							<Form.Group className="mb-3">
								<Form.Control
									placeholder="Numéro du stage"
									name="training_number"
									onChange={handleChange}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Group className="mb-3">
								<Form.Control
									placeholder="Titre"
									name="titre"
									onChange={handleChange}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Group className="mb-3">
								<Form.Control
									placeholder="Formateur"
									name="formateur"
									onChange={handleChange}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Group className="mb-3">
								<Form.Control
									as="textarea"
									rows={8}
									placeholder="Objectifs Généraux"
									name="objectifs_generaux"
									onChange={handleChange}
								/>
							</Form.Group>
						</Col>
					</Row>
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<Button onClick={() => addEntrainement()}>Ajouter</Button>
				<Button variant="secondary" onClick={handleClose}>
					Fermer
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default QTrainModel;
