import { React, Component } from "react";
import { ResponsiveLine } from "@nivo/line";
import moment from "moment";

var LightenColor = function (color, percent) {
  var num = parseInt(color.replace("#", ""), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    B = ((num >> 8) & 0x00ff) + amt,
    G = (num & 0x0000ff) + amt;

  return (
    "#" +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
      (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
      .toString(16)
      .slice(1)
  );
};

class ResponsiveLineLegendToggle extends Component {
  // https://nivo.rocks/storybook/?path=/story/line--custom-line-style
  // Définition du générateur personnalisé de ligne
  // Permet de générer des lignes en pointillées

  DashedSolidLine = ({ series, lineGenerator, xScale, yScale }) => {
    return series.map(({ id, data, color }, index) => (
      <path
        key={id}
        d={lineGenerator(
          data.map((d) => ({
            x: xScale(d.data.x),
            y: yScale(d.data.y)
          }))
        )}
        // Remplissage de la zone : None = Pas de remplissage
        fill="none"
        // Couleur du trait, le search permet de différencier objectifs de
        // résultats
        stroke={id.search(/^Crit/) === -1 ? color : color}
        // Style du trait
        style={
          id.search(/^Crit/) === -1
            ? {
                // Si objectif (=crit non trouvé)
                // On déssine une ligne pointillée
                strokeDasharray: "5,4",
                strokeWidth: 0.5
                //strokeLinejoin: "round",
                //strokeLinecap: "round"
              }
            : {
                // Sinon on dessine une ligne continue
                strokeWidth: 2
              }
        }
      />
    ));
  };

  state = {
    hiddenIds: []
  };

  toggle = (d) => {
    const { hiddenIds } = this.state;
    if (this.state.hiddenIds.includes(d.id)) {
      this.setState({
        hiddenIds: hiddenIds.filter((id) => id !== d.id)
      });
    } else {
      this.setState({
        hiddenIds: [...hiddenIds, d.id]
      });
    }
  };

  render() {
    const { hiddenIds } = this.state;

    return (
      <ResponsiveLine
        // Récupere les donnees depuis le JSON
        data={this.props.data}
        // Définie la couleur depuis le champs color de la données
        colors={(data) => {
          if (hiddenIds.includes(data.id)) {
            return "rgba(255,255,255,0)";
          }
          if (data.id.search(/^Crit/) === -1) {
            return data.color;
          } else {
            return data.color;
          }
        }}
        // Style du graphe : courbe (https://nivo.rocks/line/)
        curve="monotoneX"
        // Marges du graphique
        margin={{
          top: 30,
          right: 100,
          bottom: 50,
          left: 60
        }}
        // Format de l'abscisse, time permet de bien espacer
        xScale={{
          type: "time",
          format: "%Y-%m-%d",
          precision: "day"
        }}
        xFormat="time:%Y-%m-%d"
        // Axe des abcisses
        axisBottom={{
          legend: "Jour",
          legendOffset: -12,
          format: "%d %b"
        }}
        // Format de l'ordonnée, on définie le min et le max
        yScale={{
          type: "linear",
          min: 0,
          max: 100
        }}
        // Axe des ordonnées
        axisLeft={{
          legend: "Pourcent",
          legendOffset: 12,
          format: ""
        }}
        //Marker à la date du jour
        markers={[
          {
              axis: 'x',
              value: Date.now(),
              lineStyle: { stroke: '#777', strokeWidth: 0.5, strokeDasharray: 5 },
          },
      ]}
        //Tooltip
        tooltip={(data) => {
          return (
              <div
                  style={{
                      background: 'white',
                      padding: '9px 12px',
                      border: '1px solid #ccc',
                  }}
              >
                  <div style={{textAlign:"center"}}>{moment(data.point.data.xFormatted).format("DD-MM-YYYY")} <br /><b>{data.point.serieId} : {data.point.data.yFormatted}%</b></div>
              </div>
          )
      }}
        // Elements graphiques a appliquer au graphique
        // Certains peuvent etre desactive
        // D'autres, comme le layer DashedSolidLine sont perso
        // et permette de faire des lignes custom
        layers={[
          // includes all default layers
          "grid",
          "markers",
          "axes",
          "areas",
          "crosshair",
          "line",
          "slices",
          "points",
          "mesh",
          "legends",
          this.DashedSolidLine // add the custom layer here
        ]}
        // Parametres du graphique
        // - Points de données (personnalisable)
        enablePoints={true}
        // - Label sur les points de donnees
        enablePointLabel={false}
        // - Format du point de données
        pointLabel={(d) => d.y + "%"}
        // - Active l'affichage d'un tooltip (x , y) au passage de la souris
        useMesh={true}
        // - Active l'affichage d'un tooltip de toutes les valeurs
        //   verticales "x" ou horizontales "y"
        //   Ne peut pas etre utilisé avec Mesh
        enableSlices={false}
        // Active un repere de projection x+y au déplacement de la souris
        enableCrosshair={false}
        // Définition de la légende
        legends={[
          {
            // Position
            anchor: "bottom-right",
            // Affichage en colonne
            direction: "column",
            // Pas de justification de texte
            justify: false,
            // Déplacement de 100 vers la droite
            translateX: 100,
            // Pas de déplacement vers le haut/bas
            translateY: 0,
            // Espace entre item
            itemsSpacing: 0,
            // Direction de la lecture symbol => text
            //   - left-to-right / right-to-left
            //   - top-to-bottom / bottom-to-top
            itemDirection: "left-to-right",
            // Taille de la légende
            itemWidth: 80,
            // Hauteur de la légende
            itemHeight: 20,
            // Opacité de la légende
            itemOpacity: 0.5,
            // Taille du symbole
            symbolSize: 12,
            // Style de symbole
            symbolShape: "circle",
            // Couleur de bordure du symbole
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            // Lorsque l'on clique sur la legende
            onClick: this.toggle,
            // Effet sur la legende
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    );
  }
}

export default ResponsiveLineLegendToggle;
