import React, { useContext, useState } from "react";
import { Col, Modal, Button, Row, Form } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./documents.scss";
import * as _api from "../../Apis/Documents_apis";
import { userContext } from "../../Stores/Store";
import { useKeycloak } from "@react-keycloak/web";

const DocumentFile = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const handleClose = () => props.show(false);
  const user = useContext(userContext);
  const [document, setDocument] = useState(props.data);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [errorForm, setErrorForm] = useState();

  const handleChange = (e) => {
    setFile(e.target.files);
  };

  const uploadFile = () => {
    const formData = new FormData();
    formData.append("file", file[0], file[0].name);
    _api
      .postDocumentUpload(keycloak.token, document.id, formData)
      .then((results) => {
        if (results) {
          props.maj(document, "update");
          props.show(false);
        } else {
          setErrorForm("Une erreur technique s'est produite");
        }
      });
  };

  return (
    <>
      <Modal
        className="modal_app"
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        centered="true"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un fichier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="file"
                    name="file"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => uploadFile()}>Envoyer</Button>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DocumentFile;
