import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as _api from "../../Apis/Documents_apis";

const DocumentAdd = (props) => {
  const handleClose = () => props.show(false);
  const [document, setDocument] = useState({
    name: "",
    status: "0",
  });
  const { keycloak, initialized } = useKeycloak();
  const [errorForm, setErrorForm] = useState();
  const [folder, setFolder] = useState(props.data);

  const handleChange = (e) => {
    setDocument({ ...document, [e.target.name]: e.target.value });
  };

  const addDocument = (type) => {
    _api
      .putDocument(keycloak.token, folder.id, type, document)
      .then((results) => {
        if (results) {
          props.maj(document, "addDocument");
          props.show(false);
        } else {
          setErrorForm("Une erreur technique s'est produite");
        }
      });
  };

  return (
    <Modal
      className="modal_app"
      show={props.show}
      onHide={handleClose}
      backdrop="static"
      centered="true"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Créer un document</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control
                  placeholder="Nom du document"
                  name="name"
                  value={document.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3 d-grid gap-2">
                <Button
                  size="lg"
                  variant="primary"
                  onClick={() => addDocument("text/html")}
                  disabled={!document.name}
                >
                  Créer un article
                </Button>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3 d-grid gap-2">
                <Button
                  size="lg"
                  variant="primary"
                  onClick={() => addDocument("application/octet-stream")}
                  disabled={!document.name}
                >
                  Ajouter un document
                </Button>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3 d-grid gap-2">
                <Button
                  size="lg"
                  variant="primary"
                  onClick={() => addDocument("link")}
                  disabled="true"
                >
                  Intéger un lien
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DocumentAdd;
