/**
 * GET Qualiopi Training Async
 * @returns promise Document
 */
export const getQTrainAsync = async (token, id) => {
	const requestOptions = {
		method: "GET",
		headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
	};
	try {
		let promise = await fetch(
			`${process.env.REACT_APP_API_URL}/trainings/${id}`,
			requestOptions,
		);
		if (promise.status === 200) {
			let results = await promise.json();
			return results;
		}
	} catch (error) {
		console.log(error);
	}
};

/**
 * PUT  Qualiopi Training Async
 * @returns HTTP Code
 */
export const putQTrain = async (token, competitionid, skillid, formData) => {
	formData.progression = {};
	const requestOptions = {
		method: "PUT",
		headers: {
			Accept: "application/json",
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(formData),
	};
	try {
		let promise = await fetch(
			`${process.env.REACT_APP_API_URL}/trainings/?competition=${competitionid}&skill=${skillid}`,
			requestOptions,
		);
		if (promise.status === 201) {
			let results = await promise.json();
			return results;
		}
	} catch (error) {
		console.log(error);
	}
};

/**
 * GET  Qualiopi Trainings Async
 * @returns promise Contact
 */
export const getQTrains = async (token, competitionid, skillid) => {
	const requestOptions = {
		method: "GET",
		headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
	};
	try {
		let promise = await fetch(
			`${process.env.REACT_APP_API_URL}/trainings/?competition=${competitionid}&skill=${skillid}`,
			requestOptions,
		);
		if (promise.status === 200) {
			let results = await promise.json();
			return results;
		}
	} catch (error) {
		console.log(error);
	}
};

/**
 * POST  Qualiopi Training Async
 * @returns HTTP Code
 */
export const postQTrain = async (token, id, formData) => {
	const requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(formData),
	};
	try {
		let promise = await fetch(
			`${process.env.REACT_APP_API_URL}/trainings/${id}`,
			requestOptions,
		);
		if (promise.status === 200) {
			let results = await promise.json();
			return results;
		}
	} catch (error) {
		console.log(error);
	}
};

/**
 * DELETE  Qualiopi Training Async
 * @returns HTTP Code
 */
export const deleteQTrain = async (token, id) => {
	const requestOptions = {
		method: "DELETE",
		headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
	};
	try {
		let promise = await fetch(
			`${process.env.REACT_APP_API_URL}/trainings/${id}`,
			requestOptions,
		);
		if (promise.status === 200) {
			let results = await promise.json();
			return results;
		}
	} catch (error) {
		console.log(error);
	}
};
