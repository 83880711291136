import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import * as contacts_api from "../../Apis/Contacts_apis";
import * as users_api from "../../Apis/Users_apis";
import { userContext } from "../../Stores/Store";
import * as datas from "../../Helpers/Data";

const SearchContacts = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const user = useContext(userContext);

  const [contactsSearch, setContactSearch] = useState();
  const [roleToPush, setRoleToPush] = useState('competitor'); // Role à transmettre par select dans le pushInSkill

  const handleSearch = (e) => {
    if (e.target.value.length >= 3) {
      users_api
        .getSearchUsersAsync(keycloak.token, e.target.value.toLowerCase())
        .then((res) => setContactSearch(res));
    } else {
      setContactSearch();
    }
  };

  const pushUserInSkill = (username, data) => {
    contacts_api
      .putContactInSkill(
        keycloak.token,
        username,
        user.competitionid,
        user.skillid,
        roleToPush
      )
      .then((results) => {
        if (results) {
          data.role = roleToPush;
          props.maj(data, "pushinskill");
          props.show(false);
        }
      });
  };

  const listRoles = () =>
  datas.roles.map((role) => {
    if(role.notadminlist)
    return(
      <option value={role.name_db}>{role.name}</option>
    )
  })

  const listContactsSearch = () =>
    contactsSearch.map((contactSearch) => {
      return (
        <>
          {!user.contactsSkill.find(
            (contactSkill) => contactSkill.username == contactSearch.username
          ) && (
            <tr key={contactSearch.username}>
              <td>
                {contactSearch.first_name + " " + contactSearch.last_name}
              </td>
              <td>{contactSearch.email}</td>
              <td>
                <select onChange={(e) => setRoleToPush(e.target.options[e.target.options.selectedIndex].value)}>
                {listRoles()}
                </select>
              </td>
              <td>
                <Button
                  onClick={() =>
                    pushUserInSkill(contactSearch.username, contactSearch)
                  }
                >
                  +
                </Button>
              </td>
            </tr>
          )}
        </>
      );
    });

  return (
    <>
      <Form>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Control
                placeholder="Rechercher un contact existant"
                name="contact_search"
                onChange={handleSearch}
              />
            </Form.Group>
          </Col>
        </Row>
        <Table hover>
          <tbody>{contactsSearch && listContactsSearch()}</tbody>
        </Table>
      </Form>
    </>
  );
};

export default SearchContacts;
