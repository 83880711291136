import { useKeycloak } from "@react-keycloak/web";
import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Form, Image, ListGroup, Row } from "react-bootstrap";
import * as _api from "../../Apis/Events_apis";
import Loading from "../../Helpers/Loading";
import { userContext } from "../../Stores/Store";
import { PlanningView } from "../Planning/PlanningView";
import moment from "moment";
 
const PlanningWidget = () => {
  const user = useContext(userContext);

  const { keycloak, initialized } = useKeycloak();

  const [events, setEvents] = useState();
  const [event, setEvent] = useState();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const handleModal = (status) => setShowModal(status); //fonction à passer au child pour MAJ status modal

  //fonction à passer au child pour MAJ du State liste contacts
  const majFromModal = (data, action) => {
    let arrayTemp = [{}];
    switch (action) {
      case "delete":
        const deleteList = events.filter(
          (item) => item.id !== data.id
        );
        setEvents(deleteList);
        break;
      case "update":
        let eventIndex = events.findIndex(
          (obj) => obj.id == data.id
        );
        arrayTemp = events;
        arrayTemp[eventIndex] = data;
        setEvents(arrayTemp);
        break;
      case "add":
        arrayTemp = events;
        arrayTemp.push(data);
        setEvents(arrayTemp);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (user.competitionid !== 0 && user.skillid !== 0) {
      setLoading(true);
      let dateStart = new Date();
      let dateEnd = new Date();
      _api
        .getEventsAsync(
          keycloak.token,
          user.competitionid,
          user.skillid,
          moment(dateStart).format("YYYY-MM-DD"),
          moment((dateEnd.setMonth(dateEnd.getMonth()+4))).format("YYYY-MM-DD")
        )
        .then((res) => setEvents(res))
        .then(() => setLoading(false));
    }
  }, [user.skillid, user.competitionid]);

  const listEvents = () => {
    if (events[0]?.id) {
      return events
        .sort((a, b) => (a.start > b.start ? 1 : -1))
        .map((event) => {
          return (
            <ListGroup.Item key={event.id}>
              <small>{moment(event.start).format("DD/MM/YYYY")} </small>
              <a
                className="event_a"
                href="#" style={{ color: event.color }}
                onClick={() => {
                  setShowModal(true);
                  setEvent(event);
                }}
              >
                {event.title}
              </a>
            </ListGroup.Item>
          );
        });
    }
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <>
    {showModal === true && (
      <PlanningView show={handleModal} id={event.id} maj={majFromModal} />
    )}
    <div className="widget">
      <Card>
        <Card.Header className="widgetheader">
          <Row>
            <Col sm="2">
              <Image className="img_title" src="images/navboard/planning.svg" />
            </Col>
            <Col sm="10">Planning</Col>
          </Row>
        </Card.Header>
        <ListGroup variant="flush">{events && listEvents()}</ListGroup>
      </Card>
    </div>
    </>
  );
};

export default PlanningWidget;