import React, { useContext, useEffect, useState } from "react";
import * as _api from "../../Apis/QTrains_apis";
import { userContext } from "../../Stores/Store";
import { useKeycloak } from "@react-keycloak/web";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import { BsPencilFill, BsLockFill, BsBoxArrowInUpRight } from "react-icons/bs";
import Loading from "../../Helpers/Loading";
import moment from "moment";
import { NavLink } from "react-router-dom";

const QTrainsList = () => {
	const user = useContext(userContext);
	const [loading, setLoading] = useState(false);
	const [qtrains, setQtrains] = useState();
	const [folder, setFolder] = useState();
	const [folderId, setFolderId] = useState();
	const [showModalDocAdd, setShowModalDocAdd] = useState(false);
	const [showModalFolEdit, setShowModalFolEdit] = useState(false);
	const [showModalFolRights, setShowModalFolRights] = useState(false);
	const [showModalQtrainAdd, setShowModalQTrainAdd] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const traduction = {
		others: "Autres",
		training_center: "Centre d'Excellence",
		distanciel: "Distanciel",
	};

	const { keycloak, initialized } = useKeycloak();

	//fonction à passer au child pour MAJ du State liste
	const majFromModal = (data, action) => {
		let arrayTemp = [{}];
		switch (action) {
			case "move":
				setRefresh(!refresh);
				break;
			case "deleteFolder":
				const deleteList = qtrains.filter((item) => item.name !== data.name);
				setQtrains(deleteList);
				setRefresh(!refresh);
				break;
			case "updateFolder":
				let folderIndex = qtrains.findIndex((obj) => obj.id == data.id);
				arrayTemp = qtrains;
				arrayTemp[folderIndex] = data;
				setQtrains(arrayTemp);
				setRefresh(!refresh);
				break;
			case "addFolder":
				arrayTemp = qtrains;
				arrayTemp.push(data);
				setQtrains(arrayTemp);
				setRefresh(!refresh);
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		if (user.competitionid !== 0 && user.skillid !== 0) {
			setLoading(true);
			_api
				.getQTrains(keycloak.token, user.competitionid, user.skillid)
				.then((res) => setQtrains(res))
				.then(() => setLoading(false));
		}
	}, [user.skillid, user.competitionid, refresh]);

	const listQTrains = () => {
		return qtrains
			.sort((a, b) => (a.training_number > b.training_number ? 1 : -1))
			.map((qtrain) => {
				return (
					<Col style={{ marginBottom: "10px" }}>
						<Card className="sujet" style={{ margin: "auto" }}>
							<Card.Body>
								<NavLink exact to={"/qtrains/" + qtrain.id}>
									<h3>
										Stage {qtrain.training_number}&nbsp;
										{qtrain.statut === "Remplis" && (
											<span
												role="img"
												aria-label="filled-icon"
												style={{ fontSize: "0.6em" }}
											>
												✅
											</span>
										)}
										{qtrain.planification === "Planification confirmée" && (
											<span
												role="img"
												aria-label="confirmed-planning-icon"
												style={{ fontSize: "0.60em" }}
											>
												📅
											</span>
										)}
									</h3>
								</NavLink>
								<Card.Text>
									Statut : <b>{qtrain.statut}</b>
									<br />
									Planification : <b>{qtrain.planification}</b>
									<br />
									{qtrain.planification !== "A planifier" && (
										<>
											<hr />
											<b>
												Du {moment(qtrain.start_date).format("DD/MM/YYYY")} au{" "}
												{moment(qtrain.end_date).format("DD/MM/YYYY")}
											</b>
											<br />
											Durée : <b>{qtrain.duree} jour(s)</b>
											<br />
											Localisation :{" "}
											<b>
												{traduction[qtrain.localisation] || qtrain.localisation}
											</b>
											{qtrain.lien_externe && (
												<>
													<br />
													Lien vers Chronologie Externe <a href={qtrain.lien_externe} target="_blank" rel="noreferrer">
														<BsBoxArrowInUpRight />
													</a>
												</>
											)}
										</>
									)}
								</Card.Text>
								<hr />
								<Card.Text>
									<b>Titre</b> : {qtrain.titre}
									<br />
									<b>Formateur</b> : {qtrain.formateur}
									<br />
									<b>Objectifs Généraux</b> :{" "}
									<span
										dangerouslySetInnerHTML={{
											__html: qtrain.objectifs_generaux,
										}}
									/>
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>
				);
			});
	};

	//Contrôle du chargement des données
	if (loading) return <Loading />;

	return (
		<>
			<Row>{qtrains && listQTrains()}</Row>
		</>
	);
};

export default QTrainsList;
