import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import "./planning.scss";
import * as _api from "../../Apis/Events_apis";
import * as _api2 from "../../Apis/Contacts_apis";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import "moment/locale/fr";
import { useKeycloak } from "@react-keycloak/web";
import { userContext } from "../../Stores/Store";
import PlanningView from "./PlanningView";
import Loading from "../../Helpers/Loading";

const Planning = () => {
	const user = useContext(userContext);
	const { keycloak, initialized } = useKeycloak();

	const [showModal, setShowModal] = useState(false);
	const [events, setEvents] = useState();
	const [event, setEvent] = useState();
	const [loading, setLoading] = useState(false);
	const [date, setDate] = useState(new Date());

	const handleModal = (status) => setShowModal(status); //fonction à passer au child pour MAJ status modal

	const localizer = momentLocalizer(moment);
	const onNavigate = useCallback((newDate) => setDate(newDate), [setDate]);

	//fonction à passer au child pour MAJ du State liste contacts
	const majFromModal = (data, action) => {
		let arrayTemp = [{}];
		switch (action) {
			case "delete":
				const deleteList = events.filter((item) => item.id !== data.id);
				setEvents(deleteList);
				break;
			case "update":
				let eventIndex = events.findIndex((obj) => obj.id == data.id);
				arrayTemp = events;
				arrayTemp[eventIndex] = data;
				setEvents(arrayTemp);
				break;
			case "add":
				arrayTemp = events;
				arrayTemp.push(data);
				setEvents(arrayTemp);
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		if (user.competitionid !== 0 && user.skillid !== 0) {
			setLoading(true);
			let dateStart = date;
			let dateEnd = date;
			_api
				.getEventsAsync(
					keycloak.token,
					user.competitionid,
					user.skillid,
					moment(dateStart).format("YYYY-01-01"),
					moment(dateEnd).format("YYYY-12-31"),
				)
				.then((res) => setEvents(res))
				.then(() => setLoading(false));
		}
	}, [user.skillid, user.competitionid, date]);

	//Contrôle du chargement des données
	if (loading) return <Loading />;

	return (
		<>
			{showModal === true && (
				<PlanningView show={handleModal} id={event.id} maj={majFromModal} />
			)}
			<Row className="header">
				<Col sm={2}>
					<Image
						className="img_title"
						src={window.location.origin + "/images/icons/bigmenu_planning.svg"}
					/>
					<span className="title">Planning</span>
				</Col>
				<Col sm={10}></Col>
			</Row>
			<Row className="actions mb-3">
				<Col>
					{user.userRights && user.userRights.planning.create && (
						// <NavLink exact to={"/PlanningAdd/"}>
						// 	<Button>Ajouter un événement</Button>
						// </NavLink>
						<Button
						  onClick={() => {
						    setEvent("");
						    setShowModal(true);
						  }}
						>
						  Ajouter un événement
						</Button>
					)}
				</Col>
			</Row>

			<Calendar
				date={date}
				localizer={localizer}
				defaultDate={new Date()}
				defaultView="month"
				events={events}
				style={{ height: "100vh" }}
				allDayAccessor="all_day"
				startAccessor="start"
				endAccessor="end"
				onNavigate={onNavigate}
				eventPropGetter={(event, start, end, isSelected) => ({
					event,
					start,
					end,
					isSelected,
					style: {
						backgroundColor:
							event.competition_id && event.skill_number
								? event.color
								: "white",
						color:
							event.competition_id && event.skill_number ? "white" : "blue",
						border:
							event.competition_id && event.skill_number ? "" : "solid 1px",
						borderImage:
							event.competition_id && event.skill_number
								? ""
								: "linear-gradient(45deg, blue, red) 1",
						borderRadius: "0px",
					},
				})}
				onSelectEvent={(event) => {
					setShowModal(true);
					setEvent(event);
				}}
				views={{
					month: true,
					// week: true,
					agenda: true,
				}}
				messages={{
					today: "Aujourd'hui",
					previous: "Précédent",
					next: "Suivant",
					month: "Mois",
					week: "Semaine",
					day: "jour",
				}}
			/>
		</>
	);
};

export default Planning;
