import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Criteres from "./Criteres";
import moment from "moment";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";

const CriteresBoard = (props) => {
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  return (
    <>
      <Row>
        {props.username && date ? (
          <Criteres
            username={props.username}
            date={props.date}
            baremeId={props.baremeId}
            lock={props.lock}
          />
        ) : (
          ""
        )}
      </Row>
    </>
  );
};

export default CriteresBoard;
