import React, { useContext, useEffect, useState } from "react";
import { Col, Modal, Button, Row, Form, Alert } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./entrainements.scss";
import * as _api from "../../Apis/Entrainements_apis";
import { userContext } from "../../Stores/Store";
import { useKeycloak } from "@react-keycloak/web";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { convertToRaw } from "draft-js";
import { ContentState } from "draft-js";
import { convertFromHTML } from "draft-js";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const DocumentCreate = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const handleClose = () => props.show(false);
  const user = useContext(userContext);
  const [document, setDocument] = useState({
    name: "",
    status: "1",
  });
  const [cguOk, setCguOk] = useState(false);
  const [file, setFile] = useState();
  const [errorForm, setErrorForm] = useState();
  const [progress, setProgress] = useState(false);
  const [show, setShow] = useState();

  useEffect(() => {
    switch (props.type) {
      case "html":
        setShow("html");
        setDocument({ ...document, type: "text/html" });
        break;

      case "file":
        setShow("file");
        setDocument({ ...document, type: "application/octet-stream" });
        break;

      default:
        break;
    }
  }, [props]);

  const handleChangeFile = (e) => {
    setFile(e.target.files);
  };

  const addDoc = () => {
    const formData = new FormData();
    formData.append("file", file[0], file[0].name);
    _api
      .putEntrainementUpload(keycloak.token, props.entrainementId, formData)
      .then((result) => {
        if (result) {
          props.result(result)
          props.show(false);
        } else {
          setErrorForm("Une erreur technique s'est produite");
        }
      });
  };

  return (
    <>
      <Modal
        className="modal_app"
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        centered="true"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Uploader un document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Form onSubmit={(e) => e.preventDefault()}>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="file"
                      name="file"
                      onChange={handleChangeFile}
                    />
                    <Form.Text className="text-muted">
                      Attention le fichier ne doit pas excéder 10Mo.
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <Form.Check
                    type={"checkbox"}
                    onChange={() => setCguOk(!cguOk)}
                    id={`default-checkbox`}
                    label={`En cochant cette case, j'accepte que ce document soit stocké
                    sur la plateforme victoire, pour un usage strictement limité
                    à cette plateforme, pour les deux prochains cycles de
                    compétitions`}
                  />
                </Col>
              </Row>
            </Form>
          </>
        </Modal.Body>

        <Modal.Footer>
          {progress && (
            <Alert variant="info">Upload en cours veuillez patienter</Alert>
          )}
          <Button disabled={!cguOk} onClick={() => addDoc()}>
            Envoyer
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DocumentCreate;
