import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import Loading from "../../Helpers/Loading";
import { userContext } from "../../Stores/Store";
import * as _api from "../../Apis/Configuration_apis";
import { useKeycloak } from "@react-keycloak/web";
import AlertMessage from "../../Helpers/AlertMessage";
import moment from "moment/moment";

const Users = () => {
  const user = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [userk, setUserk] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const { keycloak, initialized } = useKeycloak();
  const [confirmDelete, setConfirmDelete] = useState();
  const [userSearch, setUserSearch] = useState("");
  const [enabled, setEnabled] = useState(true);
  const [hidden, setHidden] = useState(false);
  const [password, setPassword] = useState();
  const [errorForm, setErrorForm] = useState();
  const [competitionSearch, setCompetitionSearch] = useState();
  const [skillSearch, setSkillSearch] = useState();

  // On réinitialise les erreurs quand on ferme l'alerte
  const initError = () => {
    setErrorForm();
  };

  useEffect(() => {
    if(!competitionSearch) setCompetitionSearch(user.competitionid);
    if(!skillSearch) setSkillSearch(user.skillid);
  }, [])

  useEffect(() => {
    if (userSearch != "") {
      setLoading(true);
      _api
        .getUsers(keycloak.token)
        .then((res) => setUsers(res))
        .then(() => setLoading(false));
    }
  }, [user.skillid]);

  const addUser = () => {
    if (userk.email && userk.last_name && userk.first_name) {
      userk.password = password;
      userk.hidden = hidden ? hidden : "false";
      userk.enabled = enabled ? enabled : "true";
      _api.putUser(keycloak.token, userk).then((res) => {
        if (res.status) {
          setErrorForm("Code Erreur " + res.status + ": " + res.msg);
        } else {
          setUsers([res, ...users]);
          eraseForm();
        }
      });
    }
  };

  const updateUser = () => {
    if (userk.email && userk.last_name && userk.first_name) {
      if (password) userk.password = password;
      userk.hidden = hidden;
      userk.enabled = enabled;
      _api.postUser(keycloak.token, userk.username, userk).then((res) => {
        if (res.status) {
          setErrorForm("Code Erreur " + res.status + ": " + res.msg);
        } else {
          let arrayTemp = [];
          let userIndex = users.findIndex(
            (obj) => obj.username == res.username
          );
          arrayTemp = [...users];
          arrayTemp[userIndex] = res;
          setUsers(arrayTemp);
          eraseForm();
        }
      });
    }
  };

  const deleteUser = () => {
    if (userk.email) {
      if (confirmDelete == userk.username) {
        _api
          .deleteUser(keycloak.token, userk.username)
          .then((res) => {
            setUsers(users.filter((item) => item.email !== userk.email));
          })
          .then(() => setUserk(""))
          .then(() => setShowDelete(false));
      }
    }
  };

  const eraseForm = () => {
    setUserk("");
    setPassword("");
  };

  const search = () => {
    _api
      .getUsers(keycloak.token, userSearch.toString().toLowerCase(), competitionSearch, skillSearch)
      .then((res) => setUsers(res));
  };

  const listUsers = () => {
    return users.map((user) => {
      return (
        <>
          <tr>
            <td>{user.email}</td>
            <td>{user.first_name}</td>
            <td>{user.last_name}</td>
            <td>{user.username}</td>
            <td>{user.enabled ? "Oui" : "Non"}</td>
            <td>{user.hidden ? "Oui" : "Non"}</td>
            <td>{user.found_in_keycloak ? "Oui" : "Non"}</td>
            <td>{user.email_verified ? "Oui" : "Non"}</td>
            <td>{user.cgu_accepted ? "Oui" : "Non"}</td>
            <td>{moment(user.last_visit).format("DD/MM/YYYY")}</td>
            <td>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setUserk(user);
                  setShowDelete(false);
                  setEnabled(user.enabled);
                  setHidden(user.hidden);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-pencil"
                  viewBox="0 0 16 16"
                  color="blue"
                >
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>
              </span>
              &nbsp;
              {/* <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setUserk(user);
                    setShowDelete(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                    color="red"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </span> */}
            </td>
          </tr>
        </>
      );
    });
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;
  return (
    <>
      <Container fluid>
        {/* Affichage des erreurs */}
        {errorForm && <AlertMessage message={errorForm} errorMaj={initError} />}
        <Row>
          <Col sm={9}>
            <Row>
                <Form onSubmit={(e) => e.preventDefault()}>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Control
                          placeholder="Chercher un utilisateur"
                          value={userSearch}
                          onChange={(e) => setUserSearch(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Control
                          placeholder="ID Compétition"
                          defaultValue={user.competitionid}
                          value={competitionSearch}
                          onChange={(e) => setCompetitionSearch(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Control
                          placeholder="ID Métier"
                          defaultValue={user.skillid}
                          value={skillSearch}
                          onChange={(e) => setSkillSearch(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Button
                        variant="outline-success"
                        onClick={() => search()}
                      >
                        Chercher
                      </Button>
                    </Col>
                  </Row>
                </Form>
            </Row>
            <Row>
              <Table hover responsive striped>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Prénom</th>
                    <th>Nom</th>
                    <th>Login</th>
                    <th>Actif</th>
                    <th>Caché</th>
                    <th>Keycloak</th>
                    <th>Email vérifié</th>
                    <th>CGU</th>
                    <th>Connexion</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{users && listUsers()}</tbody>
              </Table>
            </Row>
          </Col>
          {!showDelete && (
            <Col sm={3}>
              <h3>
                {!userk.username ? "Ajouter un " : "Modifier l'"} utilisateur
              </h3>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Form.Group className="mb-3">
                    <Form.Control
                      placeholder="email de l'utilisateur"
                      required
                      value={userk && userk.email}
                      onChange={(e) =>
                        setUserk({ ...userk, email: e.target.value })
                      }
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3">
                    <Form.Control
                      placeholder="Téléphone de l'utilisateur"
                      value={userk && userk.phone_number}
                      onChange={(e) =>
                        setUserk({ ...userk, phone_number: e.target.value })
                      }
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3">
                    <Form.Control
                      placeholder="Nom de l'utilisateur"
                      value={userk && userk.last_name}
                      onChange={(e) =>
                        setUserk({ ...userk, last_name: e.target.value })
                      }
                      required
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3">
                    <Form.Control
                      placeholder="Prénom de l'utilisateur"
                      value={userk && userk.first_name}
                      onChange={(e) =>
                        setUserk({ ...userk, first_name: e.target.value })
                      }
                      required
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        label="Activer / Désactiver"
                        name="enabled"
                        type="switch"
                        value="true"
                        onChange={(e) => {
                          if (e.target.checked) setEnabled(true);
                          if (!e.target.checked) setEnabled(false);
                        }}
                        checked={enabled}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        label="Afficher / Cacher"
                        name="hidden"
                        type="switch"
                        value="true"
                        onChange={(e) => {
                          if (e.target.checked) setHidden(true);
                          if (!e.target.checked) setHidden(false);
                        }}
                        checked={hidden}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="password"
                        placeholder="Mot de passe"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {!userk.username && (
                      <Button
                        onClick={() => addUser()}
                        style={{ marginTop: "10px" }}
                      >
                        Ajouter
                      </Button>
                    )}
                    {userk.username && (
                      <>
                        <Button
                          onClick={() => updateUser()}
                          style={{ marginTop: "10px" }}
                        >
                          Modifier
                        </Button>
                        &nbsp;
                        <Button
                          variant="outline-secondary"
                          onClick={() => eraseForm()}
                          style={{ marginTop: "10px" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-x-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                          </svg>
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </Form>
            </Col>
          )}
          {showDelete && (
            <Col sm={3}>
              <h3>Supprimer {userk.username}</h3>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Alert variant="warning">
                    Cette action sera irrévocable. Elle entrainera la
                    suppression de cet utilisateur et de ses liens.
                    <br />
                    Pour confirmer la suppression veuillez saisir l'email de
                    l'utilisateur et cliquer sur "Supprimer"
                  </Alert>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="Login de l'utilisateur à supprimer"
                        required
                        value={confirmDelete}
                        onChange={(e) => setConfirmDelete(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Button
                      variant="outline-danger"
                      onClick={() => deleteUser()}
                    >
                      Supprimer
                    </Button>
                    &nbsp;
                    <Button
                      variant="outline-secondary"
                      onClick={() => {
                        setUserk("");
                        setShowDelete(false);
                      }}
                    >
                      Annuler
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Users;
