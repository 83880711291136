import React, { useContext, useEffect, useState } from "react";
import { Col, Modal, Button, Row, Form, Alert } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./documents.scss";
import * as _api from "../../Apis/Documents_apis";
import { userContext } from "../../Stores/Store";
import { useKeycloak } from "@react-keycloak/web";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { convertToRaw } from "draft-js";
import { ContentState } from "draft-js";
import { convertFromHTML } from "draft-js";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const DocumentCreate = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const handleClose = () => props.show(false);
  const user = useContext(userContext);
  const [document, setDocument] = useState({
    name: "",
    status: "1",
  });
  const [loading, setLoading] = useState(false);
  const [cguOk, setCguOk] = useState(false);
  const [file, setFile] = useState();
  const [errorForm, setErrorForm] = useState();
  const [progress, setProgress] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(""))
    )
  );
  const [show, setShow] = useState();

  useEffect(() => {
    switch (props.type) {
      case "html":
        setShow("html");
        setDocument({ ...document, type: "text/html" });
        break;

      case "file":
        setShow("file");
        setDocument({ ...document, type: "application/octet-stream" });
        break;

      default:
        break;
    }
    if (props.data) {
      setDocument(props.data);
      if (props.type == "html") {
        _api
          .getDocumentDownload(keycloak.token, props.data.token)
          .then((res) =>
            setEditorState(
              EditorState.createWithContent(
                ContentState.createFromBlockArray(convertFromHTML(res))
              )
            )
          );
      }
    }
  }, [props]);

  const handleChangeFile = (e) => {
    setFile(e.target.files);
    setDocument({ ...document, name: e.target.files[0].name });
  };

  const handleChange = (e) => {
    setDocument({ ...document, [e.target.name]: e.target.value });
  };

  const updateDoc = (type) => {
    _api.postDocument(keycloak.token, document.id, document).then((results) => {
      if (results) {
        const formData = new FormData();
        switch (type) {
          case "file":
            formData.append("file", file[0], file[0].name);
            break;
          case "html":
            const html = draftToHtml(
              convertToRaw(editorState.getCurrentContent())
            );
            formData.append("file", new Blob([html], { type: "text/html" }));
            break;
        }
        _api
          .postDocumentUpload(keycloak.token, results.id, formData)
          .then((results) => {
            if (results) {
              props.maj(results, "update");
              props.show(false);
            } else {
              setErrorForm("Une erreur technique s'est produite");
            }
          });
      } else {
        setErrorForm("Une erreur technique s'est produite");
      }
    });
  };

  const addDoc = (type) => {
    _api
      .putDocument(keycloak.token, props.folderid, document.type, document)
      .then((results) => {
        if (results) {
          const formData = new FormData();
          switch (type) {
            case "file":
              formData.append("file", file[0], file[0].name);
              break;
            case "html":
              const html = draftToHtml(
                convertToRaw(editorState.getCurrentContent())
              );
              formData.append("file", new Blob([html], { type: "text/html" }));
              break;
          }
          setProgress(true);
          _api
            .postDocumentUpload(keycloak.token, results.id, formData)
            .then((results) => {
              if (results) {
                props.maj(results, "add");
                props.show(false);
              } else {
                setErrorForm("Une erreur technique s'est produite");
              }
            });
        } else {
          setErrorForm("Une erreur technique s'est produite");
        }
      });
  };

  return (
    <>
      <Modal
        className="modal_app"
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        centered="true"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {show == "html" && "Créer un article"}
            {show == "file" && "Uploader un document"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {show == "file" && (
            <>
              <Form onSubmit={(e) => e.preventDefault()}>
                {/* <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="Nom du document"
                        name="name"
                        value={document.name}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row> */}
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="file"
                        name="file"
                        onChange={handleChangeFile}
                      />
                      <Form.Text className="text-muted">
                        Attention le fichier ne doit pas excéder 10Mo.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <Form.Check
                      type={"checkbox"}
                      onChange={() => setCguOk(!cguOk)}
                      id={`default-checkbox`}
                      label={`En cochant cette case, j'accepte que ce document soit stocké
                    sur la plateforme victoire, pour un usage strictement limité
                    à cette plateforme, pour les deux prochains cycles de
                    compétitions`}
                    />
                  </Col>
                </Row>
              </Form>
            </>
          )}
          {show == "html" && (
            <>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Control
                      placeholder="Nom du document"
                      name="name"
                      value={document.name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Editor
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <Form.Check
                    type={"checkbox"}
                    onChange={() => {
                      setCguOk(!cguOk);
                    }}
                    id={`default-checkbox`}
                    label={`En cochant cette case, j'accepte que ce document soit stocké
                    sur la plateforme victoire, pour un usage strictement limité
                    à cette plateforme, pour les deux prochains cycles de
                    compétitions`}
                  />
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          {progress && (
            <Alert variant="info">Upload en cours veuillez patienter</Alert>
          )}
          {!props.data && (
            <Button
              disabled={(!document.name || !cguOk) && true}
              onClick={() => addDoc(show)}
            >
              Envoyer
            </Button>
          )}
          {props.data && (
            <Button
              disabled={(!document.name || !cguOk) && true}
              onClick={() => updateDoc(show)}
            >
              Modifier
            </Button>
          )}
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DocumentCreate;
